
import { StatsigProvider } from "@statsig/react-bindings"
import React from "react"
import { useSelector } from "react-redux"

import { getStatsigClient } from "js/state/modules/statsig/selectors"
import { darkTheme } from "js/theme"

import ThemedApp from "./ThemedApp"

const AppProvider = () => {
  const statsigClient = useSelector(getStatsigClient)

  if (statsigClient) return (
    <StatsigProvider client={statsigClient}>
      <ThemedApp theme={darkTheme} />
    </StatsigProvider>
  )

  return (
    <ThemedApp theme={darkTheme} />
  )
}

export default AppProvider
