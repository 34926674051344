import type { OPENLOGIN_NETWORK_TYPE } from '@toruslabs/openlogin-utils'
import { LOGIN_PROVIDER } from '@toruslabs/openlogin-utils'
import {
  CHAIN_NAMESPACES, WALLET_ADAPTERS
} from "@web3auth/base"
import { CommonPrivateKeyProvider } from "@web3auth/base-provider"
import { Web3AuthNoModal } from '@web3auth/no-modal'
import { OpenloginAdapter } from "@web3auth/openlogin-adapter"
import { CarbonSDK, CarbonWalletGenericOpts, ProviderAgent } from 'carbon-js-sdk'

import { SocialLoginInfo } from 'js/state/modules/account/types'

import * as SocialIcons from 'assets/socials'

import { clickElement, runTimedTask } from './misc'

let connectedWeb3auth: Web3AuthNoModal | undefined

export const connectWeb3Auth = async (sdk: CarbonSDK, walletOpts: Partial<CarbonWalletGenericOpts>, defaultLogin?: 'google' | 'twitter', timeoutMs?: number) => {
  const connectedSdk = await runTimedTask(async (signal) => {
    const web3auth = getWeb3AuthNoModal()
    await web3auth.init()
    if (!web3auth.connected) {
      if (defaultLogin) {
        await web3auth.connectTo(
          WALLET_ADAPTERS.OPENLOGIN,
          {
            loginProvider: defaultLogin,
          }
        )
      }
    }
    if (web3auth.connected) {
      connectedWeb3auth = web3auth
    }

    if (!web3auth.provider) throw new Error("web3auth provider not initialized")

    const privateKey = await web3auth.provider.request({ method: 'private_key' })
    if (typeof privateKey !== 'string') throw new Error('invalid web3auth key')

    if (signal.aborted) return null
    const connectedSDK = await sdk.connectWithPrivateKey(privateKey, {
      providerAgent: ProviderAgent.Web3Auth,
      ...walletOpts,
    })

    return connectedSDK
  }, {
    durationInMs: timeoutMs,
    abortMessage: 'Wallet connect timeout, please check that Web3Auth pop ups are not blocked.',
  })

  return connectedSdk
}

export const disconnectWeb3Auth = async () => {
  if (connectedWeb3auth?.connected) {
    await connectedWeb3auth.logout()
    if (!connectedWeb3auth?.connected) {
      connectedWeb3auth = undefined
    }
  }
}

export const getWeb3AuthNoModal = () => {
  const network = process.env.REACT_APP_WEB3AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE | undefined
  const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT ?? 'BDABOUFLQ_xwubKBtQziQQ7M9t2M5xBXpNiXtnDT5qTwr2VBzetv25ApbEyUMtmWrlw4cCTM94i4Gv715MtH7VQ'
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.OTHER,
    chainId: '0x263e',
    rpcTarget: 'https://tm-api.carbon.network/',
    displayName: 'Carbon',
    blockExplorer: 'https://scan.carbon.network/dashboard?net=main',
    ticker: 'SWTH',
    tickerName: 'Carbon Protocol',
  }

  const web3authNoModal = new Web3AuthNoModal({
    clientId,
    chainConfig,
    web3AuthNetwork: network ?? 'sapphire_devnet',
  })
  const privateKeyProvider = new CommonPrivateKeyProvider({ config: { chainConfig } })

  const openloginAdapter = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      uxMode: 'redirect',
    },
  })
  web3authNoModal.configureAdapter(openloginAdapter)

  return web3authNoModal
}

// watch web3auth container
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addModalHook = (loginType: 'google' | 'twitter') => {
  const observer = new MutationObserver((mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        for (const node of mutation.addedNodes) {
          let element: Element | null = null
          const nodeId = (node as Element).getAttribute?.('id')
          // change observation to w3a container when initialized
          if (nodeId === 'w3a-modal') {
            switch (loginType) {
              case 'google':
                element = document.querySelector('button.w3a-button[title="Google"]')
                break
              case 'twitter':
                element = document.querySelector('button.w3a-button[title="Twitter"]')
                break
            }
            clickElement(element)
            observer.disconnect()
            return
          }
        }
      }
    }
  })

  const elem = document.getElementById('w3a-container')
  if (elem) {
    observer.observe(elem, { childList: true })
  }
}

// Maps icon to social login type, for use in the getSocialIcon() function
const IconMap = {
  [LOGIN_PROVIDER.APPLE]: SocialIcons.AppleIcon,
  [LOGIN_PROVIDER.FACEBOOK]: SocialIcons.FacebookIcon,
  [LOGIN_PROVIDER.GITHUB]: SocialIcons.GitHubIcon,
  [LOGIN_PROVIDER.GOOGLE]: SocialIcons.GoogleIcon,
  [LOGIN_PROVIDER.KAKAO]: SocialIcons.KakaoIcon,
  [LOGIN_PROVIDER.LINE]: SocialIcons.LineIcon,
  [LOGIN_PROVIDER.LINKEDIN]: SocialIcons.LinkedInIcon,
  [LOGIN_PROVIDER.REDDIT]: SocialIcons.RedditIcon,
  [LOGIN_PROVIDER.TWITCH]: SocialIcons.TwitchIcon,
  [LOGIN_PROVIDER.WECHAT]: SocialIcons.WechatIcon,
  [LOGIN_PROVIDER.WEIBO]: SocialIcons.WeiboIcon,
  [LOGIN_PROVIDER.TWITTER]: SocialIcons.XIcon,
}

/**
 * Returns corresponding icon for each social login type.
 */
export const getSocialIcon = (loginType: string | undefined): typeof IconMap[keyof typeof IconMap] | undefined => {
  return IconMap[loginType as keyof typeof IconMap]
}

/**
 * Returns ID to display given the social login info.
 */
export const getDisplayId = (loginInfo: SocialLoginInfo | undefined): string | undefined => {
  if (loginInfo?.typeOfLogin === LOGIN_PROVIDER.TWITTER) {
    return loginInfo.displayName
  }
  return loginInfo?.identifier
}