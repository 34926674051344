import { Divider, Drawer, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TypographyLabel } from 'js/components/Common'
import DropDown from 'js/components/Exchange/MarketsBar/shared/Dropdown'
import { useDropdownHandler, useTaskSubscriber } from 'js/hooks'
import { getConnectionError } from 'js/state/modules/account/selectors'
import { hideNodeInfoForm, setAutoSelectNode, setNodeMenuClose, setNodeMenuOpen, setSelectedNodes } from 'js/state/modules/app/actions'
import { getAutoSelectNode, getConnectError, getNet, getNodes, getOpenNodeMenu, getRatingLatency, getSelectedNodes, getShowNodeInfoForm } from 'js/state/modules/app/selectors'
import { capitalize, SimpleMap } from 'js/utils'
import { baseNodeForm, NodeFormState, sortByRatingLatencies } from 'js/utils/nodes'
import { customToast } from 'js/utils/notifications'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import { NodeInfoForm, NodeTablePage } from './components'

import BottomButton from '../../BottomButton'
import SliderTopBar from '../../components/MenuSlider/SliderTopBar'
import { connectionErrorMessages } from '../ErrorMessages'

interface NetworkItem {
  label: string
  key: string
}
interface Props {
}

const NodeSelectDropdown: React.FC<Props> = (props: Props): ReactElement<Props> => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const openNodeMenu = useSelector(getOpenNodeMenu)
  const network = useSelector(getNet)
  const nodes = useSelector(getNodes)
  const selectedNodes = useSelector(getSelectedNodes)
  const autoSelectNode = useSelector(getAutoSelectNode)
  const connectError = useSelector(getConnectError)
  const ratingLatencies = useSelector(getRatingLatency)
  const [currentNetwork, setCurrentNetwork] = React.useState(network)

  const isShowNodeInfoForm = useSelector(getShowNodeInfoForm)
  const [selectingNode, setSelectingNode] = React.useState<any | null>(null)
  const [loadingFetchNodes] = useTaskSubscriber('fetchNodes')
  const [loadingThirdParty] = useTaskSubscriber('queryThirdPartyApi')
  const [loadingQueryNodeLatencies] = useTaskSubscriber('queryNodeLatencies')
  const [formState, setFormState] = React.useState<NodeFormState>(baseNodeForm)
  const [formErrors, setFormErrors] = React.useState<string[]>([])
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
  const selectedNodeId = useMemo(() => selectedNodes?.[network]?.nodeId, [selectedNodes, network])
  const { errorPriority, errMessage } = useSelector(getConnectionError)

  const [isDropDownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)

  const indivRatingLatency = ratingLatencies[selectedNodeId]
  const rating = indivRatingLatency?.rating ?? 0

  const currentNetworkLabel = useMemo(() => {
    const initNetworks: CarbonSDK.Network[] = [CarbonSDK.Network.MainNet, CarbonSDK.Network.TestNet, CarbonSDK.Network.DevNet, CarbonSDK.Network.LocalHost]

    const networks: NetworkItem[] = initNetworks.map((option: CarbonSDK.Network) => ({
      label: `Carbon ${capitalize(option)}`,
      key: option,
    }))

    return networks.find(networkItem => networkItem.key === network)?.label || network
  }, [network])

  const recommendedNode = useMemo(() => {
    const bestNode = nodes.sort(sortByRatingLatencies(ratingLatencies))[0]
    return bestNode
  }, [nodes, ratingLatencies])

  const handleBack = () => {
    if (isShowNodeInfoForm) {
      dispatch(hideNodeInfoForm())
    } else {
      dispatch(setNodeMenuClose())
      dispatch(hideNodeInfoForm())
    }
  }

  const setupSelectNode = async (node: any, resetAutoselect: boolean = true) => {
    if (network !== node.appBuild) return
    try {
      dispatch(setSelectedNodes({
        ...selectedNodes,
        [network]: node,
      }))
      if (resetAutoselect && node.nodeId !== recommendedNode?.nodeId) {
        dispatch(setAutoSelectNode(false))
      }
      setSelectingNode(null)

    } catch (err) {
      throw new Error((err as Error).message)
    }
  }

  const handleSelectNode = async (node: any, resetAutoselect: boolean = true) => {
    if (!node) return
    setSelectingNode(node)
    try {
      await setupSelectNode(node, resetAutoselect)
    } catch (error) {
      if (selectingNode?.nodeId !== selectedNodeId) {
        setSelectingNode(null)
      }
      customToast.error({ title: `Node connection error: ${(error as Error).message}`, message: 'Please check the console for more details, or try switching to another node' })
      if (node.nodeId === selectedNodeId) {
        try {
          await setupSelectNode(recommendedNode, resetAutoselect)
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  useEffect(() => {
    if (connectError) {
      customToast.error({ title: `Network connection error: ${connectError.message}`, message: 'Please check the console for more details.' })
    }
  }, [connectError])

  useEffect(() => {
    const defaultNode = nodes[0]
    const selectNode = selectedNodes?.[network] ?? recommendedNode ?? defaultNode
    if (selectedNodeId && currentNetwork !== network && selectNode.appBuild === network) {
      setCurrentNetwork(network)
      handleSelectNode(selectNode, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, selectedNodes, recommendedNode, currentNetwork])

  useEffect(() => {
    if (((autoSelectNode && selectedNodeId !== recommendedNode?.nodeId) || !selectedNodeId) && firstLoad) {
      handleSelectNode(recommendedNode)
      setFirstLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectNode, recommendedNode, firstLoad])

  const {
    connectionAlert,
    navText,
  } = useMemo(() => {
    let connectionAlert: SimpleMap<React.ReactNode> = {
      alertTitle: '',
      alertSubtitle: '',
      alertText: '',
      errMessage: '',
    }
    let navText = ''
    if (!errorPriority && loadingFetchNodes && loadingThirdParty && loadingQueryNodeLatencies) {
      return {
        connectionAlert,
        navText,
      }
    }

    const errorObj = connectionErrorMessages[errorPriority]?.nodeError

    if (errorObj) {
      connectionAlert = {
        alertTitle: errorObj.title,
        alertSubtitle: errorObj.subtitle,
        alertText: errorObj.text,
        errMessage,
      }
      navText = errorObj.navText
    }

    return {
      connectionAlert,
      navText,
    }
  }, [errorPriority, loadingFetchNodes, loadingThirdParty, loadingQueryNodeLatencies, errMessage])

  const customDivIcon = useMemo(() => {
    return (
      <div className={classes.divIcon}>
        <div className={
          clsx(classes.indicator, {
            good: rating >= 0.6,
            warning: rating >= 0.2 && rating < 0.6,
            error: rating < 0.2,
          })}
        />
      </div>
    )
  }, [classes.divIcon, classes.indicator, rating])

  const networkIcon = (
    <div className={clsx(commonClasses.alignItemsCenter, classes.labelWithError)}>
      <BottomButton customDivIcon={customDivIcon} label={currentNetworkLabel} />
      <TypographyLabel className={classes.errorText} variant="body1" color="error">{navText}</TypographyLabel>
    </div>
  )

  return isMobile
    ? (
      <React.Fragment>
        <BottomButton
          customDivIcon={customDivIcon}
          label={currentNetworkLabel}
          onClick={() => dispatch(setNodeMenuOpen())}
        />
        <Drawer
          anchor="left"
          open={openNodeMenu}
          onClose={() => dispatch(setNodeMenuClose())}
          classes={{
            paper: classes.drawer,
          }}
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
        >
          <div
            className={classes.list}
            role="presentation"
          >
            <SliderTopBar
              backButton
              backButtonOnClick={handleBack}
              addPadding
              noBottomDivider
            />
            <div className={clsx(classes.innerDiv, 'innerDiv')}>
              {
                isShowNodeInfoForm ? (
                  <React.Fragment>
                    <Divider className={classes.nodeMargin} />
                    <NodeInfoForm
                      handleSelectNode={handleSelectNode}
                      formErrors={formErrors}
                      formState={formState}
                      setFormErrors={setFormErrors}
                      setFormState={setFormState}
                    />
                  </React.Fragment>
                ) : (
                  <NodeTablePage
                    handleSelectNode={handleSelectNode}
                    selectingNode={selectingNode}
                    recommendedNode={recommendedNode}
                    setFirstLoad={setFirstLoad}
                    connectionAlert={connectionAlert}
                  />
                )
              }
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    ) : (
      <DropDown
        dropdownOpen={isDropDownOpen}
        onDropdownLabelClick={isDropDownOpen ? handleDropdownClose : handleDropdownOpen}
        onMouseLeave={handleDropdownClose}
        label={networkIcon}
        hasDropdownIcon={false}
        leftUpperMenu
        className={classes.dropdown}
        labelClassName={classes.dropdownLbl}
        extraLength={50}
      >
        {
          isShowNodeInfoForm ? (
            <NodeInfoForm
              handleSelectNode={handleSelectNode}
              formErrors={formErrors}
              formState={formState}
              setFormErrors={setFormErrors}
              setFormState={setFormState}
            />
          ) : (
            <NodeTablePage
              handleSelectNode={handleSelectNode}
              selectingNode={selectingNode}
              recommendedNode={recommendedNode}
              setFirstLoad={setFirstLoad}
              connectionAlert={connectionAlert}
            />
          )
        }
      </DropDown>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdown: {
    transition: 'all 0.2s ease-in-out',
    borderRadius: 4,
    width: '20.5rem',
    height: '28rem',
    zIndex: 2,
    '&.rightDropDownMenu': {
      overflow: 'hidden',
    },
  },
  dropdownLbl: {
    '&.dropdownOpen': {
      background: 'transparent',
    },
  },
  drawer: {
    overflowY: 'hidden',
    padding: theme.spacing(2.5, 0),
    background: theme.palette.background.paper,
  },
  list: {
    height: '100%',
    width: '21.75rem',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    '& > .innerDiv': {
      ...StyleUtils.scrollBar(theme),
      overflow: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      maxWidth: 'unset',
    }
  },
  innerDiv: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 90px)',
  },
  iconAlt: {
    minWidth: '1.75rem',
  },
  iconBack: {
    width: '0.625rem',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  nodeRowMobile: {
    [theme.breakpoints.down('md')]: {
      '& td:first-child, & td:last-child': {
        border: 'none',
      },
      '& td:last-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  nodeMargin: {
    margin: theme.spacing(0, 2.5),
  },
  nodeMarginLeft: {
    marginLeft: theme.spacing(2.5),
  },
  textBack: {
    color: theme.palette.primary.main,
    marginLeft: '0.25rem',
  },
  errorButton: {
    '&:hover': {
      '&.fill svg path': {
        fill: 'url(#accentError)!important',
      },
    },
  },
  normalButton: {
    ...StyleUtils.hoverAnimation(theme),
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    margin: theme.spacing(0, 0.75),
    padding: theme.spacing(0.25, 0),
    '@media (min-width: 960px) and (max-width: 1056px)': {
      margin: theme.spacing(0, 2.5),
    },
    '& path': {
      fill: theme.palette.text.secondary,
    },
    '&.error': {
      '& path': {
        fill: 'url(#accentError)',
      },
    },

    '&.open': {
      '& path': {
        fill: theme.palette.type === 'dark' ? theme.palette.text.animated : theme.palette.text.demexSolid,
      },
    },
  },
  errorText: {
    ...theme.typography.body5,
  },
  labelWithError: {
    gap: `0 ${theme.spacing(1)}px`
  },
  divIcon: {
    display: 'flex',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    width: theme.spacing(0.75),
    height: theme.spacing(0.75),
    display: 'inline-block',
    borderRadius: '50%',
    '&.good': {
      backgroundColor: theme.palette.success.main,
    },
    '&.warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.error': {
      backgroundColor: theme.palette.error.main,
    },
  }
}))

export default NodeSelectDropdown
