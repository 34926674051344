import {
  ButtonProps,
  Button as MUIButton,
  Theme,
  createStyles, makeStyles,
} from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils'
import clsx from 'clsx'
import React, { useMemo } from 'react'

import colors, { makeButtonColors } from 'js/components/Common/colors'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import LoadingButtonIndicator from './LoadingButtonIndicator'

type muiButtonVariants = 'contained' | 'text' | 'outlined'

export interface Props extends Omit<ButtonProps<any>, 'variant'> {
  loading?: boolean
  spinnerColor?: 'inherit' | 'primary' | 'secondary'
  buttonType?: 'action' | 'neutral'
  btnsize?: 'small' | 'regular' | 'large' | 'xLarge' | 'xSmall'
  spinnerSize?: string
  loadingText?: string
  countdownSeconds?: number
  baseButtonVariant?: muiButtonVariants | 'gradientText' | 'plain'
  useOriginalContained?: boolean
}

/**
  * To use vanilla Material UI button, import as such:
  * import { Button as MUIButton } from "@material-ui/core"
  */

const Button: React.FC<Props> = (props: Props) => {
  const {
    color = 'default',
    children,
    classes: indivButtonClasses = {},
    className,
    loading,
    spinnerColor = 'inherit',
    buttonType,
    spinnerSize,
    btnsize = 'large',
    loadingText,
    countdownSeconds,
    disabled,
    baseButtonVariant = 'contained',
    useOriginalContained,
    ...rest
  } = props
  const classes = useStyles(props)
  const commonClasses = useCommonStyles()

  const buttonClasses = useMemo(() => ({
    [classes.action]: buttonType === 'action',
  }), [buttonType, classes])

  function isMuiButtonVariant(variant: string): variant is muiButtonVariants {
    return variant === 'contained' || variant === 'text' || variant === 'outlined'
  }

  const btnVariant = isMuiButtonVariant(baseButtonVariant) ? baseButtonVariant : undefined

  return (
    <MUIButton
      {...rest}
      className={clsx(
        buttonClasses,
        classes.root,
        { text: baseButtonVariant === 'gradientText', outlined: baseButtonVariant === 'plain' }, btnsize ?? '',
        (baseButtonVariant === 'contained' && !useOriginalContained) && 'baseButtonContained',
        { [classes[`${baseButtonVariant}${capitalize(color as string)}`]]: color !== 'default' && color !== 'inherit' },
        className,
      )}
      classes={{ ...indivButtonClasses }}
      color={colors.includes(color) ? undefined : color}
      disabled={disabled || loading}
      variant={btnVariant}
    >
      <div className={clsx(classes.labelBox, { loading })}>
        {!loading && children}
        {!!loading && loadingText && (
          <div className={commonClasses.flexRow}>
            {loadingText ?? ''}
            <LoadingButtonIndicator color={spinnerColor} size={spinnerSize || '1rem'} customClass={loadingText === 'undefined' ? '' : classes.circularRoot} />
          </div>
        )}
        {!!loading && !loadingText && (
          <LoadingButtonIndicator color={spinnerColor} size={spinnerSize || '1rem'} />
        )}
      </div>
    </MUIButton>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  const buttonColors = makeButtonColors(theme)
  const colorStyles = colors.reduce((styles: {}, color: string) => ({
    ...styles,
    [`contained${capitalize(color)}`]: buttonColors(color),
  }), {})
  return createStyles({
    colorStyles,
    root: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '4px',
      '&.gradientText': {
        ...StyleUtils.gradientStyles,
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      '&.plain': {
        ...theme.typography.body2,
        fontWeight: 700,
        borderRadius: '4px',
        color: '#fff',
        background: theme.palette.background.secondary,
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          background: theme.palette.action.disabledBackground,
        },
      },
      '&.baseButtonContained': {
        ...StyleUtils.primaryButtonStyle(theme),
        color: '#FFFFFF',
        '&.Mui-disabled': {
          background: `${theme.palette.action.disabledBackground} !important`,
          color: theme.palette.text.tertiary ?? 'inherit',
        },
      },
      '&.xLarge': {
        ...theme.typography.body1,
        height: '4rem',
        maxHeight: '4rem',
        fontWeight: 700,
      },
      '&.large': {
        ...theme.typography.body2,
        height: '3rem',
        maxHeight: '3rem',
        fontWeight: 700,
      },
      '&.regular': {
        ...theme.typography.body3,
        height: '2.5rem',
        maxHeight: '2.5rem',
        fontWeight: 700,
      },
      '&.small': {
        ...theme.typography.body4,
        height: '1.5rem',
        maxHeight: '1.5rem',
        fontWeight: 700,
      },
      '&.xSmall': {
        ...theme.typography.body5,
        height: '1.5rem',
        maxHeight: '1.5rem',
        fontWeight: 700,
      },
      '&.navbarTabs': {
        fontFamily: 'Play',
      },
    },
    action: {
      borderRadius: 2,
      marginLeft: 'auto',
    },
    labelBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circularRoot: {
      marginLeft: '0.625rem',
    },
  })
})

export default Button
