import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { jwtDecode } from 'jwt-decode'

import { AlertsApis } from 'js/constants/alerts'
import { ApiError, ApiErrorResponse, ApiResponse, HttpMethod } from 'js/constants/api'
import { JwtUrls } from 'js/constants/auth'

import { sendApiRequest } from '../api'
import { removeUserValue } from '../localstorage'

export const JWT_KEY = '@alerts/EXTERNAL_APIS_JWTS'

export const sendAlertsApiRequest = async <K, T = ApiError>(
  method: HttpMethod,
  url: string,
  body?: any,
  accessToken?: string
): Promise<ApiResponse<K> | ApiErrorResponse<T>> => {
  const response = await sendApiRequest<K, T>(method, url, body, accessToken)

  const isUnauthorized = (response as ApiErrorResponse).error?.code === 401
  if (isUnauthorized && accessToken) removeUnauthorizedJwt(url, accessToken)

  return response
}

export const removeUnauthorizedJwt = (url: string, token: string) => {
  const network = getNetworkFromUrl(url)
  if (network) {
    deleteJwt(network, token)
  }
}

const deleteJwt = (network: Network, token: string) => {
  const decodedToken = jwtDecode(token)
  const address = decodedToken.sub
  if (!address) return
  return removeUserValue(JWT_KEY, address, network)
}

const getNetworkFromUrl = (url: string): Network | null => {
  const parsedUrl = new URL(url)
  const origin = parsedUrl.origin


  for (const [network, urls] of Object.entries(AlertsApis)) {
    if (urls.external.includes(origin) || urls.processor.includes(origin)) {
      return network as Network
    }
  }
  for (const [network, jwtUrl] of Object.entries(JwtUrls)) {
    if (jwtUrl.includes(origin)) {
      return network as Network
    }
  }

  return null
}
