import { BlockchainUtils } from 'carbon-js-sdk'
import { TokensWithExternalBalance } from 'carbon-js-sdk/lib/util/external'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getEthExternalBalances, getNeoExternalBalances, getBscExternalBalances } from 'js/state/modules/account/selectors'

const useExternalBalances = (blockchain: BlockchainUtils.BlockchainV2): TokensWithExternalBalance[] => {
  const extEthBalances = useSelector(getEthExternalBalances)
  const extNeoBalances = useSelector(getNeoExternalBalances)
  const extBscBalances = useSelector(getBscExternalBalances)

  const externalBalances = useMemo(() => {
    switch (blockchain) {
      case 'Ethereum':
        return extEthBalances
      case 'Neo':
        return extNeoBalances
      case 'Binance Smart Chain':
        return extBscBalances
      default:
        return []
    }
  }, [blockchain, extBscBalances, extEthBalances, extNeoBalances])
  return externalBalances
}

export default useExternalBalances
