import { AppCurrency } from '@keplr-wallet/types' // eslint-disable-line import/no-extraneous-dependencies
import { BlockchainUtils, CarbonSDK, CarbonSignerTypes } from 'carbon-js-sdk'
import { ExtendedChainInfo } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved

import { BN_ONE } from 'js/utils/number'
import { SimpleMap } from 'js/utils/types'

/**
 * Naming for non-IBC transfer keys must follow the bridges API chain_name naming
 * Reference: https://api.carbon.network/carbon/coin/v1/bridges?pagination.limit=10000
 * i.e. naming of BSC is Binance Smart Chain, the TransferKey enums have to use 'Binance Smart Chain' as prefix
 */
export enum TransferKey {
  NeoTokenTransfer = 'Neo-nep5',
  NeoO3Wallet = 'Neo-o3wallet',
  NeoLedger = 'Neo-ledger',
  N3Ledger = 'Neo3-ledger',
  N3TokenTransfer = 'Neo3-nep17',
  N3O3Wallet = 'Neo3-o3wallet',
  ERC20TokenTransfer = 'Ethereum-erc20',
  ERC20MetaMask = 'Ethereum-metamask',
  ERC20Ledger = 'Ethereum-ledger',
  BEP20MetaMask = 'Binance Smart Chain-metamask',
  BEP20TokenTransfer = 'Binance Smart Chain-bep20',
  ZilPay = 'Zilliqa-zilpay',
  ZRC2TokenTransfer = 'Zilliqa-zrc2',
  TradeHub = 'tradehub',
  IBC = 'ibc',
  IBCLeap = 'ibcleap',
  ArbitrumTokenTransfer = 'Arbitrum-arb-erc20',
  ArbitrumMetaMask = 'Arbitrum-metamask',
  PolygonTokenTransfer = 'Polygon-polygon-erc20',
  PolygonMetaMask = 'Polygon-metamask',
  OKCTokenTransfer = 'OKC-okc-kip20',
  OKCMetaMask = 'OKC-metamask',
  CarbonEVMBridge = 'CarbonEVM-bridge',
  Rainbowkit = 'Rainbowkit',
}

export enum TransferMethod {
  Token = 'Token Transfer',
  O3Wallet = 'O3 Wallet',
  IBCKeplr = 'IBC Keplr',
  IBCLeap = 'IBC Leap',
  IBC = 'IBC',
  ZilPay = 'ZilPay',
  MetaMask = 'MetaMask',
  Ledger = 'Ledger',
  TradeHub = 'TradeHub',
  Bridge = 'Bridge',
  PF = 'Packet forward',
  Rainbowkit = 'Rainbowkit',
}

export enum TransferType {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export interface TransferChannel {
  denom?: string
  key: TransferKey
  defaultForSigner?: CarbonSignerTypes
  blockchain?: BlockchainUtils.BlockchainV2
  label: string
  tokenType?: string // NEP5, ERC20, etc
  transferMethod: TransferMethod
  isIbc?: boolean

  // packet forwarding details
  additionalTokenInfo?: AdditionalIbcToken
  // isPacketForward?: boolean
  // packetForwardChain?: BlockchainUtils.BlockchainV2
  // packetForwardDenom?: string
  // chainRoute?: BlockchainUtils.BlockchainV2[]
}

export const ONE_SWTH_FEE = {
  amount: BN_ONE,
  denom: 'SWTH',
}

export const tokenStandards: SimpleMap<string> = {
  Ethereum: 'ERC20',
  Neo: 'NEP5',
  Neo3: 'NEP17',
  'Binance Smart Chain': 'BEP20',
  Zilliqa: 'ZRC2',
  Arbitrum: 'Arb-ERC20',
  Polygon: 'Polygon-ERC20',
  OKC: 'OKC-KIP20',
}

export const customLabels: SimpleMap<string> = {
  'Carbon EVM': 'Carbon EVM',
  'Native': 'Internal Transfer',
}

export const ibcLeapMethodRegex = /ibcleap$/

export const isGroupedTokenRegex = /^cgt\//i

export const isGroupedTokenDenom = (denom: string) => {
  return new RegExp(isGroupedTokenRegex).test(denom)
}

/**
 * Check if denom passed in is valid fee quote
 * i.e. not LP, not CDP, not IBC, not CDP-IBC, not grouped token
 * @param denom token denom
 * @returns boolean (true or false)
 */
export const isValidFeeQuote = (denom: string): boolean => {
  return denom.length > 0
    && !isGroupedTokenDenom(denom)
    && !CarbonSDK.TokenClient.isIBCDenom(denom)
    && !CarbonSDK.TokenClient.isCdpIbcDenom(denom)
    && !CarbonSDK.TokenClient.isCdpToken(denom)
    && !CarbonSDK.TokenClient.isPoolToken(denom)
}

export const placeholderMap: { [key: string]: string } = {
  Ethereum: '0x520...',
  'Binance Smart Chain': '0x07d...',
  Neo3: 'NiQ4n...',
  Neo: 'Anjmn...',
  Zilliqa: 'zil12...',
  Native: 'swth1q...',
  'Carbon EVM': '0x057...',
}

export const blockchainRankMap: {
  [key in BlockchainUtils.BlockchainV2]: number
} = {
  Osmosis: 1,
  'Cosmos Hub': 0,
  Evmos: 2,
  Axelar: 3,
  Juno: 4,
  Stride: 5,
  Kujira: 6,
  Terra: 7,
  Comdex: 8,
  Stafihub: 9,
  'Persistence Core': 10,
  Stargaze: 11,
  Canto: 12,
  Sommelier: 13,
  'IRIS Hub': 14,
  Ethereum: 15,
  Arbitrum: 16,
  Polygon: 17,
  OKC: 18,
  'Binance Smart Chain': 19,
  Carbon: 20,
  Neo: 21,
  Neo3: 22,
  Zilliqa: 23,
}

export const slowEvmChains: BlockchainUtils.BlockchainV2[] = [
  'Ethereum', 'Arbitrum', 'Polygon',
]

export interface PacketForwardChainInfo {
  channel: string
  address?: string
  chainId: string
  chainInfo?: ExtendedChainInfo
}

export type ChainToChannelMap = Record<string, string>

export type ExternalChainChannelMap = Record<string, ChainToChannelMap>

/**
 * Object containing the breakdown of destination channels for each IBC chain (outside the Carbon ecosystem)
 * E.g. To transfer tokens from Osmosis => Noble, you need to use channel-750 (i.e. input it as sourceChannel under MsgTransfer tx)
 */
export const defaultExternalChainChannels: {
  [key in CarbonSDK.Network]: ExternalChainChannelMap
} = {
  [CarbonSDK.Network.MainNet]: {
    Osmosis: {
      Carbon: 'channel-188', // for swth on osmosis
    },
  },
  [CarbonSDK.Network.TestNet]: {},
  [CarbonSDK.Network.DevNet]: {},
  [CarbonSDK.Network.LocalHost]: {},
}

export interface SourceChainInfo {
  // src chain name (i.e. name of bridge on Carbon chain)
  chainName: BlockchainUtils.BlockchainV2
  // src chain's ibc chain id
  chainId: string
}

export interface ChainRouteInfo {
  // chain we're transferring from
  fromChain: BlockchainUtils.BlockchainV2
  // chain we're transferring to
  toChain: BlockchainUtils.BlockchainV2
  // chain id for the blockchain we're transferring from
  fromChainId: string
  // chain id for the blockchain we're transferring to
  toChainId: string
  // channel to travel from fromChain => toChain
  channelId: string
}

export interface ChainRouteInfoWithAddress extends ChainRouteInfo {
  address?: string
}

export interface PacketForwardData {
  firstInterChain?: ChainRouteInfoWithAddress
  forwardInterchain: ChainRouteInfoWithAddress[]
}

export type ChainRoutes = [BlockchainUtils.BlockchainV2, ...BlockchainUtils.BlockchainV2[]]

export interface AdditionalIbcTokenConfigItem {
  baseDenom: string
  chainRoutes: ChainRoutes // i.e. should have at least 1 item
  denomOnCarbon?: string
}

export interface AdditionalIbcToken {
  // indicates whether or not token is added to Tokens API on chain
  // e.g. SWTH via Osmosis is not added to chain whereas axlUSDC via Osmosis is added to chain
  addedOnChain: boolean

  // currency to be inserted into chain info config
  appCurrency: AppCurrency

  // base denom of token (same as baseDenom field on AdditionalIbcTokenConfigItem)
  baseDenom: string

  // Array of ChainRouteInfo objects (derived from chainRoutes on AdditionalIbcTokenConfigItem)
  chainRoutesInfoArr: {
    deposit: ChainRouteInfo[]
    withdraw: ChainRouteInfo[]
  }

  // For each packet forwarded token that is not added to Tokens API on chain (e.g. USDC via Archway)there is a token that is already recognised on Carbon.
  // This field holds the denom for that token
  // For packet fowarded tokens that are added to Tokens API (e.g. ATOM via CosmosHub), it will be the same value as denomOnCarbon
  // For SWTH on Osmosis, it will be swth
  carbonRecognisedDenom: string

  // indicates whether token requires packet-fowarding to be deposited into Carbon
  isPacketForwardToken: boolean

  // Source chain info (i.e. information for 1st chain of chainRoutes array)
  srcChainInfo: SourceChainInfo
}

// additionalIbcTokenConfig to be added to demex-webapp-config
export const defaultAddIbcTokenConfig: {
  [key in CarbonSDK.Network]: AdditionalIbcTokenConfigItem[]
} = {
  [CarbonSDK.Network.MainNet]: [{
    baseDenom: 'swth', // Record for swth on osmosis
    chainRoutes: ['Osmosis'],
  }],
  [CarbonSDK.Network.TestNet]: [],
  [CarbonSDK.Network.DevNet]: [],
  [CarbonSDK.Network.LocalHost]: [],
}

/**
 * Forward metadata under format
 * {
 * "forward": {
 *   "receiver": "pfm",
 *   "port": "transfer",
 *   "channel": "channel-123",
 *   "next": {
 *     "forward": {
 *       "receiver": "chain-d-bech32-address",
 *       "port": "transfer",
 *       "channel":"channel-234",
 *     }
 *   }
 *  }
 * }
*/
export const generatePacketForwardMetadata = (chains: ChainRouteInfoWithAddress[]): string => {
  const depth = chains.length

  const metadata = chains.reduceRight((prev, next, index) => {
    const currentForwardData = {
      receiver: next.address,
      channel: next.channelId,
      port: 'transfer'
    }

    if (index === depth) {
      return {
        next: {
          forward: currentForwardData
        }
      }
    }

    if (index === 0) {
      return {
        forward: {
          ...currentForwardData,
          ...prev
        }
      }
    }

    return {
      next: {
        forward: {
          ...currentForwardData,
          ...prev
        }
      }
    }
  }, {} as any)

  return JSON.stringify(metadata)
}

export const hideXferConfig: {
  [key in TransferType]: Set<string>
} = {
  [TransferType.Deposit]: new Set([]),
  [TransferType.Withdraw]: new Set([]),
}
