import BigNumber from 'bignumber.js'
import { TypeUtils } from 'carbon-js-sdk'
import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { GetFeeQuoteResponse } from 'carbon-js-sdk/lib/hydrogen/feeQuote' // eslint-disable-line
import { Dayjs } from 'dayjs'
import { action } from 'typesafe-actions'

import { TransferChannel } from 'js/constants/TransferOptions'

import { DenomValue, SelectedToken, TokenContractButtonPopUpState, TokenProperties, UserPortfolio, WalletBalance, WalletBalanceActionTypes, WalletBalanceStateProps } from './types'


/** WEBSOCKET ONLY: DO NOT CALL FROM COMPONENT */
export const setWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_WALLET_BALANCE,
  payload,
)

export const setEvmWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_EVM_WALLET_BALANCE,
  payload,
)
export const setCarbonWalletBalance = (payload: TypeUtils.SimpleMap<TokenBalance>) => action(
  WalletBalanceActionTypes.SET_CARBON_WALLET_BALANCE,
  payload,
)
export const setBalances = (payload: TypeUtils.SimpleMap<WalletBalance>) => action(
  WalletBalanceActionTypes.SET_BALANCES,
  payload,
)
export const setAdjustedBalances = (payload: TypeUtils.SimpleMap<WalletBalance>) => action(
  WalletBalanceActionTypes.SET_ADJUSTED_BALANCES,
  payload,
)
export const setPortfolio = (payload: UserPortfolio) => action(
  WalletBalanceActionTypes.SET_PORTFOLIO,
  payload,
)
export const setNonZeroBalanceDenoms = (payload: string[]) => action(
  WalletBalanceActionTypes.SET_NON_ZERO_BALANCE_DENOMS,
  payload,
)
export const setDerivedTokenRedemptionRate = (payload: Record<string, DenomValue[]>) => action(
  WalletBalanceActionTypes.SET_DERIVED_TOKEN_REDEMPTION,
  payload,
)
export const clearWalletBalance = () => action(WalletBalanceActionTypes.CLEAR_WALLET_BALANCE)

export const checkSelectToken = (token: Token) => action(
  WalletBalanceActionTypes.CHECK_SELECT_TOKEN,
  token,
)

export const setSelectedToken = (token: SelectedToken) => action(
  WalletBalanceActionTypes.SET_SELECTED_TOKEN,
  token,
)

export const selectDepositOption = (option: TransferChannel | null) => action(
  WalletBalanceActionTypes.SELECT_DEPOSIT_OPTION,
  option,
)

export const selectWithdrawOption = (option: TransferChannel | null) => action(
  WalletBalanceActionTypes.SELECT_WITHDRAW_OPTION,
  option,
)

export const selectGroupTokenOption = (tokenSymbol: string | null) => action(
  WalletBalanceActionTypes.SELECT_GROUP_TOKEN_OPTION,
  tokenSymbol,
)

export const retrieveWalletFees = (denom: string) => action(
  WalletBalanceActionTypes.GET_WALLET_FEES,
  denom,
)

export const setWalletFees = (feeInfo: GetFeeQuoteResponse) => action(
  WalletBalanceActionTypes.SET_WALLET_FEES,
  feeInfo,
)

export const setHasSufficientFees = (hasSufficientFees: boolean) => action(
  WalletBalanceActionTypes.SET_HAS_SUFFICIENT_FEES,
  hasSufficientFees,
)

export const clearWalletFees = (feeDenom: string) => action(
  WalletBalanceActionTypes.CLEAR_WALLET_FEES,
  feeDenom,
)

export const setRawDelegations = (delegations: WalletBalanceStateProps["rawDelegations"]) => action(
  WalletBalanceActionTypes.SET_RAW_DELEGATIONS,
  delegations,
)
export const setRawUnbondingDelegations = (unbondings: WalletBalanceStateProps["rawUnbondingDelegations"]) => action(
  WalletBalanceActionTypes.SET_RAW_UNBONDING_DELEGATIONS,
  unbondings,
)

export const queryNeoTokenBalance = (address: string) => action(
  WalletBalanceActionTypes.QUERY_NEO_TOKEN_BALANCE,
  address,
)

export const setNeoTokenBalance = (balance: BigNumber) => action(
  WalletBalanceActionTypes.SET_NEO_TOKEN_BALANCE,
  balance,
)

export const setTokenContractButtonPopupState = (state: TokenContractButtonPopUpState) => action(
  WalletBalanceActionTypes.SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE,
  state,
)

export const setTokenProperties = (state: TypeUtils.SimpleMap<TokenProperties>) => action(
  WalletBalanceActionTypes.SET_TOKEN_PROPERTIES,
  state,
)

export const setLastInsufficientFeeToast = (state: Dayjs | null) => action(
  WalletBalanceActionTypes.SET_LAST_INSUFFICIENT_FEE_TOAST,
  state,
)
