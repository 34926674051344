import { ApiError, HttpMethod, ApiResponse, ApiErrorResponse } from 'js/constants/api'

export const sendApiRequest = async <K, T = ApiError>(method: HttpMethod, url: string, body?: any, accessToken?: string): Promise<ApiResponse<K> | ApiErrorResponse<T>> => {
  const headers: HeadersInit = {
    'content-type': 'application/json',
    ...(accessToken && { 'Authorization': `Bearer ${accessToken}` })
  }
  const res = await fetch(url, { method, headers, ...(body && { body: JSON.stringify(body) }) })
  return await res.json()
}




