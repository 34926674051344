import BigNumber from 'bignumber.js'
import { Models, TypeUtils } from 'carbon-js-sdk'
import { TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'

import { BN_ZERO } from 'js/utils/number'

import { AdjustedBalance, DenomValue, SelectedToken, TokenContractButtonPopUpState, TokenProperties, UserPortfolio, WalletBalance, WalletFees } from './types'

import { RootState } from '../rootReducer'

export function getWalletBalances(state: RootState): TypeUtils.SimpleMap<WalletBalance> {
  return state.walletBalance.balances
}
export function getPortfolio(state: RootState): UserPortfolio {
  return state.walletBalance.portfolio
}

export function getEvmAddressBalances(state: RootState): TypeUtils.SimpleMap<TokenBalance> {
  return state.walletBalance.evmBalances
}
export function getCarbonBalances(state: RootState): TypeUtils.SimpleMap<TokenBalance> {
  return state.walletBalance.carbonBalances
}

export function getQuoteBalances(state: RootState): WalletBalance | null {
  const { walletBalance, exchange } = state
  const quote = exchange.market?.quote ?? ''
  return walletBalance.balances?.[quote] ?? null
}

export function getSelectedToken(state: RootState): SelectedToken | null {
  return state.walletBalance.selectedToken
}

export function getWalletFees(state: RootState): WalletFees {
  return state.walletBalance.walletFees
}

export function getIsUSD(state: RootState): any {
  return state.walletBalance.isUSD
}

export function getRawDelegations(state: RootState): Models.Staking.DelegationResponse[] | null {
  return state.walletBalance.rawDelegations
}

export function getRawUnbondingDelegations(state: RootState): Models.Staking.UnbondingDelegation[] | null {
  return state.walletBalance.rawUnbondingDelegations
}

/** @deprecated use getRawDelegations */
export function getAccountDelegations(state: RootState): Models.Staking.DelegationResponse[] {
  return []
}

export function getNeoTokenBalance(state: RootState): BigNumber {
  return state.walletBalance.neoTokenBalance
}

export function getTokenContractButtonPopupState(state: RootState): TokenContractButtonPopUpState {
  return state.walletBalance.tokenContractButtonPopUpState
}

export function getAdjustedStake(state: RootState): TypeUtils.SimpleMap<BigNumber> {
  return state.walletBalance.adjustedStake
}

export function getTokenProperties(state: RootState): TypeUtils.SimpleMap<TokenProperties> {
  return state.walletBalance.tokenProperties
}

export function getAdjustedBalances(state: RootState): TypeUtils.SimpleMap<AdjustedBalance> {
  return state.walletBalance.adjustedBalances
}

export function getAdjustedBalance(denom: string = ''): (state: RootState) => AdjustedBalance | undefined {
  return (state: RootState) => state.walletBalance.adjustedBalances[denom]
}

export function getNonZeroBalanceDenoms(state: RootState): string[] {
  return state.walletBalance.nonZeroBalanceDenoms ?? []
}

export function getDerivedTokenRedemption(state: RootState): Record<string, DenomValue[]> {
  return state.walletBalance.derivedTokenRedemption
}

export function getDecimals(denom: string = ''): (state: RootState) => number {
  return (state: RootState) => {
    return state.walletBalance.tokenProperties[denom]?.tokenCDPInfo.isCdpToken
      ? state.walletBalance.tokenProperties[denom]?.tokenCDPInfo.underlyingDecimals
      : state.walletBalance.tokenProperties[denom]?.tokenBasicInfo.decimals
      ?? 0
  }
}

export function getUsdValue(denom: string) {
  return (state: RootState) => {
    // if token is derived (e.g. cdp or lp token)
    const underlyingTokens = state.walletBalance.derivedTokenRedemption[denom]
    return underlyingTokens?.reduce((sum, [underlyingDenom, rate]) => {
      // need to convert to human before applying USD conversion rate
      const denomDecimals = getDecimals(denom)(state)
      const underlyingDecimals = getDecimals(underlyingDenom)(state)
      const hRate = rate.shiftedBy(denomDecimals - underlyingDecimals)
      return sum.plus(hRate.times(state.app.carbonSDK?.token.usdValues[underlyingDenom] ?? 0))
    }, BN_ZERO)

      // otherwise, get token price as if its underlying
      ?? state.app.carbonSDK?.token.usdValues[denom]
  }
}
