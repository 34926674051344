import { CarbonSDK } from 'carbon-js-sdk'
import preval from 'preval.macro'
import { isMobile } from 'react-device-detect'

export enum DeployEnv {
  master = 'master',
  staging = 'staging',
  develop = 'develop',
  alpha = 'alpha',
  local = 'local',
}

const readDeployEnv = (): DeployEnv => {
  const env = (process.env.REACT_APP_DEPLOY_ENV ?? DeployEnv.local)
  const validEnvs = Object.values(DeployEnv) as string[]
  if (validEnvs.includes(env)) return env as DeployEnv
  return DeployEnv.local
}

export const DEPLOY_ENV = readDeployEnv()
if (window) {
  (window as any).DEPLOY_ENV = DEPLOY_ENV
}

export function getDefaultNetwork(): CarbonSDK.Network {
  switch (DEPLOY_ENV) {
    case DeployEnv.master:
      return CarbonSDK.Network.MainNet
    case DeployEnv.staging:
      return CarbonSDK.Network.TestNet
    case DeployEnv.alpha:
    case DeployEnv.develop:
      return CarbonSDK.Network.DevNet
    case DeployEnv.local:
    default: return CarbonSDK.Network.LocalHost
  }
}

enum ContentfulEnv {
  alpha = 'alpha',
  beta = 'beta',
  develop = 'dev',
  production = 'production'
}
export function getContentfulEnvironment(): ContentfulEnv {
  switch (DEPLOY_ENV) {
    case DeployEnv.master:
      return ContentfulEnv.production
    case DeployEnv.staging:
      return ContentfulEnv.beta
    case DeployEnv.alpha:
      return ContentfulEnv.alpha
    case DeployEnv.develop:
      return ContentfulEnv.develop
    case DeployEnv.local:
    default: return ContentfulEnv.production
  }
}

export const IS_ENV_MASTER = DEPLOY_ENV === DeployEnv.master
export const IS_ENV_STAGING = DEPLOY_ENV === DeployEnv.staging
export const IS_ENV_DEVELOP = DEPLOY_ENV === DeployEnv.develop
export const IS_ENV_ALPHA = DEPLOY_ENV === DeployEnv.alpha
export const IS_ENV_LOCAL = DEPLOY_ENV === DeployEnv.local

export const IS_STRATEGIES_MAINNET = IS_ENV_MASTER

export const IS_EDIT_ORDER_ENABLED = true

export const HIDE_STRATEGIES = IS_ENV_MASTER || IS_ENV_DEVELOP

// perma hide this until we revive the feature
export const HIDE_FLUO_STAKING = true

export const HIDE_FIRST_PERP_POOL_MAINNET = (network: CarbonSDK.Network) => {
  return network === CarbonSDK.Network.MainNet
}

// hide plp incentives
export const HIDE_PLP_INCENTIVES = IS_ENV_MASTER

export const isTouchDevice = () => {
  return typeof window.ontouchstart !== 'undefined'
    || (navigator.maxTouchPoints > 0)
}

/**
 * checks if browser is Safari
 */
export const isSafariDevice = () => {
  // Additional check for mobile safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  return (window as any).safari !== undefined || isSafari
}

/**
 * checks if browser is Chrome in iOS mobile
 */
export const isChromeIOS = () => {
  return navigator.userAgent.match('CriOS')
}


/**
 * checks if browser is MetaMask Mobile in-app browser
 */
export const isMetaMaskMobileBrowser = () => {
  return navigator.userAgent.match('MetaMaskMobile')
}

/**
 * checks if browser is Keplr Mobile in-app browser
 */
export const isKeplrMobileBrowser = () => {
  return navigator.userAgent.match('KeplrWalletMobile')
}

/**
 * checks if browser is Leap Mobile in-app browser
 */
export const isLeapMobileBrowser = () => {
  return navigator.userAgent.match('LeapCosmos')
}

/**
 * checks if browser is in-app web3 mobile browser
 */
export const isWeb3MobileBrowser = () => {
  return isMobile && (
    window?.ethereum?.isCoinbaseWallet
    || window?.ethereum?.isBitKeep
    || (window as any)?.okxwallet
  )
}

/**
 * checks if browser is a mobile wallet in-app browser
 */
export const isMobileWalletBrowser = () => {
  return isMetaMaskMobileBrowser() || isKeplrMobileBrowser() || isLeapMobileBrowser() || isWeb3MobileBrowser()
}

/**
 * checks if browser is Mozilla Firefox
*/
export const isMozillaDevice = (): boolean => {
  return navigator?.userAgent.toLowerCase().indexOf('firefox') > -1
}

/**
 * checks if browser is chromium based
*/
export const isChromiumDevice = (): boolean => {
  return (/chrom(e|ium)/.test(navigator?.userAgent.toLowerCase()))
}

/**
 * if this is a mobile device, return mobile os
 * @returns 'ios' | 'android' | null
 */
export type MobileOS = 'ios' | 'android' | null
export const getMobileOS = (): MobileOS => {
  const userAgent = navigator.userAgent
  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    return 'ios'
  }
  if (userAgent.includes('Android')) {
    return 'android'
  }
  return null
}

export const isInStandaloneMode = () =>
  (window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in window.navigator) && (window.navigator.standalone)) || document.referrer.includes('android-app://')


export const isBrave = () => (navigator as any).brave

export const isWindowsDevice = () => {
  // @ts-ignore: userAgentData is not on type Navigator but its still supported by certain browsers' versions
  const platform: string = window.navigator?.userAgentData?.platform || window.navigator.platform
  // Array of strings representing various Windows platforms sourced from common Windows operating system versions: Win32, Win64, Windows, and WinCE
  const windowsPlatforms: string[] = ['Win32', 'Win64', 'Windows', 'WinCE']

  return windowsPlatforms.some((winPlatform) => platform.includes(winPlatform))
}

// evaluates at yarn build
export const BUILD_TIMESTAMP = preval`module.exports = require('dayjs')().format('YYYYMMDD/HHmmss')`

export const isPushNotificationSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window