import { CarbonSDK, ProviderAgent } from "carbon-js-sdk"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useDisconnect } from "wagmi"

import { useAsyncTask } from 'js/hooks'
import { logout } from 'js/state/modules/account/actions'
import { resetAlertsData } from "js/state/modules/alerts/actions"
import { setCarbonSDK, setLegacyAccounts, setLegacyLoggedIn, setSubPage } from 'js/state/modules/app/actions'
import { Page } from 'js/state/modules/app/types'
import { setActiveStep } from 'js/state/modules/competition/actions'
import { disconnectWeb3Auth } from 'js/utils/web3auth'

const useDisconnectWallet = (sdk?: CarbonSDK | null) => {
  const dispatch = useDispatch()
  const [runDisconnectWallet] = useAsyncTask('disconnectWallet')
  const { connectors: connectedConnectors, disconnect: disconnectRainbowKit } = useDisconnect()

  const disconnect = useCallback(async () => {
    if (!sdk?.wallet) return

    try {
      dispatch(logout())
      dispatch(setSubPage(Page.Main))
      dispatch(setActiveStep(1)) // reset comp registration
      dispatch(resetAlertsData()) // reset user alerts data
      dispatch(setLegacyAccounts([])) // reset metamask legacy accounts
      dispatch(setLegacyLoggedIn(false)) // reset metamask legacy login flag
      if (sdk?.wallet?.providerAgent === ProviderAgent.Web3Auth) {
        await disconnectWeb3Auth()
      }
      // disconnect all connected wallets
      disconnectRainbowKit()
      connectedConnectors.forEach((connector) => {
        disconnectRainbowKit({connector})
      })

      const disconnectedSDK: CarbonSDK = sdk.disconnect()
      dispatch(setCarbonSDK(disconnectedSDK))
    } catch (err) {
      console.error(err)
    }
  }, [connectedConnectors, disconnectRainbowKit, dispatch, sdk])

  return () => runDisconnectWallet(disconnect)
}

export default useDisconnectWallet
