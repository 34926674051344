import { TypeUtils } from 'carbon-js-sdk'

import { GroupTokenDetails } from 'js/state/modules/app/types'
import { WalletBalance } from 'js/state/modules/walletBalance/types'

export const isGroupToken = (groupTokenDetails: TypeUtils.SimpleMap<GroupTokenDetails>, tokenDenom: string) => {
  return Boolean(groupTokenDetails[tokenDenom])
}

export const getGroupTokenDetails = (groupTokenDetails: TypeUtils.SimpleMap<GroupTokenDetails>, tokenDenom: string): GroupTokenDetails | undefined => {
  const groupTokenDetail = groupTokenDetails[tokenDenom] ?? Object.values(groupTokenDetails).find((group) => group.registeredDenoms.includes(tokenDenom))
  return groupTokenDetail
}

/**
 * @deprecated use getTokenProperties
 * @param groupTokenDetails
 * @param tokenDenom
 * @returns whether token registered under a grouped token
 */
export const isPartOfGroup = (groupTokenDetails: TypeUtils.SimpleMap<GroupTokenDetails>, tokenDenom: string) => {
  const groupDetails = Object.values(groupTokenDetails).find((group) => group.registeredDenoms.includes(tokenDenom))
  return Boolean(groupDetails)
}

export const hasRegisteredTokenBalance = (groupTokenDetails: TypeUtils.SimpleMap<GroupTokenDetails>, balances: TypeUtils.SimpleMap<WalletBalance>) => {
  Object.values(groupTokenDetails).forEach((groupTokenDetail) => {
    if (groupTokenDetail.registeredDenoms?.find((denom) => balances[denom]?.available?.gt(0))) {
      return true
    }
  })
  return false
}
