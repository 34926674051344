import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { ReferralActionTypes, ReferralState, ReferralStateProps } from './types'

export const DefaultInitialState: Record.Factory<ReferralStateProps> = Record<ReferralStateProps>({
  ambassador: false,
  urlReferralId: '',
  referralIdAddr: '',
  referralIdComm: '0',
  referralIdKb: '0',
  refIdRefereeDetails: [],
  openRefPopUp: false,
  showRefBanner: false,
  existingReferrer: null,
  referrerAddress: '',
  referrerComm: 0,
  referrerKb: 0,
  referrerUsername: '',
  refereeCount: '0',
  refereeDetails: [],
  referralLinks: [],
  amountEarned: '0',
  topEarned: '0',
  topEarnedAddress: '0',
  tradeList: [],
})

const defaultInitialState: ReferralState = new DefaultInitialState()

export const referralReducer = (
  state: ReferralState = defaultInitialState,
  action: AnyAction,
): ReferralState => {
  switch (action.type) {
    case ReferralActionTypes.RESET_REFERRALS: {
      return new DefaultInitialState()
    }
    case ReferralActionTypes.SET_AMBASSADOR: {
      return state.set('ambassador', action.payload)
    }
    case ReferralActionTypes.SET_URL_REFERRAL_ID: {
      return state.set('urlReferralId', action.payload)
    }
    case ReferralActionTypes.SET_REFERRAL_ID_ADDR: {
      return state.set('referralIdAddr', action.payload)
    }
    case ReferralActionTypes.SET_REFERRAL_ID_COMM: {
      return state.set('referralIdComm', action.payload)
    }
    case ReferralActionTypes.SET_REFERRAL_ID_KB: {
      return state.set('referralIdKb', action.payload)
    }
    case ReferralActionTypes.SET_REF_ID_REFEREE_DETAILS: {
      return state.set('refIdRefereeDetails', action.payload)
    }
    case ReferralActionTypes.SET_OPEN_REF_POPUP: {
      return state.set('openRefPopUp', action.payload)
    }
    case ReferralActionTypes.SET_SHOW_REF_BANNER: {
      return state.set('showRefBanner', action.payload)
    }
    case ReferralActionTypes.SET_EXISTING_REFERRER: {
      return state.set('existingReferrer', action.payload)
    }
    case ReferralActionTypes.SET_REFERRER_ADDR: {
      return state.set('referrerAddress', action.payload)
    }
    case ReferralActionTypes.SET_REFERRER_COMM: {
      return state.set('referrerComm', action.payload)
    }
    case ReferralActionTypes.SET_REFERRER_KB: {
      return state.set('referrerKb', action.payload)
    }
    case ReferralActionTypes.SET_REFERRER_USERNAME: {
      return state.set('referrerUsername', action.payload)
    }
    case ReferralActionTypes.SET_REFEREE_COUNT: {
      return state.set('refereeCount', action.payload)
    }
    case ReferralActionTypes.SET_REFEREE_DETAILS: {
      return state.set('refereeDetails', action.payload)
    }
    case ReferralActionTypes.SET_REF_LINKS: {
      return state.set('referralLinks', action.payload)
    }
    case ReferralActionTypes.SET_AMT_EARNED: {
      return state.set('amountEarned', action.payload)
    }
    case ReferralActionTypes.SET_TOP_EARNED: {
      return state.set('topEarned', action.payload)
    }
    case ReferralActionTypes.SET_TOP_EARNED_ADDR: {
      return state.set('topEarnedAddress', action.payload)
    }
    case ReferralActionTypes.SET_TRADE_LIST: {
      return state.set('tradeList', action.payload)
    }
    default: {
      return state
    }
  }
}
