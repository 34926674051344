import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { AnyAction } from 'redux'

import { AlertsApis } from 'js/constants/alerts'
import { ApiErrorResponse, ApiResponse, HttpMethod } from 'js/constants/api'

import { sendAlertsApiRequest } from './jwt'
import { TelegramDialogActionType, UpdateVerificationStatus, VerificationType } from './verify'


export interface UserProfile {
  id: number
  address: string
  name: string | null
  botChatId: string | null
  email: string | null
  telegramHandle: string | null
}

export const getProfileFromRawResponse = (user: RawUserProfile): UserProfile => {
  const { emailVerifiedAt, emailCreatedAt, botVerifiedAt, ...rest } = user
  return { ...rest }
}

export const getVerificationStatusUpdates = (user: RawUserProfile): UpdateVerificationStatus[] => {
  const { emailVerifiedAt, emailCreatedAt, botVerifiedAt } = user
  return [{
    type: VerificationType.Email,
    update: {
      verifiedAt: emailVerifiedAt,
      createdAt: emailCreatedAt,
    }
  },
  {
    type: VerificationType.Telegram,
    update: {
      verifiedAt: botVerifiedAt,
    }
  }]
}

export interface RawUserProfile extends UserProfile {
  emailCreatedAt: Date | null
  emailVerifiedAt: Date | null
  botVerifiedAt: Date | null
}

export interface UpdateProfile {
  email?: string
  botChatId?: string
}
export interface UserProfileResponse {
  model: RawUserProfile
}
export interface UserPreferencesResponse {
  model: RawPreference[]
}

export interface Preference {
  category: Category
  disabledChannels: Channel[] | null
}
export interface RawPreference extends Preference {
  subscribedAddress: string
  subscriberAddress: string
}

// preserve order as to how it would be shown on preferences UI
export enum Channel {
  Email = 'email',
  Telegram = 'telegram',
  Push = 'push',
}

export const AllChannels: Channel[] = Object.values(Channel)

// preserve order as to how it would be shown on inbox UI
export enum Section {
  Transactions = 'Transactions',
  Updates = 'Updates',
  Promotions = 'Promotions',
}

export type Category = TransactionCategory | UpdatesCategory | PromotionsCategory


export interface PreferencesTabDisplay {
  title: string
  description: string
  categoryDisplays: CategoryDisplay[]
}

export interface CategoryDisplay {
  categories: Category[]
  title: string
  description: string
  toggleableChannels: Channel[]
}
export enum TransactionCategory {
  OrderPlaced = 'order-placed',
  OrderFilled = 'order-filled',
  OrderCancelled = 'order-cancelled',
  PositionOpened = 'position-opened',
  PositionClosed = 'position-closed',
  PositionLiquidated = 'position-liquidated',
  PositionAutoDeleveraged = 'position-auto-deleveraged',
  PositionSettled = 'position-settled',
  PositionUpdate = 'position-update',

  NitronLiquidations = 'nitron-liquidations',

  Deposit = 'deposit',
  Withdraw = 'withdraw',
  InternalTransfer = 'internal-transfer',
}

export enum UpdatesCategory {
  NitronNewAsset = 'nitron-new-asset',
  NitronLendingRewards = 'nitron-lending-rewards',

  LiquidityPoolsRewardWeights = 'liquidity-pools-rewards-weights',
  LiquidityPoolsRewardCurve = 'liquidity-pools-reward-curve',
  LiquidityPoolsCommitmentCurve = 'liquidity-pools-commitment-curve',
  StakeUnbondComplete = 'stake-unbond-complete',
  NewPerpPool = 'new-perp-pool',

  NewMarket = 'new-market',
}

export enum PromotionsCategory {
  Promotions = 'promotions',
}

export enum DialogCategory {
  NoToken = "no-token",
  TokenExpired = "token-expired",
}
export interface AuthDialog {
  open: boolean
  dialogCategory?: DialogCategory
  retry?: AnyAction
  revert?: AnyAction
}
export interface TelegramDialog {
  open: boolean
  action?: TelegramDialogActionType
  token?: string
}
export type Id = number

// preserve order as to how it would be shown on preferences UI
export const PreferencesDisplay: Record<Section, PreferencesTabDisplay> = {
  [Section.Transactions]: {
    title: 'Transactions',
    description: 'Set your preferences to receive updates on your transactions.',
    categoryDisplays: [
      {
        categories: [TransactionCategory.OrderFilled],
        title: 'Filled Orders',
        description: 'Notify me when my order is filled.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.OrderPlaced],
        title: 'Created Orders',
        description: 'Notify me when my order is created.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.OrderCancelled],
        title: 'Canceled Orders',
        description: 'Notify me when my order is canceled.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionClosed],
        title: 'Closed Positions',
        description: 'Notify me when my position is closed.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionLiquidated],
        title: 'Liquidated Positions',
        description: 'Notify me when my position is liquidated.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionOpened],
        title: 'Opened Positions',
        description: 'Notify me when my position is opened.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionUpdate],
        title: 'Position Updates',
        description: 'Notify me when there is a change in collateral on my position.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionSettled],
        title: 'Settled Positions',
        description: 'Notify me when my position is settled due to an expired market.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.PositionAutoDeleveraged],
        title: 'Auto-Deleveraging',
        description: 'Notify me when my position is auto-deleveraged.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.NitronLiquidations],
        title: 'Liquidations',
        description: 'Notify me when my collateral is partially liquidated.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.Deposit],
        title: 'Cross-chain Deposits',
        description: 'Notify me when my deposits into Demex are complete.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.Withdraw],
        title: 'Cross-chain Withdrawals',
        description: 'Notify me when my withdrawals from Demex are complete.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [TransactionCategory.InternalTransfer],
        title: 'Internal Transfers',
        description: 'Notify me when I have received tokens via Internal Transfer.',
        toggleableChannels: AllChannels,
      },
    ]
  },
  [Section.Updates]: {
    title: 'Updates',
    description: 'Set your preferences to receive system updates.',
    categoryDisplays: [
      {
        categories: [UpdatesCategory.NewMarket],
        title: 'New Perp Markets',
        description: 'Notify me about new perpetual market launches.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [UpdatesCategory.NitronNewAsset],
        title: 'New Asset Listing',
        description: 'Notify me when a new asset is listed to Nitron.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [UpdatesCategory.NewPerpPool],
        title: 'New Perp Pool',
        description: 'Notify me when a new perpectual pool is created.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [UpdatesCategory.NitronLendingRewards],
        title: 'Lending Pool Rewards',
        description: 'Notify me when any asset on Nitron is incentivised.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [UpdatesCategory.LiquidityPoolsRewardWeights, UpdatesCategory.LiquidityPoolsRewardCurve, UpdatesCategory.LiquidityPoolsCommitmentCurve],
        title: 'Spot Pool Reward Updates',
        description: 'Notify me when there are any updates to the spot pool rewards.',
        toggleableChannels: AllChannels,
      },
      {
        categories: [UpdatesCategory.StakeUnbondComplete],
        title: 'Unbond Completed',
        description: 'Notify me when the unbonding duration for my staked assets has ended.',
        toggleableChannels: [Channel.Push],
      },
    ]
  },
  [Section.Promotions]: {
    title: 'Promotions',
    description: 'Set your preferences to receive messages for marketing promotions and campaigns.',
    categoryDisplays: [],
  },
}

export const getTabToggleableChannels = (details: PreferencesTabDisplay): Channel[] => {
  return Object.values(Channel).reduce((acc, c) => {
    const found = details.categoryDisplays.find(cat => cat.toggleableChannels.includes(c))
    if (found) acc.push(c)
    return acc
  }, [] as Channel[])
}


export const SectionCategories: Record<Section, Category[]> = {
  [Section.Transactions]: Object.values(TransactionCategory),
  [Section.Updates]: Object.values(UpdatesCategory),
  [Section.Promotions]: Object.values(PromotionsCategory),
}

export const getSectionFromCategory = (catgeory: Category): Section | undefined => {
  return Object.values(Section).find(section => {
    const categories = SectionCategories[section]
    return categories.includes(catgeory)
  })
}

export const queryProfile = async (network: Network, accessToken: string): Promise<ApiResponse<UserProfileResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/user/profile`
  return await sendAlertsApiRequest<UserProfileResponse>(HttpMethod.GET, url, null, accessToken)
}

export const profileUpdate = async (network: Network, accessToken: string, update: UpdateProfile): Promise<ApiResponse<UserProfileResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/user/profile/update`
  return await sendAlertsApiRequest<UserProfileResponse>(HttpMethod.POST, url, update, accessToken)
}

export const queryPreferences = async (network: Network, address: string): Promise<ApiResponse<UserPreferencesResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/user/preferences/${address}`
  return await sendAlertsApiRequest<UserPreferencesResponse>(HttpMethod.GET, url)
}

export const updatePreferences = async (network: Network, accessToken: string, update: Preference[]): Promise<ApiResponse<UserPreferencesResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/user/preferences/update`
  return await sendAlertsApiRequest<UserPreferencesResponse>(HttpMethod.POST, url, update, accessToken)
}

export const getToggleStatus = (preferences: Preference[] | null, category: Category, channel: Channel): boolean => {
  if (!preferences || preferences.length === 0) return true
  const pref = preferences.find(p => p.category === category)
  if (!pref) return true
  return !pref.disabledChannels?.includes(channel)
}

export const optimisticPreferencesUpdate = (preferences: Preference[] | null, updates: Preference[]): Preference[] => {
  if (!preferences) {
    return updates.map(u => ({ category: u.category, disabledChannels: u.disabledChannels }))
  }
  updates.forEach(u => {
    const pref = preferences.find(p => p.category === u.category)
    if (!pref) {
      preferences.push({ category: u.category, disabledChannels: u.disabledChannels })
    }
    else {
      pref.disabledChannels = u.disabledChannels
    }
  })
  return [...preferences]
}