import { getConnectors } from '@wagmi/core'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

import { StorageKey } from 'js/constants/app'
import { wagmiConfig } from 'js/utils'
import { WalletType } from 'types/wallet'

const useCurrentWalletConnector = () => {
  const { connector } = useAccount()

  const currentWalletConnector = useMemo(() => {
    const connectors = getConnectors(wagmiConfig)
    const walletProviderAgent = localStorage.getItem(StorageKey.ConnectedWallet) ?? ''
    const currentWalletId = localStorage.getItem(StorageKey.ConnectedWeb3Wallet) ?? ''
    if (!window.ethereum && connector && connector.id !== WalletType.WalletConnect) {
      return connectors.find(c => c.id === WalletType.WalletConnect)
    }
    const currentConnector = connectors.find((c) => {
      return c.id === currentWalletId || (c.name.toLowerCase() === walletProviderAgent && !!c.icon)
    })

    return currentConnector ?? connector
  }, [connector])

  return currentWalletConnector
}

export default useCurrentWalletConnector
