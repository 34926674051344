import { Grid, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import TopCardLabel from "js/components/LiquidityPool/LiquidityPool/components/TopCardLabel"
import { useDimensions } from "js/hooks"
import { muiBreakpoints } from "js/theme"
import { StyleUtils, useCommonStyles } from "js/utils"

const PoolsFallbackMain: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  const { isTablet, isMobile } = React.useMemo(() => {
    return {
      isTablet: dimensions.windowSize.width >= muiBreakpoints.xs && dimensions.windowSize.width < muiBreakpoints.md,
      isMobile: dimensions.windowSize.width < muiBreakpoints.xs,
    }
  }, [dimensions.windowSize.width])

  const gridSpacing = React.useMemo(() => {
    if (isMobile || isTablet) return 0
    return 4
  }, [isMobile, isTablet])

  return (
    <Grid container className={classes.bigGrid} spacing={gridSpacing}>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <TopCardLabel>
          <div className={clsx(commonClasses.flexColumn, classes.loadingSkeletonWrapper, 'liquidityPool')}>
            <LoadingSkeleton height={isMobile ? 16 : 20} />
            <LoadingSkeleton height={isMobile ? 16 : 20} />
          </div>
        </TopCardLabel>
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <TopCardLabel>
          <div className={clsx(commonClasses.flexColumn, classes.loadingSkeletonWrapper, 'totalLiquidity')}>
            <LoadingSkeleton height={isMobile ? 12 : 20} />
            <LoadingSkeleton />
          </div>
        </TopCardLabel>
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <TopCardLabel>
          <div className={clsx(commonClasses.flexColumn, classes.loadingSkeletonWrapper, 'totalWeeklyRewards')}>
            <LoadingSkeleton height={isMobile ? 12 : 20} />
            <LoadingSkeleton />
          </div>
        </TopCardLabel>
      </Grid>
      <Grid item xs={12} className={classes.bottomGridItem}>
        <div className={classes.containerBox} style={{ minHeight: `${isMobile ? dimensions.otherPagesHeight.mobile - 100 : dimensions.otherPagesHeight.desktop - 196}px` }}>
          <LoadingSkeleton width={180} paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
        </div>
      </Grid>
    </Grid>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  bigGrid: {
    margin: theme.spacing(2, 'auto'),
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(0, 2),
    width: '100%',
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(6),
    },
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(1),
      },
    },
  },
  containerBox: {
    ...StyleUtils.scrollBar(theme),
    backgroundColor: theme.palette.background.primary,
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2.75, 2, 2.5),
    },
  },
  loadingSkeletonWrapper: {
    gap: '8px',
    minHeight: '93px',
    '&.liquidityPool': {
      [theme.breakpoints.down('lg')]: {
        minHeight: '28px'
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '52px',
      },
    },
    '&.totalLiquidity': {
      [theme.breakpoints.down('lg')]: {
        minHeight: '46px'
      },
    },
    '&.totalWeeklyRewards': {
      [theme.breakpoints.down('lg')]: {
        minHeight: '63px'
      },
    },
  },
}))

export default PoolsFallbackMain