import { Card, CardProps, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'


interface Props extends CardProps {
  heading?: string
}

const WalletCard: React.FC<Props> = (props: Props) => {
  const { className, heading, children, ...rest } = props
  const classes = useStyles()

  return (
    <Card elevation={0} {...rest} className={clsx(classes.root, className)}>
      {typeof heading === 'string' && (
        <Typography className={classes.title} variant="h5" color="textPrimary">
          {heading}
        </Typography>
      )}
      {children}
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'unset',
    borderRadius: 4,
    padding: theme.spacing(3, 4, 4),
    border: 'none',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3, 3, 4),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 3),
    },
    '@media (max-width: 360px)': {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontFamily: 'Graphik, sans-serif',
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
  },
}))

export default WalletCard
