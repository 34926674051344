import { LinkProps } from '@material-ui/core'
import { CarbonCustomError } from 'carbon-js-sdk/lib/util/tx'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import React from 'react'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'
import { ReactComponent as TradeHistoryIcon } from 'assets/TradeHistory.svg'
import { ReactComponent as DelegateIcon } from 'assets/moduleIcons/delegate.svg'
import { ReactComponent as CGLPIcon } from 'assets/notification/feature/CGLPIcon.svg'
import { ReactComponent as CampaignsIcon } from 'assets/notification/feature/CampaignsIcon.svg'
import { ReactComponent as NitronIcon } from 'assets/notification/feature/NitronIcon.svg'
import { ReactComponent as NitronLiquidationsIcon } from 'assets/notification/feature/NitronLiquidationsIcon.svg'
import { ReactComponent as PoolsIcon } from 'assets/notification/feature/PoolsIcon.svg'
import { ReactComponent as ProfileIcon } from 'assets/notification/feature/ProfileIcon.svg'
import { ReactComponent as ReferralIcon } from 'assets/notification/feature/ReferralIcon.svg'
import { ReactComponent as StakingIcon } from 'assets/notification/feature/StakingIcon.svg'
import { ReactComponent as TradeIcon } from 'assets/notification/feature/TradeIcon.svg'
import { ReactComponent as VaultsIcon } from 'assets/notification/feature/VaultsIcon.svg'
import { ReactComponent as WalletIcon } from 'assets/notification/feature/WalletIcon.svg'
import { ReactComponent as OrderCancelledIcon } from 'assets/notification/feature/order/OrderCancelledIcon.svg'
import { ReactComponent as OrderFilledIcon } from 'assets/notification/feature/order/OrderFilledIcon.svg'
import { ReactComponent as OrderPartiallyFilledIcon } from 'assets/notification/feature/order/OrderPartiallyFilledIcon.svg'
import { ReactComponent as OrderPlacedIcon } from 'assets/notification/feature/order/OrderPlacedIcon.svg'
import { ReactComponent as OrderSystemCancelledIcon } from 'assets/notification/feature/order/OrderSystemCancelledIcon.svg'
import { ReactComponent as AlertIcon } from 'assets/notification/type/AlertIcon.svg'
import { ReactComponent as CautionIcon } from 'assets/notification/type/CautionIcon.svg'
import { ReactComponent as InfoIcon } from 'assets/notification/type/InfoIcon.svg'
import { ReactComponent as TickIcon } from 'assets/notification/type/TickIcon.svg'


import Paths from './paths'


export const DEFAULT_NOTIFICATION_DURATION_SECONDS = 10
export const DEFAULT_ERROR_NOTIFICATION_DURATION_SECONDS = 15
export const DEFAULT_SUCCESS_NOTIFICATION_DURATION_SECONDS = 5

export const MAX_ORDERS_TO_SHOW = 3

export const TX_ERROR_MESSAGE = 'Seems like we can\'t carry out this action. Please refresh and try again.'
export const TX_ERROR_TITLE = 'Transaction Error'

export const DISMISS_TOAST_KEY = 'dismissToast'
export const TOAST_DELAY = 250
export const MAX_TOAST = 5

export interface ActionProps {
  label: string
  cta: () => void
  endIcon?: SVGComponent
  startIcon?: SVGComponent
}

export interface BtnLinkProps extends LinkProps {
  label?: string
  path?: string
  href?: string
  endIcon?: SVGComponent
  startIcon?: SVGComponent
  svgClass?: string
}

export interface ToastOptions {
  hide?: boolean,
  custom?: CustomToast
}

export interface CustomToast {
  title?: string,
  featureType?: FeatureType,
  message?: React.ReactNode
  body?: React.ReactNode
  link?: BtnLinkProps | undefined
}


export enum FeatureType {
  TRADE = 'trade',
  STAKING = 'staking',
  WALLET = 'wallet',
  CAMPAIGNS = 'campaigns',
  REFERRAL = 'referral',
  NITRON = 'nitron',
  NITRON_LIQUIDATIONS = 'nitron_liquidations',
  CGLP = 'cglp',
  ADDRESS_BOOK = 'address_book',
  PROFILE = 'profile',
  POOLS = 'pools',
  VAULTS = 'vaults',
  ORDER_PLACED = 'order_placed',
  ORDER_FILLED = 'order_filled',
  ORDER_PARTIALLY_FILLED = 'order_partially_filled',
  ORDER_CANCELLED = 'order_cancelled',
  ORDER_SYSTEM_CANCELLED = 'order_system_cancelled',
  SUBACCOUNTS = 'subaccounts',
  OVERVIEW_REWARDS = 'overview_rewards'
}
export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  LOADING = 'loading',
}

export const FeatureIcons: { [index in FeatureType]?: SVGComponent } = {
  trade: TradeIcon,
  staking: StakingIcon,
  wallet: WalletIcon,
  campaigns: CampaignsIcon,
  referral: ReferralIcon,
  nitron: NitronIcon,
  nitron_liquidations: NitronLiquidationsIcon,
  cglp: CGLPIcon,
  address_book: StakingIcon,
  profile: ProfileIcon,
  pools: PoolsIcon,
  vaults: VaultsIcon,
  order_placed: OrderPlacedIcon,
  order_filled: OrderFilledIcon,
  order_partially_filled: OrderPartiallyFilledIcon,
  order_cancelled: OrderCancelledIcon,
  order_system_cancelled: OrderSystemCancelledIcon,
  subaccounts: DelegateIcon,
}

export const NotificationIcons: { [index in NotificationType]: SVGComponent } = {
  error: AlertIcon,
  success: TickIcon,
  warning: CautionIcon,
  info: InfoIcon,
  loading: InfoIcon,
}

type ModuleCustomTransactionErrorMessages = SimpleMap<CustomErrorMessage>

type CustomErrorMessage = CustomMessage | BuildCustomMessage

export const PANIC_ERROR_CODE = 111222
export const PANIC_CODESPACE = 'undefined'

export type BuildCustomMessage = (error: CarbonCustomError) => CustomMessage | undefined

export interface CustomMessage {
  title?: string,
  message: string,
  link?: BtnLinkProps
}

enum SdkErrorCodes {
  ErrInsufficientFunds = 13,
  ErrInvalidRequest = 18,
}

enum PerpPoolErrorCodes {
  ErrExistingPositionsOpenInMarketType = 6,
  ErrExistingOrdersOpenInMarketType = 7,
  ErrTokenBalanceInsufficientType = 9,
  ErrMarketNotInPoolType = 10,
}

enum MarketErrorCodes {
  ErrFuturesMarketExpired = 17,
}

enum FeeErrorCodes {
  ErrInsufficientTxFees = 2,
}

export enum CdpErrorCodes {
  ErrAmountOverMaximumType = 4,
}

const getSdkInvalidRequestCustomMessage = (error: CarbonCustomError) => {
  const isExceedLeverageMessage = error.message.includes('position size will become too large to maintain current market leverage, reduce leverage to make order')
  if (isExceedLeverageMessage) return { message: 'Your new order will exceed the maximum position size allowed. Please close any other open orders.' }
}

const SdkErrorMessages: { [value in SdkErrorCodes]: CustomErrorMessage } = {
  [SdkErrorCodes.ErrInsufficientFunds]: { message: 'Insufficient Funds in Account. Deposit funds into your account to continue.', link: { label: 'Deposit Now', path: Paths.Account.Balance, endIcon: ExternalLinkIcon } },
  [SdkErrorCodes.ErrInvalidRequest]: (error: CarbonCustomError) => getSdkInvalidRequestCustomMessage(error)
}

const MarketErrorMessages: { [value in MarketErrorCodes]: CustomErrorMessage } = {
  [MarketErrorCodes.ErrFuturesMarketExpired]: { message: 'This market has expired. Please trade on another market.' },
}
const PerpsPoolErrorMessages: { [value in PerpPoolErrorCodes]: CustomErrorMessage } = {
  [PerpPoolErrorCodes.ErrExistingPositionsOpenInMarketType]: { message: 'You currently have open positions on this market. Please close your positions before adding liquidity.' },
  [PerpPoolErrorCodes.ErrExistingOrdersOpenInMarketType]: { message: 'You currently have open orders on this market. Please close your order before adding liquidity.' },
  [PerpPoolErrorCodes.ErrTokenBalanceInsufficientType]: { message: 'You do not have enough funds to add liquidity to this pool. Please deposit funds into Demex.', link: { label: 'Deposit Now', path: Paths.Account.Balance, endIcon: ExternalLinkIcon } },
  [PerpPoolErrorCodes.ErrMarketNotInPoolType]: { message: 'This token is not supported yet. Import any token permissionlessly.', link: { label: 'Import Token', path: Paths.Markets.Import, endIcon: ExternalLinkIcon } },
}

const FeeErrorMessages: { [value in FeeErrorCodes]: CustomErrorMessage } = {
  [FeeErrorCodes.ErrInsufficientTxFees]: { message: 'Insufficient Funds in Account. SWTH is required in your wallet for gas fees. Buy SWTH to continue.', link: { label: 'Buy SWTH', path: Paths.Trade.SwthUsd, endIcon: ExternalLinkIcon } },
}

export const CdpErrorMessages: { [value in CdpErrorCodes]: CustomErrorMessage } = {
  [CdpErrorCodes.ErrAmountOverMaximumType]: { message: 'You are unable to lend more than the available amount of tokens that can be lent to Nitron based on the supply cap of this asset. Please lend an amount lower than {availableToLend}'},
}

export const CustomErrorMessages: SimpleMap<ModuleCustomTransactionErrorMessages> = {
  // key must follow carbon module name
  perpspool: PerpsPoolErrorMessages,
  sdk: SdkErrorMessages,
  market: MarketErrorMessages,
  carbonfee: FeeErrorMessages,
  cdp: CdpErrorMessages,
}

export enum OrderCancelReasonCode {
  Unknown = 0,
  OrderFailedValidation = 1,
  CannotFulfillPostOnly = 2,
  CannotFulfillReduceOnly = 3,
  InsufficientMargin = 4,
  ExecutionPriceOutsideTradingBandwidth = 5,
  MarketClosed = 6,
  MadePositionPoolNegative = 7,
  OrderExecutionPriceTooPoor = 8,
  TIFExpired = 10,
  PositionBeingLiquidated = 11,
  Derisk = 12,
  NoMarkPrice = 14,
  MaxLeverageExceeded = 15,
  MatchedOwnOrder = 16,
  AMMUpdated = 17,
  UserInitiated = 18,

}
export const DynamicCustomMessageCancelReasonCode = [OrderCancelReasonCode.InsufficientMargin, OrderCancelReasonCode.TIFExpired]

export const OrderCancelCustomMessages: { [value in OrderCancelReasonCode]?: CustomMessage } = {
  [OrderCancelReasonCode.Unknown]: { title: 'Order Rejected', message: 'Your order was rejected. Please try again in awhile.' },
  [OrderCancelReasonCode.OrderFailedValidation]: { title: 'Order Rejected', message: 'Your order was invalid. Please check your order details and try again.' },
  [OrderCancelReasonCode.CannotFulfillPostOnly]: { title: 'Order Rejected', message: 'Your post-only order could not be executed as a maker order.' },
  [OrderCancelReasonCode.CannotFulfillReduceOnly]: { title: 'Order Cancelled', message: 'Your reduce-only order cannot be executed without increasing your position size.' },
  [OrderCancelReasonCode.ExecutionPriceOutsideTradingBandwidth]: { title: 'Order Rejected', message: 'Your order has matched at a price outside of the designated trading bandwidth for this market.', link: { label: 'Learn More', target: '_blank', href: 'https://guide.dem.exchange/trade/market-specifications#trading-bandwidth', endIcon: ExternalLinkIcon } },
  [OrderCancelReasonCode.MarketClosed]: { title: 'Order Rejected', message: 'The market is closed.' },
  [OrderCancelReasonCode.MadePositionPoolNegative]: { title: 'Order Rejected', message: 'Insufficient liquidity in system to place this order. Please try again in awhile.' },
  [OrderCancelReasonCode.OrderExecutionPriceTooPoor]: { title: 'Order Rejected', message: 'Your order price was lower than your position’s bankruptcy price.' },
  [OrderCancelReasonCode.PositionBeingLiquidated]: { title: 'Order Rejected', message: 'Your position is being liquidated and new orders cannot be placed.' },
  [OrderCancelReasonCode.Derisk]: { title: 'Order Cancelled', message: 'Your position is about to be liquidated and all risk-increasing orders are being cancelled.' },
  [OrderCancelReasonCode.NoMarkPrice]: { title: 'Order Rejected', message: 'Unable to obtain the mark price for this market. Please try again in awhile.' },
  [OrderCancelReasonCode.MaxLeverageExceeded]: { title: 'Order Cancelled', message: 'Order exceeds the initial margin requirements for this market. Please reduce your leverage and try again.', link: { label: 'Learn More', target: '_blank', href: 'https://guide.dem.exchange/trade/market-specifications#initial-margin', endIcon: ExternalLinkIcon } },
  [OrderCancelReasonCode.MatchedOwnOrder]: { title: 'Order Cancelled', message: 'Order was cancelled as it matches a newer order that you placed.' },
  [OrderCancelReasonCode.UserInitiated]: { title: 'Order Cancelled', message: 'You have successfully cancelled this order.' },
}

export const ViewOrderHistoryLink: BtnLinkProps = {
  label: 'View Order History',
  endIcon: TradeHistoryIcon,
  path: Paths.Account.Order.v2,
}
