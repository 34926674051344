import { makeStyles, Theme } from '@material-ui/core'

import { StyleUtils } from 'js/utils/styles'

export const formStyles = makeStyles((theme: Theme) => ({
  // templates
  formBox: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '4px',
    padding: theme.spacing(3, 4, 4),
    [theme.breakpoints.up('sm')]: {
      width: '32rem',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.primary,
      width: '100%',
      padding: theme.spacing(3, 2, 2.75),
    },
  },
  header: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  svgSpan: {
    cursor: 'pointer',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(-0.5),
    '& path': {
      stroke: theme.palette.text.secondary,
    },
    '&:hover': {
      background: theme.palette.background.active,
      '& path': {
        stroke: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '1rem',
      maxWidth: '1rem',
    },
  },
  title: {
    ...theme.typography.h4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
    },
  },
  tooltip: {
    cursor: 'help',
    borderBottom: '1px dashed',
  },
  detailsColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '1.5rem',
  },
  detailsRow: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.hidden': {
      display: 'none',
    },
  },
  rightAlign: {
    textAlign: 'right',
  },
  detailsHeader: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  divider: {
    color: theme.palette.divider,
    width: '100%',
  },
  actionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  root: {
    backgroundColor: theme.palette.background.base,
    padding: '1rem 2rem 2rem',
    width: '100%',
    minHeight: 'calc(100vh - 48px - 28px)', // height minus top bar and bottom bar
  },
  page: {
    width: '100%',
    backgroundColor: theme.palette.background.base,
    padding: '2rem',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  background: {
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  form: {
    width: '32rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 'calc(100vh - 48px - 28px - 4rem)', // - top bar - bottom bar - padding
    paddingBottom: '3.25rem',
    [theme.breakpoints.only('md')]: {
      paddingBottom: '4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4rem',
      minHeight: 0,
      width: '100%',
    },
    borderRadius: '4px',
  },
  backBtn: {
    display: 'flex',
    width: '100%',
    margin: '2.375rem 0.5625rem 2.375rem 0',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.75),
      marginLeft: theme.spacing(2),
      marginBottom: 0,
    },
  },
  backText: {
    ...theme.typography.h6,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body3,
      fontWeight: 700,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  assetClass: {
    marginRight: '6px',
    height: '1.5rem',
    width: '1.5rem',
  },
  selectText: {
    ...theme.typography.body3,
    textAlign: 'left',
    '&.isOpen': {
      color: 'url(#demexLinearGradient)',
    },
  },
  amountInputBox: {
    marginTop: '6px',
  },
  amountInput: {
    '& input': {
      ...theme.typography.body3,
    },
  },
  formAmountInputBox: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(0.75),
  },
  arrowLeft: {
    minHeight: '12px',
    minWidth: '14px',
    margin: '2.375rem 0.5625rem 2.375rem 0',
  },
  actionBtn: {
    width: '6.5rem',
  },
  successBtn: {
    width: '9rem',
  },
  statefulBtn: {
    width: 'unset !important',
    minWidth: '6.5rem',
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
  },
  arrowRightIcon: {
    margin: '0 0.5rem',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  healthFactor: {
    display: 'flex',
    alignItems: 'center',
    '&.red': { // < 1
      color: theme.palette.error.main,
    },
    '&.yellow': { // 1 - 3
      color: theme.palette.warning.main,
    },
    '&.green': { // > 3
      color: theme.palette.success.main,
    },
  },
  linkText: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  tooltipIcon: {
    width: '1.25rem',
    height: '1.25rem',
    maxHeight: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '1rem',
      height: '1rem',
      marginLeft: 0,
    },
  },
  // rewards tag
  rewardTag: {
    borderRadius: '4px',
    padding: '4px 8px',
    background: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
    '&:hover': {
      background: theme.palette.background.active,
      cursor: 'pointer',
    },
  },
  rewardLabel: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '12px',
    ...theme.typography.body4,
    fontSize: '10px',
    borderRadius: '3px',
  },
  rewardAssetIcon: {
    margin: 0,
    marginLeft: theme.spacing(0.75),
    width: '14px',
  },
  popoverRewardAssetIcon: {
    margin: 0,
    marginRight: theme.spacing(0.75),
    width: '14px',
  },
  popover: {
    ...theme.typography.body4,
    maxWidth: '10rem',
  },
  popoverText: {
    ...theme.typography.body4,
  },
  innerPopoverText: {
    ...theme.typography.body4,
    lineHeight: '13px',
    marginBottom: theme.spacing(0.5),
  },
  rewardSymbolAsset: {
    display: 'flex',
    alignItems: 'center',
  },
  rewardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popoverPaper: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: StyleUtils.dropShadow(theme),
  },
  usdValue: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  marginTop4: {
    marginTop: theme.spacing(4),
  },
  valuesWrapper: {
    flexWrap: 'wrap',
  }
}))

export const tableStyles = makeStyles((theme: Theme) => ({
  detailsIcon: {
    marginLeft: theme.spacing(0.5),
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
    border: 'none !important',
    '&:hover path': {
      stroke: theme.palette.type === 'light' ? 'url(#demexLinearGradient)' : theme.palette.text.animated,
      ...StyleUtils.hoverAnimationDuration(),
    },
  },
  actionButton: {
    minHeight: '1.75rem',
    minWidth: '5rem',
    marginRight: theme.spacing(1.5),
    '& > span': {
      ...theme.typography.body4,
      fontSize: '12px',
      fontWeight: 700,
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      opacity: '0.5',
      cursor: 'not-allowed',
    },
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      flex: '1 1 3.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      '&.threeButton': {
        flex: 'unset',
        minWidth: 'unset',
        maxWidth: '33%',
        width: '100%',
      },
    },
  },
  detailsSection: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(1.25),
  },
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 3),
    padding: theme.spacing(1.5, 0),
    '&.last': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1.25, 0),
      display: 'block',
    },
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  }
}))
