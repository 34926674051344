import BigNumber from 'bignumber.js'
import { Hydrogen, OrderModule, TypeUtils, WSModels } from 'carbon-js-sdk'
import { InternalTransfer } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bank/query'
import { List, RecordOf } from 'immutable'

import { TransferType } from 'js/constants/TransferOptions'
import { DateRange } from 'js/constants/date'

export interface HistoryStateProps {
  readonly orderHistory: List<ModifiedHistoryOrder>
  readonly openOrders: List<ModifiedHistoryOrder>
  readonly trades: List<WSModels.AccountTrade>
  readonly openPositions: List<WSModels.Position>
  readonly closedPositions: List<WSModels.Position>
  readonly editedOrders: { [orderId: string]: Partial<OrderModule.EditOrderParams> }
  readonly crosschainTransfers: Hydrogen.CrossChainTransfer[]
  readonly pendingCrosschainTransfers: PendingCrosschainTransfers
  readonly internalTransfers: InternalTransfer[]
  readonly closePositionPriceInputs: TypeUtils.SimpleMap<string>
  readonly balanceGraphData: OverviewGraphCoordinate[]
  readonly pnlGraphData: OverviewPnlGraphCoordinate[]
  readonly pnl: BigNumber
  readonly fundingData: FundingData[]
  readonly fundingPaidBreakdownGraphData: FundingBreakdownGraphCoordinate[]
  readonly fundingReceivedBreakdownGraphData: FundingBreakdownGraphCoordinate[]
  readonly isCurrentMarketSelected: boolean
  readonly isUSD: boolean
  readonly isSyncComplete: boolean
  readonly isUserOverviewMode: boolean
}

export interface ModifiedHistoryOrder extends WSModels.HistoryOrder {
  allocated_margin_amount?: string;
  allocated_margin_denom?: string;
  type?: string
}

export interface GraphDataRequest {
  days: number
}
export interface FundingRequest {
  dateRange: DateRange
}

export interface OverviewGraphCoordinate {
  datetime: string
  timestamp: string
  value: number
}

export interface OverviewPnlGraphCoordinate extends OverviewGraphCoordinate {
  cumulativePnl: number
  pnlPercentageChange?: number
}

export interface FundingData {
  time: string
  market: string
  amount: BigNumber
}

export interface FundingBreakdownGraphCoordinate {
  market: string
  amount: BigNumber 
}

export type HistoryState = RecordOf<HistoryStateProps>

export type CustomTransferType = TransferType | "all"

export interface TransferStatus {
  type: CustomTransferType
  denom: string
}

export interface PendingCrosschainTransfers {
  transfers: TransferStatus[]
  type?: CustomTransferType
}

export const HistoryActionTypes = {
  // All Orders
  SYNC_ORDERS: '@history/SYNC_ORDERS',
  SET_SYNC_STATUS_TRUE: '@history/SET_SYNC_STATUS_TRUE',
  SET_SYNC_STATUS_FALSE: '@history/SET_SYNC_STATUS_FALSE',
  CLEAR_ORDERS_AND_TRADES: '@history/CLEAR_ORDERS_AND_TRADES',

  // Order history
  ADD_ORDER_HISTORY: '@history/ADD_ORDER_HISTORY',
  SET_ORDER_HISTORY: '@history/SET_ORDER_HISTORY',

  // Open orders
  SET_OPEN_ORDERS: '@history/SET_OPEN_ORDERS',
  CLEAR_OPEN_ORDERS: '@history/CLEAR_OPEN_ORDERS',

  // Edit order
  EDIT_ORDER: '@history/EDIT_ORDER',
  EDIT_ORDER_RESULT: '@history/EDIT_ORDER_RESULT',

  // Trades
  SET_ACCOUNT_TRADES: '@history/SET_ACCOUNT_TRADES',
  ADD_ACCOUNT_TRADES: '@history/ADD_ACCOUNT_TRADES',
  CLEAR_ACCOUNT_TRADES: '@history/CLEAR_ACCOUNT_TRADES',

  // Positions
  SET_OPEN_POSITIONS: '@history/SET_OPEN_POSITIONS',
  RELOAD_OPEN_POSITIONS: '@history/RELOAD_OPEN_POSITIONS',
  SET_CLOSED_POSITIONS: '@history/SET_CLOSED_POSITIONS',
  ADD_POSITIONS: '@history/ADD_POSITIONS',
  CLEAR_POSITIONS: '@history/CLEAR_POSITIONS',
  SET_CLOSE_POSITION_PRICE_INPUTS: '@history/SET_CLOSE_POSITION_PRICE_INPUTS',

  // Transfers
  GET_INTERNAL_TRANSFERS: '@history/GET_INTERNAL_TRANSFERS',
  SET_INTERNAL_TRANSFERS: '@history/SET_INTERNAL_TRANSFERS',

  // Crosschain transfers
  SET_CROSSCHAIN_TRANSFERS: '@history/SET_CROSSCHAIN_TRANSFERS',
  SET_PENDING_CROSSCHAIN_TRANSFERS: '@history/SET_PENDING_CROSSCHAIN_TRANSFERS',

  // Balance/Pnl history
  GET_ACCOUNT_BALANCE_GRAPH_DATA: '@history/GET_ACCOUNT_BALANCE_GRAPH_DATA',
  GET_ACCOUNT_PNL_GRAPH_DATA: '@history/GET_ACCOUNT_PNL_GRAPH_DATA',
  GET_ACCOUNT_PNL: '@history/GET_ACCOUNT_PNL',
  GET_ACCOUNT_OVERVIEW_DATA: '@history/GET_ACCOUNT_OVERVIEW_DATA',
  SET_ACCOUNT_BALANCE_GRAPH_DATA: '@history/SET_ACCOUNT_BALANCE_GRAPH_DATA',
  SET_ACCOUNT_PNL_GRAPH_DATA: '@history/SET_ACCOUNT_PNL_GRAPH_DATA',
  SET_ACCOUNT_PNL: '@history/SET_ACCOUNT_PNL',

  // Funding history
  GET_ACCOUNT_FUNDINGS: '@history/GET_ACCOUNT_FUNDINGS',
  GET_ACCOUNT_FUNDING_HISTORY_GRAPH_DATA: '@history/GET_ACCOUNT_FUNDING_HISTORY_GRAPH_DATA',
  GET_ACCOUNT_FUNDING_PAID_BREAKDOWN_GRAPH_DATA: '@history/GET_ACCOUNT_FUNDING_PAID_BREAKDOWN_GRAPH_DATA',
  GET_ACCOUNT_FUNDING_RECEIVED_BREAKDOWN_GRAPH_DATA: '@history/GET_ACCOUNT_FUNDING_RECEIVED_BREAKDOWN_GRAPH_DATA',
  GET_ACCOUNT_TOTAL_FUNDING: '@history/GET_ACCOUNT_TOTAL_FUNDING',
  SET_ACCOUNT_FUNDINGS: '@history/SET_ACCOUNT_FUNDINGS',
  SET_ACCOUNT_FUNDING_PAID_BREAKDOWN_GRAPH_DATA: '@history/SET_ACCOUNT_FUNDING_PAID_BREAKDOWN_GRAPH_DATA',
  SET_ACCOUNT_FUNDING_RECEIVED_BREAKDOWN_GRAPH_DATA: '@history/SET_ACCOUNT_FUNDING_RECEIVED_BREAKDOWN_GRAPH_DATA',
  SET_IS_CURRENT_MARKET_SELECTED: '@history/SET_IS_CURRENT_MARKET_SELECTED',
  SET_GENERIC_MESSAGE: '@history/SET_GENERIC_MESSAGE',
  CLEAR_GENERIC_MESSAGE: '@history/CLEAR_GENERIC_MESSAGE',
  SET_IS_USER_OVERVIEW_MODE: '@history/SET_IS_USER_OVERVIEW_MODE',
}

export const HistoryTasks = {
  FetchAccountBalanceGraphData: 'fetch-account-balance-graph-data',
  FetchAccountPnlGraphData: 'fetch-account-pnl-graph-data',
  FetchAccountPnl: 'fetch-account-pnl',
  FetchAccountFunding: 'fetch-account-funding',
  FetchAccountFundingHistoryGraphData: 'fetch-account-funding-history-graph-data',
  FetchAccountFundingBreakdownGraphData: 'fetch-account-funding-breakdown-graph-data',
}
