import { CarbonEvmChainIDs, Network, NetworkConfigs } from "carbon-js-sdk/lib/constant" // eslint-disable-line import/no-unresolved
import { parseChainId } from 'carbon-js-sdk/lib/util/ethermint'

export const CARBON_EVM_MAINNET = {
  chainId: Number(parseChainId(CarbonEvmChainIDs[Network.MainNet])),
  chainName: 'Carbon EVM',
  rpcUrls: [`${NetworkConfigs[Network.MainNet].evmJsonRpcUrl}`],
}

export const CARBON_EVM_TESTNET = {
  chainId: Number(parseChainId(CarbonEvmChainIDs[Network.TestNet])),
  chainName: 'Carbon EVM Testnet',
  rpcUrls: [`${NetworkConfigs[Network.TestNet].evmJsonRpcUrl}`],
}

export const CARBON_EVM_DEVNET = {
  chainId: Number(parseChainId(CarbonEvmChainIDs[Network.DevNet])),
  chainName: 'Carbon EVM Devnet',
  rpcUrls: [`${NetworkConfigs[Network.DevNet].evmJsonRpcUrl}`],
}

export const CARBON_EVM_LOCALHOST = {
  chainId: Number(parseChainId(CarbonEvmChainIDs[Network.LocalHost])),
  chainName: 'Carbon EVM Localhost',
  rpcUrls: [`${NetworkConfigs[Network.LocalHost].evmJsonRpcUrl}`],
}
