import {
  FormControl,
  InputAdornment,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  OutlinedInput as MuiOutlinedInput,
  OutlinedInputClassKey,
  OutlinedTextFieldProps,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { useCommonStyles } from 'js/utils'

import CommonTooltip from '../Exchange/Common/Tooltip'

export type OutlinedInputClasses = { [key in OutlinedInputClassKey]: string }

export interface ClassesMap {
  outlined?: Partial<OutlinedInputClasses>
  label?: string
  helperText?: string
  startAdornment?: string
  endAdornment?: string
}

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  variant?: string
  prefix?: any
  suffix?: any
  errorText?: string
  warningText?: string
  inputClasses?: ClassesMap
  hint?: React.ReactNode
  assistiveMessage?: string
  step?: number | string
  shrink?: boolean
  labelTooltip?: React.ReactNode
}

const preventOnScrollValueChange: React.WheelEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement
  target.blur()
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    classes = {}, className,
    label, fullWidth, type, disabled,
    prefix, suffix, errorText, inputClasses = {}, hint, assistiveMessage, warningText,
    step, autoComplete = 'off', inputProps, shrink = true, labelTooltip, ...rest
  } = props
  const customClasses = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <FormControl
      className={clsx(customClasses.root, classes.root, className, { warning: warningText })}
      error={!!errorText}
      fullWidth={fullWidth}
    >
      <div className={clsx(commonClasses.justifyContentSpaceBetween, commonClasses.alignItemsStart, commonClasses.fullWidth)}>
        {label && (
          <InputLabel
            className={clsx(customClasses.inputLabel, inputClasses.label, { hasTooltip: labelTooltip })}
            disableAnimation
            shrink={shrink}
          >
            <CommonTooltip
              isTextTooltip
              title={labelTooltip ?? ""}
            >
              <span>{label}</span>
            </CommonTooltip>
          </InputLabel>
        )}
        {hint}
      </div>

      <OutlinedInput
        {...rest}
        type={type}
        onWheel={preventOnScrollValueChange}
        autoComplete={autoComplete}
        disabled={disabled}
        classes={{
          ...inputClasses.outlined,
          // TODO: Remove once V2 Theme is fully implemented
          root: clsx(inputClasses.outlined?.root ?? '', { warning: warningText, error: errorText }),
          notchedOutline: clsx(inputClasses.outlined?.notchedOutline ?? '', { disabled }),
          input: clsx(inputClasses.outlined?.input ?? ''),
          adornedEnd: clsx(inputClasses.outlined?.adornedEnd ?? ''),
        }}
        startAdornment={prefix && (
          <InputAdornment
            position="start"
            disableTypography
            className={inputClasses.startAdornment}>
            {prefix}
          </InputAdornment>
        )}
        endAdornment={suffix && (
          <InputAdornment
            position="end"
            disableTypography
            className={clsx(customClasses.inputLabel, inputClasses.label, inputClasses.endAdornment)}
          >
            {suffix}
          </InputAdornment>
        )}
        inputProps={{
          step,
          min: type === 'number' ? 0 : '',
          inputMode: type === 'number' ? 'decimal' : 'text',
          ...inputProps,
        }}
      />
      {(errorText || assistiveMessage || warningText) && (
        <div className={commonClasses.justifyContentSpaceBetween}>
          {errorText && (
            <FormHelperText className={inputClasses.helperText}>
              {errorText || ' '}
            </FormHelperText>
          )}
          {warningText && (
            <FormHelperText className={inputClasses.helperText}>
              {warningText || ' '}
            </FormHelperText>
          )}
          <AssistiveMessage className={inputClasses.helperText}>
            {assistiveMessage || ' '}
          </AssistiveMessage>
        </div>
      )}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.warning label, &.warning p': {
      color: theme.palette.warning.main,
    },
  },
  inputLabel: {
    '&.hasTooltip': {
      marginBottom: theme.spacing(1.25)
    },
  },
}))

const InputLabel = withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.label1,
    color: theme.palette.text.secondary,
    fontSize: '0.8125rem',
    lineHeight: '0.75rem',
    letterSpacing: '0.01875rem',
    marginBottom: '0.4rem',
    transform: 'scale(1)',
    width: '100%',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '&.Mui-error': {
      color: theme.palette.text.secondary,
    },
  },
  formControl: {
    position: 'unset',
  },
}))(MuiInputLabel)

const OutlinedInput = withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body3,
    height: theme.spacing(6),
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '4px',
    padding: 0,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.text.disabled,
    },
    '&.error $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&.warning $notchedOutline': {
      borderColor: theme.palette.warning.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    '&$focused.error $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&$focused.warning $notchedOutline': {
      borderColor: theme.palette.warning.main,
    },
    '&$disabled': {
      backgroundColor: theme.palette.action?.disabledBackground,
    },
    [theme.breakpoints.only('xs')]: {
      height: theme.spacing(5.25),
    },
  },
  adornedStart: {
    paddingLeft: theme.spacing(1.25),
  },
  adornedEnd: {
    paddingRight: theme.spacing(1.25),
  },
  input: {
    ...theme.typography.body3,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1.25, 0, 2),
    '&::placeholder': {
      color: theme.palette.text.disabled,
    },
  },
  inputAdornedStart: {
    paddingLeft: 0,
  },
  focused: {},
  notchedOutline: {
    borderColor: 'transparent',
    '&.disabled': {
      borderColor: 'transparent !important',
    },
  },
}))(MuiOutlinedInput)

const FormHelperText = withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body3,
    textAlign: 'left',
  },
}))(MuiFormHelperText)

const AssistiveMessage = withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body3,
    textAlign: 'right',
    color: theme.palette.text.secondary,
  },
}))(MuiFormHelperText)

export default TextField
