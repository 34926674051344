import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"

import { RootState } from "js/state/modules/rootReducer"

import LiteModeFallback from "./LiteModeFallback"
import TradeMainFallback from "./TradeMainFallback"

import PageLoadFallback from "../../PageLoadFallback"

const HomeFallback: React.FC = () => {
  const location = useLocation()
  const isSimpleMode = useSelector((store: RootState) => store.exchange.isSimpleMode)

  // lite mode fallback
  if (location.pathname.includes('lite')) {
    return <LiteModeFallback />
  }
  if (location.pathname.includes('trade')) {
    if (location.pathname.includes('recent_trades')) {
      return <PageLoadFallback />
    }
    return <TradeMainFallback />
  }
  // trade main fallback
  return (isSimpleMode ? <LiteModeFallback /> : <TradeMainFallback />)
}

export default HomeFallback