import { Container, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"


const ProfileFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  return (
    <div className={classes.box}>
      <Container maxWidth={false} className={classes.containerDiv}>
        <div className={clsx(classes.summaryRow, commonClasses.flexRow, 'topBox')}>
          <div className={clsx(classes.connectedWalletSection, commonClasses.flexColumn, commonClasses.alignItemsCenter)}>
            <div className={classes.walletSkeletonWrapper}>
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </div>
          <div className={clsx(commonClasses.flexRow, classes.rightSummaryRow)}>
            <div className={clsx(commonClasses.flexColumn, commonClasses.alignItemsCenter, commonClasses.justifyContentSpaceBetween, classes.rightSummarySectionTop)}>
              <div className={classes.stakeSkeletonWrapper}>
                <LoadingSkeleton />
                <br />
                <LoadingSkeleton />
              </div>
            </div>
            <div className={classes.rightSummarySectionBottom}>
              <div className={classes.settingsSkeletonWrapper}>
                <LoadingSkeleton width={300} paddingBottom={48} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton paddingBottom={24} />
                <LoadingSkeleton />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.summaryRow}>
          <div className={classes.preferencesSection}>
            <div className={classes.preferencesSkeletonWrapper}>
              <LoadingSkeleton paddingBottom={48} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton paddingBottom={24} />
              <LoadingSkeleton />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(4, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4, 4, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 7),
    },
  },
  containerDiv: {
    padding: theme.spacing(0),
  },
  summaryRow: {
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '&.topBox': {
        marginTop: 0,
      },
    },
  },
  connectedWalletSection: {
    position: 'relative',
    flex: 1,
    padding: theme.spacing(5, 3.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.primary,
    marginBottom: '2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.5rem',
      padding: theme.spacing(1.25, 1.5),
    },
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        marginRight: theme.spacing(4),
        width: '50%',
      },
      '&:last-child': {
        width: '50%',
      },
    },
  },
  walletSkeletonWrapper: {
    width: '242px',
    height: '394px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '207px',
    }
  },
  rightSummaryRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    flexDirection: 'column',
    width: '100%',
  },
  rightSummarySectionTop: {
    flex: 1,
    padding: theme.spacing(2.5, 3, 3, 3.5),
    borderRadius: '0.25rem',
    marginBottom: '2rem',
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    [theme.breakpoints.down('sm')]: { // added this for mobile version
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.25rem',
      padding: '0.625rem 0.75rem',
    },
  },
  stakeSkeletonWrapper: {
    height: '96px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '72px',
    }
  },
  rightSummarySectionBottom: {
    flex: 3,
    padding: theme.spacing(2.5, 3, 3, 3.5),
    borderRadius: '0.25rem',
    marginBottom: '2rem',
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    [theme.breakpoints.down('sm')]: { // added this for mobile version
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.25rem',
      padding: theme.spacing(2.25, 1.5),
    },
  },
  settingsSkeletonWrapper: {
    [theme.breakpoints.down('sm')]: {
      height: '299px',
    },
  },
  preferencesSection: {
    flex: 1,
    padding: theme.spacing(2.5, 2.5, 3, 3.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.primary,
    marginBottom: '2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: '0.5rem',
      padding: theme.spacing(2.25, 1.5),
    },
  },
  preferencesSkeletonWrapper: {
    height: '388px',
    [theme.breakpoints.down('sm')]: {
      height: '376px',
    },
  },
}))

export default ProfileFallback