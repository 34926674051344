import { makeStyles, SvgIcon, Theme } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { MenuItem } from 'js/components/Common/MenuListItems'
import { isMobilePromoOngoing } from 'js/constants/competition'
import { StaticLinks } from 'js/constants/externalLinks'
import Paths from 'js/constants/paths'
import { EventAction, EvtCategory, useEventTrackers, useGetRouteMatch } from 'js/hooks'
import { getNet, getThemeType } from 'js/state/modules/app/selectors'
import { RootState } from 'js/state/modules/rootReducer'
import { HIDE_FLUO_STAKING, HIDE_STRATEGIES, IS_STRATEGIES_MAINNET } from 'js/utils/environment'

import { ReactComponent as Blog } from 'assets/Blog.svg'
import { ReactComponent as Discord } from 'assets/Discord.svg'
import { ReactComponent as DocIcon } from 'assets/DocIcon.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'
import { ReactComponent as GLPCompounder } from 'assets/GLPCompounder.svg'
import { ReactComponent as GitBook } from 'assets/GitBook.svg'
import { ReactComponent as MobileIcon } from 'assets/Icon/Mobile.svg'
import { ReactComponent as TrophyIcon } from 'assets/Icon/Trophy.svg'
import { ReactComponent as LaunchVaults } from 'assets/LaunchVaults.svg'
import { ReactComponent as LeaderboardIcon } from 'assets/LeaderboardIcon.svg'
import { ReactComponent as MarketIcon } from 'assets/MarketIcon.svg'
import { ReactComponent as MenuPools } from 'assets/MenuPools.svg'
import { ReactComponent as MenuRecentTrades } from 'assets/MenuRecentTrades.svg'
import { ReactComponent as MenuStake } from 'assets/MenuStake.svg'
import { ReactComponent as MenuSwap } from 'assets/MenuSwap.svg'
import { ReactComponent as MenuTrade } from 'assets/MenuTrade.svg'
import { ReactComponent as Nitron } from 'assets/Nitron.svg'
import { ReactComponent as ReferralIcon } from 'assets/Referral.svg'
import { ReactComponent as Rewards } from 'assets/Reward.svg'
import { ReactComponent as SimplifiedVault } from 'assets/SimplifiedVault.svg'
import { ReactComponent as Strategies } from 'assets/Strategies.svg'
import { ReactComponent as Telegram } from 'assets/Telegram.svg'
import { ReactComponent as Ideas } from 'assets/Tips.svg'
import { ReactComponent as AccountBalanceWallet } from 'assets/WalletOutline.svg'
import { ReactComponent as WhyDemex } from 'assets/WhyDemex.svg'
import { ReactComponent as XIcon } from 'assets/X.svg'
import { ReactComponent as Github } from 'assets/socials/GitHub.svg'

import IconButton from '../Common/IconButton'

interface MenuLinks {
  Trade: MenuItem
  Swap: MenuItem
  Nitron: MenuItem
  Balance: MenuItem
  Earn: MenuItem[]
  Leaderboard: MenuItem[]
  Promotion: MenuItem
  More: MenuItem[]
  Help: MenuItem[]
}

const MenuLinksConfig = (): MenuLinks => {
  const classes = useStyles()
  const history = useHistory()
  const eventTrackers = useEventTrackers()
  const themeType = useSelector(getThemeType)
  const { isTrade, isRecentTrades, isLite, isSwap, isPools, isStake, isBorrow, isLiquidations,
    isGLPWrapper, isStrategies, isMarkets, isCompetition, isReferrals,
    isLeaderboard, isPromotionHub, isMobilePromo, isFluoDashboard, isLaunchVaults, isPerpPool, isWalletBalance } = useGetRouteMatch()
  const mobilePromoOngoing = isMobilePromoOngoing()

  const isSimpleMode = useSelector((store: RootState) => store.exchange.isSimpleMode)
  const network = useSelector(getNet)

  const handleClickGA = (path: string | { pathname: string, state: string }, gaEventCategory: EvtCategory, gaEventAction: EventAction, eventLabel?: string) => {
    history.push(path)
    eventTrackers.sendEvent(gaEventCategory, gaEventAction, { source: 'navbar' }, eventLabel)
  }

  const goToExternalLink = (link: string, gaEventAction?: EventAction) => {
    window.open(link, '_blank')
    gaEventAction && eventTrackers.sendEvent(EvtCategory.Navigation, gaEventAction, { source: 'navbar' })
  }

  const tradeLink: MenuItem = {
    key: 'trade-ui',
    active: isTrade || isLite,
    className: classes.menuLabel,
    label: 'Trade',
    onClick: () => handleClickGA(isSimpleMode ? Paths.Lite : Paths.Trade.Main, EvtCategory.Navigation, 'menu_trade', isSimpleMode ? 'lite' : 'pro'),
    startIcon: MenuTrade,
    startIconType: 'fill',
  }

  const swapLink: MenuItem = {
    key: 'swap-ui',
    active: isSwap,
    className: classes.menuLabel,
    label: 'Swap',
    onClick: () => handleClickGA(Paths.Swap.Home, EvtCategory.Navigation, 'menu_swap'),
    startIcon: MenuSwap,
    startIconType: 'stroke',
  }

  const nitronLink: MenuItem = {
    active: isBorrow || isLiquidations,
    key: 'nitron',
    label: 'Lend On Nitron',
    onClick: () => handleClickGA(Paths.Nitron.Main, EvtCategory.Navigation, 'menu_nitron'),
    startIcon: Nitron,
    startIconType: 'fill',
  }

  const balanceLink: MenuItem = {
    active: isWalletBalance,
    key: 'wallet-balance',
    label: 'Balances',
    className: classes.menuLabel,
    onClick: () => history.push(Paths.Account.Balance),
    startIcon: AccountBalanceWallet,
    startIconType: 'fill',
  }

  const earnLinks = React.useMemo((): MenuItem[] => {
    const initTextLinks: MenuItem[] = [{
      active: isPerpPool,
      key: 'perp-pool-manage',
      label: 'Perp Pools',
      onClick: () => handleClickGA(Paths.Pools.PerpList, EvtCategory.Navigation, 'menu_perp_pool'),
      startIcon: SimplifiedVault,
      startIconType: 'fill',
    }, {
      active: isPools,
      key: 'pools',
      label: 'Pools',
      onClick: () => handleClickGA(Paths.Pools.List, EvtCategory.Navigation, 'menu_pools'),
      startIcon: MenuPools,
      startIconType: 'stroke',
    }, {
      active: isStake,
      key: 'staking',
      onClick: () => handleClickGA(Paths.Stake.List, EvtCategory.Earn, 'stake_swth_tap'),
      label: 'Stake SWTH',
      startIcon: MenuStake,
      startIconType: 'stroke',
      endIconType: 'fill',
    }, {
      active: isGLPWrapper,
      key: 'glp-compounder',
      label: 'GLP Compounder',
      onClick: () => handleClickGA(Paths.Strategy.GLPWrapper, EvtCategory.Navigation, 'menu_glp_compounder'),
      startIcon: GLPCompounder,
      startIconType: 'fill',
    }]

    if (!IS_STRATEGIES_MAINNET && !HIDE_STRATEGIES) {
      initTextLinks.splice(2, 0, {
        active: isStrategies,
        key: 'strategies',
        label: 'Strategies',
        onClick: () => handleClickGA(Paths.Strategy.Main, EvtCategory.Navigation, 'menu_glp_compounder'),
        startIcon: Strategies,
        startIconType: 'fill',
      })
    }

    if (!HIDE_FLUO_STAKING) {
      initTextLinks.splice(2, 0, {
        active: isLaunchVaults,
        key: 'launch-vaults',
        label: 'Launch Vaults',
        onClick: () => history.push(Paths.LaunchVaults.Main), // TODO update this with GA
        startIcon: LaunchVaults,
        startIconType: 'fill',
      })
    }

    return initTextLinks
  }, [isPools, isPerpPool, isStake, isBorrow, isLiquidations, isGLPWrapper, isLaunchVaults, isFluoDashboard, network]) // eslint-disable-line

  const leaderboardLinks = React.useMemo((): MenuItem[] => {
    const links: MenuItem[] = [{
      key: 'competition',
      active: isCompetition,
      onClick: () => handleClickGA(Paths.Competition.Main, EvtCategory.Misc, 'campaign_tap'),
      label: (
        <span className={classes.compLabel}>
          Demex Trading League
        </span>
      ),
      startIcon: TrophyIcon,
      startIconType: 'fill',
    }, {
      key: 'referrals',
      active: isReferrals,
      onClick: () => handleClickGA(Paths.Account.Referrals, EvtCategory.Navigation, 'menu_referrals'),
      label: 'Referrals',
      startIcon: ReferralIcon,
      startIconType: 'stroke',
    }]

    if (mobilePromoOngoing) {
      links.splice(1, 0, {
        key: 'mobilePromo',
        active: isMobilePromo,
        onClick: () => history.push(Paths.Rewards.MobilePromotion),
        label: (
          <span className={classes.compLabel}>
            Mobile Trading Sprint
          </span>
        ),
        startIcon: MobileIcon,
        startIconType: 'stroke',
      })
    }

    return links
  }, [isCompetition, classes, isReferrals, mobilePromoOngoing, isMobilePromo]) // eslint-disable-line react-hooks/exhaustive-deps

  const promotionHubLink: MenuItem = {
    active: isPromotionHub,
    key: 'rewards',
    label: 'Rewards', // changed from 'Promotions'
    onClick: () => handleClickGA(Paths.Rewards.Main, EvtCategory.Navigation, 'menu_rewards'),
    startIcon: Rewards,
    startIconType: 'stroke',
  }

  const moreLinks = React.useMemo((): MenuItem[] => {
    return [{
      key: 'markets',
      active: isMarkets,
      label: 'Markets',
      onClick: () => handleClickGA(Paths.Markets.List, EvtCategory.Navigation, 'menu_markets'),
      startIcon: MarketIcon,
      startIconType: 'stroke',
    }, {
      key: 'recent-trades',
      active: isRecentTrades,
      onClick: () => handleClickGA(Paths.Trade.RecentTrades, EvtCategory.RecentTrades, 'click_recent_trades'),
      label: 'Recent Trades',
      startIcon: MenuRecentTrades,
      startIconType: 'stroke',
    }, {
      key: 'leaderboard',
      active: isLeaderboard,
      onClick: () => handleClickGA({
        pathname: Paths.Rewards.CarbonCreditsTradingLeague,
        state: 'pnl'
      }, EvtCategory.Navigation, 'menu_leaderboard'),
      label: 'Leaderboard',
      startIcon: LeaderboardIcon,
      startIconType: 'stroke',
      hasDivider: true,
    }, {
      key: 'whydemex',
      onClick: () => goToExternalLink(StaticLinks.Home),
      label: 'Why Demex ?',
      startIcon: WhyDemex,
      startIconType: 'stroke',
      endIcon: ExternalLinkIcon,
      endIconType: 'stroke',
    }, {
      key: 'blog',
      label: 'Blog',
      onClick: () => goToExternalLink(StaticLinks.DemexBlog.Main, 'menu_blog'),
      startIcon: Blog,
      startIconType: 'fill',
      endIcon: ExternalLinkIcon,
      endIconType: 'stroke',
    }, {
      key: 'guide',
      label: 'Guide',
      onClick: () => goToExternalLink(StaticLinks.DemexDocs.Home, 'menu_guide'),
      startIcon: GitBook,
      startIconType: 'fill',
      endIcon: ExternalLinkIcon,
      endIconType: 'stroke',
    }, {
      key: 'docs',
      label: 'Docs',
      onClick: () => goToExternalLink(StaticLinks.Api, 'menu_docs'),
      startIcon: DocIcon,
      startIconType: 'stroke',
      endIcon: ExternalLinkIcon,
      endIconType: 'stroke',
      hasDivider: true,
    }, {
      key: 'socialMedia',
      label: (
        <div className={classes.socialMediaButtons}>
          <IconButton
            className={classes.iconButton}
            onClick={() => goToExternalLink(StaticLinks.Socials.DemexTwitter, 'menu_twitter')}
            icontype="fill"
          >
            <SvgIcon
              className={classes.svgIcon}
              component={XIcon}
              viewBox="0 0 13 13"
            />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => goToExternalLink(StaticLinks.Socials.Telegram, 'menu_telegram')}
            icontype="fill"
          >
            <SvgIcon
              className={classes.svgIcon}
              component={Telegram}
            />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => goToExternalLink(StaticLinks.Socials.Discord, 'menu_discord')}
            icontype="fill"
          >
            <SvgIcon
              className={classes.svgIcon}
              component={Discord}
              viewBox="0 0 71 55"
            />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => goToExternalLink(StaticLinks.Socials.Github, 'menu_github')}
            icontype="fill"
          >
            <SvgIcon
              className={classes.svgIcon}
              component={Github}
              viewBox="0 0 24 24"
            />
          </IconButton>
        </div>
      ),
      menuItemWrapper: false,
    }]
  }, [network, isLeaderboard, isRecentTrades, isMarkets, isLiquidations, themeType]) // eslint-disable-line

  const helpLinks = React.useMemo((): MenuItem[] => {
    return [
      {
        key: 'guide',
        label: (
          <div className={classes.helpTitleWrapper}>
            <span>Help Center</span>
            <span className={classes.helpSubTitle}>Explore our guides for troubleshooting</span>
          </div>
        ),
        onClick: () => goToExternalLink(StaticLinks.DemexDocs.Home),
        startIcon: GitBook,
        startIconType: 'fill',
        endIcon: ExternalLinkIcon,
        endIconType: 'stroke',
      },
      {
        key: 'telegram',
        label: (
          <div className={classes.helpTitleWrapper}>
            <span>Join Telegram</span>
            <span className={classes.helpSubTitle}>Get live support and chat with the community</span>
          </div>
        ),
        onClick: () => goToExternalLink(StaticLinks.Socials.Telegram),
        startIcon: Telegram,
        startIconType: 'fill',
        endIcon: ExternalLinkIcon,
        endIconType: 'stroke',
      },
      {
        key: 'discord',
        label: (
          <div className={classes.helpTitleWrapper}>
            <span>Join Discord</span>
            <span className={classes.helpSubTitle}>Get dev support with Demex API</span>
          </div>
        ),
        onClick: () => goToExternalLink(StaticLinks.Socials.Discord),
        startIcon: Discord,
        startIconType: 'fill',
        endIcon: ExternalLinkIcon,
        endIconType: 'stroke',
      },
      {
        key: 'feedback',
        label: (
          <div className={classes.helpTitleWrapper}>
            <span>Provide Feedback</span>
            <span className={classes.helpSubTitle}>Suggest ideas and product feedback</span>
          </div>
        ),
        onClick: () => goToExternalLink(StaticLinks.Ideas),
        startIcon: Ideas,
        startIconType: 'fill',
        endIcon: ExternalLinkIcon,
        endIconType: 'stroke',
      },
    ]
  }, [classes.helpTitleWrapper, classes.helpSubTitle]) // eslint-disable-line

  return {
    Trade: tradeLink,
    Swap: swapLink,
    Nitron: nitronLink,
    Balance: balanceLink,
    Earn: earnLinks,
    Leaderboard: leaderboardLinks,
    Promotion: promotionHubLink,
    More: moreLinks,
    Help: helpLinks,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  startIcon: {
    '&.large': {
      maxWidth: '1.375rem',
      maxHeight: '1.375rem',
      minWidth: '1.375rem',
      minHeight: '1.375rem',
    },
  },
  endIcon: {
    maxWidth: '0.75rem',
    maxHeight: '0.75rem',
    minWidth: '0.75rem',
    minHeight: '0.75rem',
  },
  fire: {
    height: '1.375rem',
    width: '1.375rem',
  },
  compLabel: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 6,
    },
  },
  socialMediaButtons: {
    display: 'flex',
    columnGap: theme.spacing(1),
    padding: theme.spacing(2, 1.5),
  },
  iconButton: {
    margin: '0',
    height: theme.spacing(2),
    width: theme.spacing(2),
    borderRadius: '4px',
    padding: '4px',
    backgroundColor: theme.palette.background.divider,
    '&:hover': {
      backgroundColor: `${theme.palette.background.divider} !important`,
    },
  },
  svgIcon: {
    width: theme.spacing(1.75),
    height: 'unset',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  helpTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  helpSubTitle: {
    ...theme.typography.body3,
    [theme.breakpoints.only('xs')]: {
      ...theme.typography.body4,
    },
  },
}))

export default MenuLinksConfig
