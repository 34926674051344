import { Record } from 'immutable'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

import { BN_ZERO } from 'js/utils/number'

import {
  WalletBalanceStateProps,
  WalletBalanceActionTypes,
  WalletBalanceState,
} from './types'

const defaultValues: WalletBalanceStateProps = {
  balances: {},
  adjustedBalances: {},
  portfolio: {},
  nonZeroBalanceDenoms: undefined,
  derivedTokenRedemption: {},

  evmBalances: {},
  carbonBalances: {},
  isUSD: true,
  profile: null,
  selectedToken: null,
  depositOption: null,
  withdrawOption: null,
  groupTokenOption: '',

  walletFees: {},
  hasSufficientFees: null,
  neoTokenBalance: BN_ZERO,

  rawDelegations: null,
  rawUnbondingDelegations: null,
  tokenContractButtonPopUpState: {},

  adjustedStake: {},
  tokenProperties: {},
}

export const DefaultInitialState: Record.Factory<WalletBalanceStateProps> = Record<WalletBalanceStateProps>(cloneDeep(defaultValues))

const defaultInitialState: WalletBalanceState = new DefaultInitialState()

export const WalletBalanceReducer = (
  state: WalletBalanceState = defaultInitialState,
  action: AnyAction,
): WalletBalanceState => {
  switch (action.type) {
    case WalletBalanceActionTypes.SET_BALANCES: {
      return state.set('balances', action.payload)
    }
    case WalletBalanceActionTypes.SET_ADJUSTED_BALANCES: {
      return state.set('adjustedBalances', action.payload)
    }
    case WalletBalanceActionTypes.SET_PORTFOLIO: {
      return state.set('portfolio', action.payload)
    }
    case WalletBalanceActionTypes.SET_NON_ZERO_BALANCE_DENOMS: {
      return state.set('nonZeroBalanceDenoms', action.payload)
    }
    case WalletBalanceActionTypes.SET_DERIVED_TOKEN_REDEMPTION: {
      return state.set('derivedTokenRedemption', action.payload)
    }
    case WalletBalanceActionTypes.SET_EVM_WALLET_BALANCE: {
      return state.set('evmBalances', action.payload)
    }
    case WalletBalanceActionTypes.SET_CARBON_WALLET_BALANCE: {
      return state.set('carbonBalances', action.payload)
    }
    case WalletBalanceActionTypes.CLEAR_WALLET_BALANCE: {
      return new DefaultInitialState(cloneDeep(defaultValues))
    }
    case WalletBalanceActionTypes.SET_SELECTED_TOKEN: {
      return state.set('selectedToken', action.payload)
    }
    case WalletBalanceActionTypes.SELECT_DEPOSIT_OPTION: {
      return state.set('depositOption', action.payload)
    }
    case WalletBalanceActionTypes.SELECT_WITHDRAW_OPTION: {
      return state.set('withdrawOption', action.payload)
    }
    case WalletBalanceActionTypes.SELECT_GROUP_TOKEN_OPTION: {
      return state.set('groupTokenOption', action.payload)
    }
    case WalletBalanceActionTypes.SET_WALLET_FEES: {
      return state.setIn(
        ['walletFees', action.payload.token_denom],
        action.payload,
      )
    }
    case WalletBalanceActionTypes.SET_HAS_SUFFICIENT_FEES: {
      return state.set('hasSufficientFees', action.payload)
    }
    case WalletBalanceActionTypes.CLEAR_WALLET_FEES: {
      const newWalletFees = { ...state.get('walletFees') }
      delete newWalletFees[action.payload]
      return state.set('walletFees', newWalletFees)
    }
    case WalletBalanceActionTypes.SET_NEO_TOKEN_BALANCE: {
      return state.set('neoTokenBalance', action.payload)
    }
    case WalletBalanceActionTypes.SET_RAW_DELEGATIONS: {
      return state.set('rawDelegations', action.payload)
    }
    case WalletBalanceActionTypes.SET_RAW_UNBONDING_DELEGATIONS: {
      return state.set('rawUnbondingDelegations', action.payload)
    }
    case WalletBalanceActionTypes.SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE: {
      return state.set('tokenContractButtonPopUpState', action.payload)
    }
    case WalletBalanceActionTypes.SET_TOKEN_PROPERTIES: {
      return state.set('tokenProperties', action.payload)
    }
    default:
      return state
  }
}
