import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved

import { SimpleMap } from './types'

export const safeParseStoredValue = <T = unknown>(key: string | null, backup?: T) => {
  if (!key) {
    return backup
  }
  try {
    return JSON.parse(key)
  } catch (error) {
    return backup
  }
}
/*
  Save value in local storage in the format {[network]:{[address]:value}}. Useful when caching wallet related information across networks. 
*/
export const saveUserValue = (key: string, address: string, network: Network, value: any) => {
  const valuesStr = localStorage.getItem(key)
  const values: Record<Network, SimpleMap<any>> | undefined = safeParseStoredValue(valuesStr)
  if (!values) {
    localStorage.setItem(key, JSON.stringify({ [network]: { [address]: value } }))
    return
  }
  let networkValues: SimpleMap<any> = values[network]
  networkValues ? networkValues[address] = value : values[network] = { [address]: value }
  localStorage.setItem(key, JSON.stringify(values))
}

export const getUserValue = (key: string, address: string, network: Network) => {
  const valuesStr = localStorage.getItem(key)
  const values: Record<Network, SimpleMap<any>> | undefined = safeParseStoredValue(valuesStr)
  return values?.[network]?.[address]
}

export const removeUserValue = (key: string, address: string, network: Network) => {
  const valuesStr = localStorage.getItem(key)
  const values: Record<Network, SimpleMap<any>> | undefined = safeParseStoredValue(valuesStr)
  const value = values?.[network]?.[address]
  if(!value) return
  delete values?.[network]?.[address]
  localStorage.setItem(key, JSON.stringify(values))
}