import BigNumber from 'bignumber.js'
import { Blockchain, ExternalUtils, Models, TypeUtils } from 'carbon-js-sdk'
import { Profile } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/profile/profile'
import { SubAccount } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/subaccount/subaccount'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import { Dayjs } from 'dayjs'
import { RecordOf } from 'immutable'
import { Action as ReduxAction } from 'redux'

import { BroadcastTxParams } from 'js/constants/tx'
import { ChartLayoutType } from 'js/utils'
import { UserNotification } from 'js/utils/notifications'

export type UsersFeeSettings = {
  [key: string]: TokenFeeSettings
}

export interface ConnectionError {
  errorPriority: number
  errMessage: string
}
export interface TokenFeeSettings {
  reserve: number
}

export type SmartWalletAddressMap = {
  [chain in Blockchain]?: string
}

export interface AccountStateProps {
  readonly profile: Profile | null
  readonly loginRedirect: string | null

  readonly neoExternalBalances: ExternalUtils.TokensWithExternalBalance[]
  readonly ethExternalBalances: ExternalUtils.TokensWithExternalBalance[]
  readonly bscExternalBalances: ExternalUtils.TokensWithExternalBalance[]
  readonly n3ExternalBalances: ExternalUtils.TokensWithExternalBalance[]
  readonly tokenFeeSettings: TokenFeeSettings | null
  readonly reservedTokensPreference: ReservedTokensPreference | null

  readonly smartWalletAddress?: SmartWalletAddressMap

  readonly enableReservedFee: boolean | null
  readonly hideZero: boolean
  readonly tokenFeePrefs: TokenFeePreference | null
  readonly contactData: ContactData
  readonly recentlyUsedContactData: Contact[]
  readonly userNotifications: UserNotification[]
  readonly connectionError: ConnectionError
  readonly showAddAddressDialog: boolean
  readonly showCarbonAddress: boolean
  readonly showCreateSubAccountDialog: boolean
  readonly pendingSubAccountCreations: SubAccount[] | undefined
  readonly pendingSubAccountActivations: SubAccount[] | undefined
  readonly pendingSubAccountNotifications: SubAccount[] | undefined
  readonly subAccounts: SubAccount[] | undefined
  readonly subAccountCooldown: Models.Duration
  readonly isSWTHDefaultWithdrawal: boolean
  readonly isSWTHWithdrawal: boolean

  readonly socialLoginInfo?: SocialLoginInfo

  readonly isSignlessTransactionsEnabled: boolean
  readonly granteeDetailsMap: TypeUtils.SimpleMap<GranteeDetails>
  readonly lastTransactionMap: TypeUtils.SimpleMap<LastTransaction>
  readonly txBeforeMergeEvm: TxBeforeMergeEvm | null
  readonly signlessDialogTrigger?: string
}

export type AccountState = RecordOf<AccountStateProps>

export interface TxBeforeMergeEvm {
  broadcastTxParams: BroadcastTxParams
  taskName: string
  txTypes: string[]
}

export interface AccountProfile {
  username: string
  twitter: string
}

export interface SocialLoginInfo {
  identifier?: string
  displayName?: string
  typeOfLogin?: string
  profileImageUrl?: string
}

export interface Action<T> extends ReduxAction {
  payload: T;
}

export const AccountActionTypes = {
  LOGOUT: '@account/LOGOUT',
  SET_NEO_EXTERNAL_BALANCES: '@account/SET_NEO_EXTERNAL_BALANCES',
  SET_ETH_EXTERNAL_BALANCES: '@account/SET_ETH_EXTERNAL_BALANCES',
  SET_BSC_EXTERNAL_BALANCES: '@account/SET_BSC_EXTERNAL_BALANCES',
  SET_SMART_WALLET_ADDRESSES: '@account/SET_SMART_WALLET_ADDRESSES',
  SET_PROFILE: '@account/SET_PROFILE',
  SET_SOCIAL_LOGIN_INFO: '@account/SET_SOCIAL_LOGIN_INFO',
  SUBMIT_PROFILE: '@account/SUBMIT_PROFILE',
  POLL_PROFILE_UPDATE: '@account/POLL_PROFILE_UPDATE',
  ENABLE_PROFILE_SUBMIT: '@account/ENABLE_PROFILE_SUBMIT',
  DISABLE_PROFILE_SUBMIT: '@account/DISABLE_PROFILE_SUBMIT',
  ENABLE_RESERVED_FEE_FEATURE: '@account/ENABLE_RESERVED_FEE_FEATURE',
  SET_HIDE_ZERO_FALSE: '@account/SET_HIDE_ZERO_FALSE',
  SET_HIDE_ZERO_TRUE: '@account/SET_HIDE_ZERO_TRUE',
  SET_HIDE_ZERO: '@account/SET_HIDE_ZERO',
  SET_LOGIN_REDIRECT: '@account/SET_LOGIN_REDIRECT',
  SET_TOKEN_FEE_SETTINGS: '@account/SET_TOKEN_FEE_SETTINGS',
  SET_TOKEN_FEE_PREFERENCE: '@account/SET_TOKEN_FEE_PREFERENCE',
  SET_TEMP_GRID_LAYOUT: '@account/SET_TEMP_GRID_LAYOUT',
  SET_TEMP_SELECTED_TOKENS: '@account/SET_TEMP_SELECTED_TOKENS',
  FETCH_CONTACT_DATA: '@account/FETCH_CONTACT_DATA',
  SET_CONTACT_DATA: '@account/SET_CONTACT_DATA',
  SET_SHOW_ADD_ADDRESS_DIALOG: '@account/SET_SHOW_ADD_ADDRESS_DIALOG',
  QUERY_USER_NOTIFICATIONS: '@account/QUERY_USER_NOTIFICATIONS',
  SET_USER_NOTIFICATIONS: '@account/SET_USER_NOTIFICATIONS',
  RESET_USER_NOTIFICATIONS: '@account/RESET_USER_NOTIFICATIONS',
  SET_CONNECTION_ERROR: '@account/SET_CONNECTION_ERROR',
  SET_SHOW_CARBON_ADDRESS: '@account/SET_SHOW_CARBON_ADDRESS',
  SET_SHOW_CREATE_SUBACCOUNT_DIALOG: '@account/SET_SHOW_CREATE_SUBACCOUNT_DIALOG',
  FETCH_PENDING_SUBACCOUNT_CREATIONS: '@account/FETCH_PENDING_SUBACCOUNT_CREATIONS',
  FETCH_PENDING_SUBACCOUNT_ACTIVATIONS: '@account/FETCH_PENDING_SUBACCOUNT_ACTIVATIONS',
  SET_PENDING_SUBACCOUNT_CREATIONS: '@account/SET_PENDING_SUBACCOUNT_CREATIONS',
  SET_PENDING_SUBACCOUNT_ACTIVATIONS: '@account/SET_PENDING_SUBACCOUNT_ACTIVATIONS',
  SET_PENDING_SUBACCOUNT_NOTIFICATIONS: '@account/SET_PENDING_SUBACCOUNT_NOTIFICATIONS',
  REMOVE_PENDING_SUBACCOUNT_NOTIFICATION: '@account/REMOVE_PENDING_SUBACCOUNT_NOTIFICATION',
  FETCH_SUBACCOUNTS: '@account/FETCH_SUBACCOUNTS',
  SET_SUBACCOUNTS: '@account/SET_SUBACCOUNTS',
  RESET_SUBACCOUNTS_DATA: '@account/RESET_SUBACCOUNTS_DATA',
  FETCH_SUBACCOUNT_COOLDOWN: '@account/FETCH_SUBACCOUNT_COOLDOWN',
  SET_SUBACCOUNT_COOLDOWN: '@account/SET_SUBACCOUNT_COOLDOWN',
  SET_DEFAULT_WITHDRAWAL_SWTH: '@acount/SET_DEFAULT_WITHDRAWAL_SWTH',
  SET_WITHDRAWAL_SWTH: "@account/SET_WITHDRAWAL_SWTH",
  LOAD_DEFAULT_WITHDRAWAL_SWTH: '@account/LOAD_DEFAULT_WITHDRAWAL_SWTH',
  SET_RESERVED_TOKENS_PREFERENCE: '@account/SET_RESERVED_TOKENS_PREFERENCE',
  SET_SIGNLESS_DIALOG_TRIGGER: '@account/SET_SIGNLESS_DIALOG_TRIGGER',
  SET_ENABLE_SIGNLESS_TRANSACTIONS: '@account/SET_ENABLE_SIGNLESS_TRANSACTIONS',
  SET_GRANTEE_DETAILS: '@account/SET_GRANTEE_DETAILS',
  SET_LAST_TRANSACTION_TIMESTAMP: '@account/SET_LAST_TRANSACTION_TIMESTAMP',
  SET_TX_BEFORE_MERGE_EVM: '@account/SET_TX_BEFORE_MERGE_EVM',
  SET_RECENTLY_USED_CONTACT_DATA: '@account/SET_RECENTLY_USED_CONTACT_DATA',
  HANDLE_UPDATE_RECENTLY_USED_CONTACT_DATA: '@account/HANDLE_UPDATE_RECENTLY_USED_CONTACT_DATA',
  HANDLE_EDIT_RECENTLY_USED_CONTACT_DATA: '@account/HANDLE_EDIT_RECENTLY_USED_CONTACT_DATA',
  CLEAR_RECENTLY_USED_CONTACT_DATA: '@account/CLEAR_RECENTLY_USED_CONTACT_DATA',
}

export interface EnableReservedFees {
  [key: string]: boolean
}
export type TokenFeePreference = {
  selectedTokens: string[]
  unselectedTokens: string[]
  layout: ChartLayoutType[]
}

export type ReservedTokensPreference = {
  tokenPrefDenoms: string[]
  firstPriority: { denom: string, symbol: string }
  reservedTokens: SimpleMap<BigNumber> // USD value of reservation
}

export type UsersTokenFeePreferences = {
  [key: string]: TokenFeePreference
}


type TokenSubset = {
  denom: string
  symbol: string
}
export interface IndivTokenFeePreference extends TokenFeePreference {
  tokenPrefSymbols: string[]
  tokenPrefDenoms: string[]
  firstPriorityToken: TokenSubset
  topUpToken: TokenSubset
}

export interface Contact {
  id: string
  contactAddress: string
  contactName: string
  network: string
  token: string
  timestamp: Dayjs
}

export interface ContactData {
  result: {
    entries: Contact[]
  }
}

export interface GranteeDetails {
  expiration: Date,
  mnemonics: string,
  granteeAddress: string,
  authMsgVersion: number,
}

export interface LastTransaction {
  lastTransactionTimestamp: number,
  secondsFromPreviousTransaction: number,
}

export interface SignlessDialogTrigger {
  triggerType: 'tx' | 'settings' | null,
  walletAddress: string,
}
