import { Coin } from '@cosmjs/stargate'
import BigNumber from 'bignumber.js'
import { CDPLiquidations } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/cdp_liquidations'
import { CdpPosition } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/query'
import { RecordOf } from 'immutable'

export interface RiskyPosition extends CdpPosition {
  totalCollateral: BigNumber
  totalDebts: BigNumber
  bonus: BigNumber
  debts: CDPCoin[]
}

export interface CDPCoin extends Coin {
  isMinted: boolean
}

export interface BorrowerPosition extends CdpPosition {
  debts: CDPCoin[]

}

export interface ModuleTotal {
  collateralsUsd: BigNumber | null
  debtsUsd: BigNumber | null
}

export interface CDPLiquidationsStateProps {
  readonly moduleTotal: ModuleTotal
  readonly liquidations: CDPLiquidations[]
  readonly positions: CdpPosition[]
}

export type CDPLiquidationsState = RecordOf<CDPLiquidationsStateProps>


export const CdpLiquidationsActionTypes = {
  SET_LIQUIDATIONS: '@cdpLiquidations/SET_LIQUIDATIONS',
  SET_POSITIONS: '@cdpLiquidations/SET_POSITIONS',
  SET_MODULE_TOTAL: '@cdpLiquidations/SET_MODULE_TOTAL',
  QUERY_LIQUIDATION_HISTORY: '@cdp/QUERY_LIQUIDATION_HISTORY',
  QUERY_POSITIONS: '@cdp/QUERY_POSITIONS',
}

export type HistoryParams = {
  page: number,
  hideHealthFactor: boolean,
  hideSmallDebtAmt: boolean,
}