/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { Carbon, CarbonSDK, OrderModule, TypeUtils } from 'carbon-js-sdk' // eslint-disable-line import/no-unresolved
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { List } from 'immutable'
import { capitalize } from 'lodash'

import { SHIFT_DECIMALS } from 'js/constants/assets'
import { isLimit } from 'js/models/Order'
import { BooksObj, Market, OpenOrderLevel } from 'js/state/modules/exchange/types'
import { ModifiedHistoryOrder } from 'js/state/modules/history/types'
import { OrderBookEntry } from 'js/state/modules/orderBook/types'
import { OrderManagerActionTypes } from 'js/state/modules/orderManager/types'
import { AdjustedBalance } from 'js/state/modules/walletBalance/types'
import { BN_ZERO, parseNumber } from 'js/utils/number'

export const highPriceImpact = new BigNumber(1)
export const midPriceImpact = new BigNumber(0.5)

// pro mode slippage tolerance
const slippageTolerance: number = 40

// set ignoreSide to TRUE when input type is quantity
export function massageInput(
  input: BigNumber, side: string, tickSize: BigNumber, ignoreSide: boolean,
) {
  if (!input.isNaN() && !input.div(tickSize).isInteger()) {
    if (ignoreSide || side === 'buy') {
      return input.div(tickSize).integerValue(BigNumber.ROUND_DOWN).times(tickSize)
    }
    if (side === 'sell') {
      return input.div(tickSize).integerValue(BigNumber.ROUND_UP).times(tickSize)
    }
  }
  return input
}

export interface TickLotSizes {
  tickSize: BigNumber
  lotSize: BigNumber
}

export function getAdjustedTickLotSize(
  market: Market | null | undefined,
): TickLotSizes {
  if (!market) {
    return {
      tickSize: BN_ZERO,
      lotSize: BN_ZERO,
    }
  }

  const baseDp = market?.basePrecision.toNumber() ?? 0
  const quoteDp = market?.quotePrecision.toNumber() ?? 0
  const diffDp = quoteDp - baseDp

  const lotSize = parseNumber(market?.lotSize, BN_ZERO)!
  const tickSize = parseNumber(market?.tickSize, BN_ZERO)!.shiftedBy(-SHIFT_DECIMALS)

  const adjustedLotSize = lotSize.shiftedBy(-baseDp)
  const adjustedTickSize = tickSize.shiftedBy(-diffDp)

  return {
    tickSize: adjustedTickSize,
    lotSize: adjustedLotSize,
  }
}

export const handleStoreSessionPrice = (price: BigNumber, orderType: OrderModule.OrderType, marketId: string) => {
  if (isLimit(orderType)) {
    const sessionStoragePrices = sessionStorage.getItem(OrderManagerActionTypes.SET_CACHED_ORDER_PRICES)
    const sessionStoragePricesJson = sessionStoragePrices ? JSON.parse(sessionStoragePrices) : {}
    sessionStorage.setItem(OrderManagerActionTypes.SET_CACHED_ORDER_PRICES, JSON.stringify({
      ...sessionStoragePricesJson,
      [marketId]: price.toString(10),
    }))
  }
}

export const calculateMidPrice = (booksObj: BooksObj, market: Market | null,) => {
  const baseDp = market?.basePrecision.toNumber() ?? 0
  const quoteDp = market?.quotePrecision.toNumber() ?? 0

  const openBuys = market ? booksObj[market.id]?.bids : []
  const openSells = market ? booksObj[market.id]?.asks : []

  const openBuysOrder = sortByPriceLevel(openBuys, false)
  const openSellsOrder = sortByPriceLevel(openSells, true)

  const midPrice = bnOrZero(openBuysOrder[0]?.price)
    .plus(bnOrZero(openSellsOrder[0]?.price))
    .div(2)
    .shiftedBy(baseDp - quoteDp)
    .shiftedBy(-SHIFT_DECIMALS)

  return midPrice
}

export function getNewPositionState(oldLots: BigNumber, newLots: BigNumber) {
  // increasing
  if (oldLots.isZero()) return 'increasing'
  if (oldLots.isPositive()
    && newLots.gt(oldLots)
    && newLots.isPositive()) {
    return 'increasing'
  }
  if (oldLots.isNegative()
    && newLots.lt(oldLots)
    && newLots.isNegative()) {
    return 'increasing'
  }

  if (!oldLots.isZero()) {
    // reducing
    if (oldLots.isPositive()
      && newLots.lt(oldLots)
      && !newLots.isNegative()) {
      return 'reducing'
    }
    if (oldLots.isNegative()
      && newLots.gt(oldLots)
      && !newLots.isPositive()) {
      return 'reducing'
    }
    // past_neutral
    if (oldLots.isPositive()
      && newLots.lt(oldLots)
      && newLots.isNegative()) {
      return 'past_neutral'
    }
    if (oldLots.isNegative()
      && newLots.gt(oldLots)
      && newLots.isPositive()) {
      return 'past_neutral'
    }
  }

  if (oldLots.eq(newLots)) {
    return 'no_change'
  }

  return 'error'
}

export function calculateSliderValue(quantity: BigNumber, maxQuantity: BigNumber) {
  if (maxQuantity === BN_ZERO) {
    return new BigNumber(0)
  }
  const factor = quantity.div(maxQuantity)
  const one = new BigNumber(1)
  if (factor.gt(one)) return new BigNumber(100)
  if (factor.isNaN()) return new BigNumber(0)
  return factor.times(100)
}

export function getExpectedMarginAndEntryPrice(
  expectedPositionState: string,
  currentAllocatedMargin: BigNumber,
  orderTotal: BigNumber,
  currentTotal: BigNumber,
  newLots: BigNumber,
  expectedLotsBN: BigNumber,
  openPositionLots: BigNumber,
  currentEntryPrice: BigNumber,
  orderPriceBN: BigNumber,
  leverageBN: BigNumber,
) {
  const results = {
    expectedMargin: new BigNumber(0),
    expectedEntryPrice: new BigNumber(0),
  }
  switch (expectedPositionState) {
    case 'increasing':
      results.expectedMargin = currentAllocatedMargin.plus(orderTotal.abs().div(leverageBN))
      results.expectedEntryPrice = (orderTotal.plus(currentTotal)).div(newLots)
      return results
    case 'reducing':
      results.expectedEntryPrice = currentEntryPrice
      // eslint-disable-next-line no-case-declarations
      const minExpectedMargin = expectedLotsBN.times(orderPriceBN).div(leverageBN).abs()
      results.expectedMargin = currentAllocatedMargin.times(newLots).div(openPositionLots)
      if (results.expectedMargin.lt(minExpectedMargin)
        && currentAllocatedMargin.gt(minExpectedMargin)) {
        results.expectedMargin = minExpectedMargin
      } else if (results.expectedMargin.lt(minExpectedMargin)
        && currentAllocatedMargin.lte(minExpectedMargin)) {
        // might happen when position is at a big loss
        results.expectedMargin = currentAllocatedMargin
      }
      return results
    case 'past_neutral':
      results.expectedMargin = newLots.times(orderPriceBN).div(leverageBN)
      results.expectedEntryPrice = orderPriceBN
      return results
    case 'no_change':
      results.expectedMargin = currentAllocatedMargin
      results.expectedEntryPrice = currentEntryPrice
      return results
    default:
      return results
  }
}

export function getBalancesSpotMarket(
  order: OrderModule.CreateOrderParams,
  adjustedBalances: TypeUtils.SimpleMap<AdjustedBalance>,
  market: Market,
  orderPrice: BigNumber,
): {
  availableBalance: BigNumber
  remainingBalance: BigNumber
} {
  let availableBalance = BN_ZERO
  let remainingBalance = BN_ZERO

  if (order?.side === 'buy') {
    availableBalance = adjustedBalances[market.quote]?.available ?? BN_ZERO
    remainingBalance = availableBalance.minus((orderPrice ?? BN_ZERO).times(order.quantity))
  } else {
    availableBalance = adjustedBalances[market.base]?.available ?? BN_ZERO
    remainingBalance = availableBalance.minus(order.quantity)
  }
  return { availableBalance, remainingBalance }
}

export function getRemainingBalanceFuturesMarket(
  availableBalance: BigNumber,
  orderTotal: BigNumber,
  effectiveLeverage: number,
  isOpenPosition: boolean,
  isOppositeSideOrder: boolean,
  isReversingPosition: boolean,
  estimatedPnL: BigNumber,
  expectedMargin: BigNumber,
): BigNumber {
  const additionalMarginRequired = orderTotal.abs().dividedBy(effectiveLeverage)
  if (!isOpenPosition || !isOppositeSideOrder) {
    return availableBalance.minus(additionalMarginRequired)
  }
  const marginAdjustment = isReversingPosition ? expectedMargin.abs() : BN_ZERO
  return availableBalance.plus(estimatedPnL).minus(marginAdjustment)
}

export const shiftByDiffDp = (
  market: Carbon.Market.Market | null | undefined,
  value: BigNumber | undefined,
): BigNumber => {
  if (!market || !value) {
    return BN_ZERO
  }
  const baseDp = market.basePrecision.toNumber()
  const quoteDp = market.quotePrecision.toNumber()
  const diffDp = quoteDp - baseDp
  if (value.isNaN() || !value.isFinite) {
    return BN_ZERO
  }
  return value.shiftedBy(diffDp)
}

export const unshiftByDiffDp = (
  market: Carbon.Market.Market | null | undefined,
  value: BigNumber | undefined,
): BigNumber => {
  if (!market || !value) {
    return BN_ZERO
  }
  const baseDp = market.basePrecision.toNumber()
  const quoteDp = market.quotePrecision.toNumber()
  const diffDp = quoteDp - baseDp
  if (value.isNaN() || !value.isFinite) {
    return BN_ZERO
  }
  return value.shiftedBy(-diffDp)
}

export const shiftByQuoteDp = (
  market: Carbon.Market.Market | null | undefined,
  value: BigNumber | undefined,
): BigNumber => {
  if (!market || !value) {
    return BN_ZERO
  }
  const quoteDp = market.quotePrecision.toNumber()
  if (value.isNaN() || !value.isFinite) {
    return BN_ZERO
  }
  return value.shiftedBy(-quoteDp)
}

export const shiftByBaseDp = (
  market: Carbon.Market.Market | null | undefined,
  value: BigNumber | undefined,
): BigNumber => {
  if (!market || !value) {
    return BN_ZERO
  }
  const baseDp = market.basePrecision.toNumber()
  if (value.isNaN() || !value.isFinite) {
    return BN_ZERO
  }
  return value.shiftedBy(-baseDp)
}

export const sortByPriceEntry = (orders: List<OrderBookEntry>, ascending: boolean) => {
  return orders.sort((a: OrderBookEntry, b: OrderBookEntry) => {
    const priceA = parseFloat(a.price)
    const priceB = parseFloat(b.price)
    return ascending ? priceA - priceB : priceB - priceA
  })
}

export const sortByPriceLevel = (orders: OpenOrderLevel[], ascending: boolean) => {
  return orders.sort((a: OpenOrderLevel, b: OpenOrderLevel) => {
    const priceA = parseFloat(a.price)
    const priceB = parseFloat(b.price)
    return ascending ? priceA - priceB : priceB - priceA
  })
}

// we should buffer for:
// (1) expected trading fees incurred by this order
// (2) the fees required to close the expected position
// WITH extra 20% margin of safetfy to account for price changes

// note: there will be an edge case where the user will not be able to REDUCE a position because of the fees required to close the position
// this current "double buffer" implementation will minimize the chance of the above edge case from occuring but it is still possible
// please beg the backend team to implement deducting trading fees from margin instead of available balance

function getFees(feeFactor: BigNumber, quantity: BigNumber, markPrice: BigNumber, side: OrderModule.OrderSide, currentPositionLotsBN: BigNumber): BigNumber {
  const currentFees = markPrice.times(quantity).times(feeFactor)
  const quantityBN = side === 'buy' ? new BigNumber(quantity) : new BigNumber(quantity).times(-1)
  const expectedLotsBN = quantityBN.plus(currentPositionLotsBN)
  const potentialFees = expectedLotsBN.abs().times(markPrice).times(feeFactor)
  return currentFees.plus(potentialFees).times(1.2)
}


export function getAdjustFuturesQuantityForFees(
  // quantity here is how much you can buy without fees and therefore inaccurate
  quantity: BigNumber,
  currentPositionLotsBN: BigNumber,
  side: OrderModule.OrderSide,
  adjustedFee: BigNumber,
  lotSize: BigNumber,
  freeCollateral: BigNumber,
  price: BigNumber,
  leverage: BigNumber,
) {

  const isReducing = (side === 'sell' && currentPositionLotsBN.isPositive()) || (side === 'buy' && currentPositionLotsBN.isNegative())
  // Fee calculation is done by solving the equation: margin + fees = freeCollateral
  // buffer is set at 1.2
  // margin = (quantity * markprice) / leverage
  // fees = (markprice * quantity * feefactor) + [|(quantitySign * quantity + currentPosition)| * markprice * feeFactor] * buffer
  // [(quantity * markprice) / leverage] + {(markprice * quantity * feefactor) + [|(quantitySign * quantity + currentPosition)| * markprice * feeFactor]} * buffer = freeCollateral
  // solving for quantity (taking note of the abs in fee calculation, hence separating the equation into two parts, you get the two equations below)
  const quantitySign = side === 'buy' ? new BigNumber(1) : new BigNumber(-1)

  const factor = price.times(1.2).times(adjustedFee).times(leverage)

  let quantityA = freeCollateral.times(leverage).minus(currentPositionLotsBN.times(factor)).div(price.plus(factor).plus(factor.times(quantitySign)))
  let quantityB = freeCollateral.times(leverage).plus(currentPositionLotsBN.times(factor)).div(price.plus(factor).minus(factor.times(quantitySign)))

  // account for lot size
  quantityA = quantityA.minus(quantityA.modulo(lotSize))
  quantityB = quantityB.minus(quantityB.modulo(lotSize))

  // solve for the two equations (throw quantity back into the formula: margin + fees = freeCollateral) and check which is closer to the total free collateral, then return that quantity
  const marginA = quantityA.times(price).div(leverage)
  const marginB = quantityB.times(price).div(leverage)

  const feeA = getFees(adjustedFee, quantityA, price, side, currentPositionLotsBN)
  const feeB = getFees(adjustedFee, quantityB, price, side, currentPositionLotsBN)

  const totalValueOfTradeA = marginA.plus(feeA)
  const totalValueOfTradeB = marginB.plus(feeB)


  // Total value of trade cannot be higher than the free collateral
  if (freeCollateral.minus(totalValueOfTradeA).isLessThan(0)) {
    return isReducing ? quantityB.plus(currentPositionLotsBN.abs()) : quantityB
  } if (freeCollateral.minus(totalValueOfTradeB).isLessThan(0)) {
    return isReducing ? quantityA.plus(currentPositionLotsBN.abs()) : quantityA
  }

  const finalQuantity = freeCollateral.minus(totalValueOfTradeA).gt(freeCollateral.minus(totalValueOfTradeB)) ? quantityB : quantityA

  return isReducing ? finalQuantity.plus(currentPositionLotsBN.abs()) : finalQuantity
}


export const getDefaultTimeInForce = (isMarketOrder: boolean) => {
  return isMarketOrder ? OrderModule.TimeInForce.Ioc : OrderModule.TimeInForce.Gtc
}

export const calculateOrderParams = (order: ModifiedHistoryOrder, sdk: CarbonSDK | undefined, orderMarket: Market | undefined): {
  priceBN: BigNumber
  quantityBN: BigNumber
  stopPriceBN: BigNumber
} => {
  const rawPriceBN = parseNumber(order.price, BN_ZERO)!
  const rawQuantityBN = parseNumber(order.quantity, BN_ZERO)!
  const rawStopPriceBN = parseNumber(order.stop_price, BN_ZERO)!

  if (!sdk || !orderMarket) {
    return {
      priceBN: rawPriceBN,
      quantityBN: rawQuantityBN,
      stopPriceBN: rawStopPriceBN,
    }
  }

  const priceBN = !new BigNumber(order.price).isZero()
    ? unshiftByDiffDp(orderMarket, new BigNumber(order.price))
    : unshiftByDiffDp(orderMarket, new BigNumber(order.avg_filled_price))
  const stopPriceBN = unshiftByDiffDp(orderMarket, new BigNumber(order.stop_price))
  const quantityBN = shiftByBaseDp(orderMarket, new BigNumber(order.quantity) ?? BN_ZERO)
  return {
    priceBN, quantityBN, stopPriceBN,
  }
}

export const orderTypeDisplay = (orderType: string): string => {
  let displayStr = ''
  switch (orderType) {
    case 'stop-limit':
      displayStr = 'Stop Limit'
      break
    case 'stop-market':
      displayStr = 'Stop Market'
      break
    case 'take-profit-limit':
      displayStr = 'Take Profit Limit'
      break
    case 'take-profit-market':
      displayStr = 'Take Profit Market'
      break
    default:
      displayStr = capitalize(orderType)
      break
  }
  return displayStr
}

// Trade UI memo function
export enum MemoTx {
  Create = 'c',
  Edit = 'e'
}

export enum MemoLocation {
  LiteMode = 'l',
  ProMode = 'p',
  SwapMode = 's',
  Fees = 'f',
  History = 'h',
  Chart = 'c',
}

/**
 * Function to generate tracking memos on Demex Trade UI
 * @param location indicates location of tx execution (i.e. from which component is this tx submitted from)
 * @param tx indicates type of tx executed (defaults to Create as create order txs are executed more often)
 * @returns tracking memo dmx-<tx>-<location>
 */
export const generateExchangeMemo = (location: MemoLocation, tx: MemoTx = MemoTx.Create): string => {
  return `dmx-${tx}-${location}`
}

export const generateFokOrderPrice = (targetPrice: BigNumber, isBuyOrder: boolean) => {
  const slippage = slippageTolerance / 100
  const fokIndexThreshold: number = slippage * (isBuyOrder ? 1 : -1)
  const fokInputPrice = targetPrice.times(1 + fokIndexThreshold)
  return fokInputPrice
}

export const getFokOrderType = (orderType: OrderModule.OrderType): OrderModule.OrderType => {
  switch (orderType) {
    case OrderModule.OrderType.Market:
      return OrderModule.OrderType.Limit
    case OrderModule.OrderType.StopMarket:
      return OrderModule.OrderType.StopLimit
    case OrderModule.OrderType.TakeProfitMarket:
      return OrderModule.OrderType.TakeProfitLimit
    default:
      return orderType
  }
}

export const getHighestBid = (openBuys: OrderBookEntry[]): OrderBookEntry | null => {
  let highestBid: OrderBookEntry | null = null
  openBuys.forEach((buy: OrderBookEntry) => {
    if (!highestBid || bnOrZero(buy.price).gt(bnOrZero(highestBid.price))) {
      highestBid = buy
    }

  })
  return highestBid
}


export const getLowestAsk = (openSells: OrderBookEntry[]): OrderBookEntry | null => {
  let lowestAsk: OrderBookEntry | null = null
  openSells.forEach((sell: OrderBookEntry) => {
    if (!lowestAsk || bnOrZero(sell.price).lt(bnOrZero(lowestAsk.price))) {
      lowestAsk = sell
    }

  })
  return lowestAsk
}

export const getRealSize = (size: string = '0', side: string = '') => {
  let sign = ''
  if (side === 'sell' || side === 'short') {
    sign = '-'
  }
  const sizeStr = `${sign}${size}`

  return parseNumber(sizeStr, BN_ZERO)!
}