import { Divider, Hidden, makeStyles, Paper, Tooltip as V2Tooltip, Typography, useTheme, useMediaQuery } from '@material-ui/core'
import { BlockchainUtils } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import DropdownButton from 'js/components/Common/DropdownButton'
import SvgIcon from 'js/components/Common/SvgIcon'
import TransferMethodIcon from 'js/components/Common/TransferMethodIcon'
import { customLabels, TransferChannel, TransferType } from 'js/constants/TransferOptions'
import Paths from 'js/constants/paths'
import { useDropdownHandler } from 'js/hooks'
import useTransferOptions, { OptionItem } from 'js/hooks/useTransferOptions'
import { getGroupTokens } from 'js/state/modules/app/selectors'
import { RootState } from 'js/state/modules/rootReducer'
import { selectDepositOption, selectWithdrawOption } from 'js/state/modules/walletBalance/actions'
import { WalletBalanceState } from 'js/state/modules/walletBalance/types'
import { StyleUtils, useCommonStyles } from 'js/utils'
import { getGroupTokenDetails, isGroupToken } from 'js/utils/groupTokens'

import { ReactComponent as CautionIcon } from 'assets/Caution.svg'

import TypographyLabel from '../TypographyLabel'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  defaultChannel?: TransferChannel
  type: TransferType
}

const SetterActions: {
  [index: string]: (channel: TransferChannel) => void
} = {
  deposit: selectDepositOption,
  withdraw: selectWithdrawOption,
}

const optionRegexOverride: BlockchainUtils.BlockchainV2[] = [
  'Native',
]

const TransferChannelSelect: React.FC<Props> = (props: Props) => {
  const { className, children, type: transferType, defaultChannel, ...rest } = props
  const [maxHeight, setMaxHeight] = useState<string | number>('14rem')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const boxRef = useRef<HTMLDivElement>(null)
  const [openDropdown, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const selectedToken = useSelector<RootState, Token | null>((store) => store.walletBalance.selectedToken)
  const walletBalance = useSelector<RootState, WalletBalanceState>((store) => store.walletBalance)
  const groupTokenDetails = useSelector(getGroupTokens)
  const transferOptions = useTransferOptions(selectedToken?.denom, transferType)
  // if matches look for denoms that is grouped
  const isTokenGroup = React.useMemo(() => {
    return isGroupToken(groupTokenDetails, selectedToken?.denom ?? '')
  }, [groupTokenDetails, selectedToken])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (!isMobile) {
      timeoutId = setTimeout(() => {
        const container = document?.querySelector('.outerBoxContainer')
        if (boxRef.current && container) {
          const rect = boxRef.current.getBoundingClientRect()
          const newMaxHeight = container.getBoundingClientRect().bottom - rect.bottom - 20
          setMaxHeight(newMaxHeight)
        }
      }, 200)
    } else {
      setMaxHeight('14rem')
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isMobile, transferOptions])

  const {
    selectedOption,
  } = React.useMemo(() => {
    const denom = selectedToken?.denom ?? '-'
    let selectedOption: TransferChannel | null = null

    switch (transferType) {
      case TransferType.Withdraw:
        selectedOption = walletBalance.withdrawOption
        break
      default:
        selectedOption = walletBalance.depositOption
        break
    }

    return {
      tokenDenom: denom,
      transferOptions,
      selectedOption,
    }
  }, [selectedToken, transferType, walletBalance, transferOptions])

  const onSelect = (option: TransferChannel) => () => {
    const setterAction = SetterActions[transferType]
    dispatch(setterAction?.(option))
    handleDropdownClose()
  }

  if (!selectedToken) {
    return <Redirect to={Paths.Account.Home} />
  }

  // eslint-disable-next-line
  const channelLabelRegex = /\(([a-z0-9 ]+)\)$/i
  const labelRegexArr = (selectedOption?.label ?? '')?.match(channelLabelRegex)

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      onMouseLeave={handleDropdownClose}
    >
      <div ref={boxRef}>
        <DropdownButton autoFocus disableFocusRipple className={classes.dropdownBtn} iconClass={classes.iconClass} open={openDropdown} onClick={openDropdown ? handleDropdownClose : handleDropdownOpen}>
          <div className={clsx(commonClasses.flex1, commonClasses.alignItemsCenter, commonClasses.justifyContentStart)}>
            <TransferMethodIcon className={clsx(classes.transferIcon, 'selected')} optionKey={selectedOption?.key} transferMethod={selectedOption?.transferMethod} blockchain={selectedOption?.blockchain} />
            <Typography className={classes.selectedOption}>
              {customLabels[selectedOption?.blockchain ?? ''] ?? selectedOption?.tokenType}
            </Typography>

            {selectedOption && selectedOption?.blockchain !== 'Native' && (
              <TypographyLabel className={clsx(classes.selectOptionAlt, classes.ml0p5)} color="textSecondary">
                {labelRegexArr?.[1] ?? ''}
              </TypographyLabel>
            )}
          </div>
        </DropdownButton>

        <div className={clsx(classes.dropdownContainer, commonClasses.posRelative)}>
          <Paper
            className={clsx(classes.dropdown, { [classes.dropdownOpen]: openDropdown })}
            elevation={2}
            style={{
              height: openDropdown ? maxHeight : 0
            }}
          >
            {
              transferOptions.map((optionItem: OptionItem, index: number) => {
                const { title, channels } = optionItem
                const mtValue = index === 0 ? 0 : 1
                const isNative = channels.find((channel) => channel.blockchain === 'Native') // Check if title needs to be formatted for 'Native' blockchain
                const formattedTitle = isNative ? `Carbon (${title})` : title
                return (
                  optionItem.channels.length > 0 ? (
                    <div key={index} style={{ marginTop: mtValue }}>
                      <Typography color="textSecondary" className={classes.optionTitle}>
                        {formattedTitle}
                      </Typography>
                      {
                        optionItem.channels.map((option: TransferChannel, optionIndex: number) => {
                          const optionRegex = /^([a-z0-9 ]{1,}) \(([a-z0-9 ]{1,})\)$/i
                          const optionArr = option.label.match(optionRegex)
                          const optionLbl = optionArr?.[2] ?? ''
                          const isSelectedOption = selectedOption?.label === option.label

                          const checkOptionDenom = option?.additionalTokenInfo?.carbonRecognisedDenom ?? option.denom ?? ''
                          const legacyBalance = walletBalance.balances[checkOptionDenom]?.available // check for legacy balance
                          const groupTokenDetail = getGroupTokenDetails(groupTokenDetails, checkOptionDenom)
                          const groupTokenBalance = groupTokenDetail?.balances?.find((balance) => balance.denom === checkOptionDenom)?.available // check for balance in group
                          const totalWithdrawableBalance = bnOrZero(legacyBalance).plus(bnOrZero(groupTokenBalance))
                          const isDisabled = (
                            transferType === TransferType.Withdraw
                            && isTokenGroup
                            && totalWithdrawableBalance.lte(0) // when withdraw usd, disabled if 0 legacy balance and 0 balance in usd group
                            && !['Native', 'Carbon EVM'].includes(selectedOption?.blockchain ?? '') // tooltip should not appear for internal transfer and Carbon EVM
                          )
                          return (
                            <div key={`${option.key}-${option.blockchain}`} className={clsx(classes.optionItem, { firstItem: optionIndex === 0 })} onClick={onSelect(option)}>
                              <TransferMethodIcon className={clsx(classes.transferIcon, 'transfer')} optionKey={option?.key} transferMethod={option?.transferMethod} blockchain={option?.blockchain} />
                              <Typography className={clsx(classes.selectOption, { isSelectedOption })} variant="body1">
                                {option.blockchain && optionRegexOverride.includes(option.blockchain) ? option.label : optionLbl}
                              </Typography>
                              {isDisabled && (
                                <V2Tooltip
                                  classes={{ tooltip: classes.v2tooltip }}
                                  placement="right"
                                  title={(
                                    <TypographyLabel className={clsx(classes.tooltip, classes.tooltipLeftCell)}>There is not enough available in this network. Please select another network or token.</TypographyLabel>
                                  )}
                                >
                                  <div className={commonClasses.flexRow}>
                                    <SvgIcon component={CautionIcon} className={classes.cautionIcon} />
                                  </div>
                                </V2Tooltip>
                              )}
                            </div>
                          )
                        })
                      }
                      {index !== transferOptions.length - 1 && (
                        <Hidden mdDown>
                          <Divider className={classes.divider} />
                        </Hidden>
                      )}
                    </div>
                  ) : null
                )
              })
            }
          </Paper>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  assetIcon: {
    height: '2rem',
    width: '2rem',
    marginRight: theme.spacing(2),
  },
  iconClass: {
    border: 'none',
  },
  backdrop: {
    zIndex: 'unset',
  },
  dropdownBtn: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    padding: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  dropdownContainer: {
    border: '1px solid transparent',
  },
  dropdown: {
    ...StyleUtils.scrollBar(theme),
    transition: 'all 0.2s ease-in-out',
    zIndex: 2,
    padding: theme.spacing(1.75, 1.5),
    position: 'absolute',
    width: '100%',
    boxShadow: StyleUtils.dropShadow(theme),
    left: 0,
    height: 'auto',
    maxHeight: 0,
    opacity: 0,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.only('xs')]: {
    },
    overflowX: 'hidden',
    overflowY: 'scroll',
    scrollbarColor: theme.palette.scrollbar.thumb,
    '-webkit-overflow-scrolling': 'scroll',
  },
  dropdownOpen: {
    ...StyleUtils.dropdownAnimation(),
    maxHeight: '14rem',
  },
  firstGrid: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: '50%',
      width: '100%',
      paddingRight: theme.spacing(2),
    },
  },
  optionItem: {
    alignItems: 'center',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    padding: theme.spacing(0.75, 1, 1),
    '&:hover': {
      backgroundColor: theme.palette.background.active,
    },
    '&.firstItem': {
      marginTop: theme.spacing(0.75),
    },
  },
  optionTitle: {
    ...theme.typography.body4,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0, 0.5),
  },
  secondGrid: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: '50%',
      width: '100%',
      paddingLeft: theme.spacing(2),
    },
  },
  selectOption: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    '&.margin': {
      marginBottom: theme.spacing(0.5),
    },
    '&.isSelectedOption': {
      color: theme.palette.text.primary,
    },
  },
  selectedOption: {
    ...theme.typography.body3,
    '&.margin': {
      marginBottom: theme.spacing(0.5),
    },
  },
  transferIcon: {
    '&.selected': {
      height: 24,
      width: 24,
      marginRight: theme.spacing(1.75),
    },
    '&.transfer': {
      height: 24,
      width: 24,
    },
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  v2tooltip: {
    maxWidth: '12rem',
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(0, 0.75),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0.75),
    },
  },
  cautionIcon: {
    width: '0.75rem',
    margin: '0 0.25rem 0 0.375rem',
    '& path': {
      fill: 'url(#accentWarning)',
    },
    cursor: 'pointer',
  },
  tooltip: {
    ...theme.typography.body4,
    textAlign: 'left',
    cursor: 'pointer',
  },
  ml0p5: {
    marginLeft: theme.spacing(0.5),
  },
}))

export default TransferChannelSelect