import { StatsigClient } from "@statsig/js-client"
import { TypeUtils } from "carbon-js-sdk"
import { RecordOf } from "immutable"

export interface StatsigStateProps {
  readonly statsigClient?: StatsigClient
  readonly statsigExperiments?: TypeUtils.SimpleMap<StatsigExperiment>
}

export type StatsigState = RecordOf<StatsigStateProps>

export const StatsigActionTypes = {
  SET_STATSIG_CLIENT: '@statsig/SET_STATSIG_CLIENT',
  SET_STATSIG_EXPERIMENTS: '@statsig/SET_STATSIG_EXPERIMENTS',
}

export interface StatsigExperiment {
  experimentName: string,
  variables: TypeUtils.SimpleMap<boolean>,
}

export enum StatsigExperimentNames {
  MobileConnectMethod = 'mobile_connect_method'
}
