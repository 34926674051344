import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import TopCardLabel from "js/components/LiquidityPool/LiquidityPool/components/TopCardLabel"
import { useDimensions } from "js/hooks"
import { muiBreakpoints } from "js/theme"
import { useCommonStyles } from "js/utils"

const ManagePoolsFallback: React.FC = () => {
  const classes = useStyles()
  const dimensions = useDimensions()
  const commonClasses = useCommonStyles()

  const { isMobile } = React.useMemo(() => {
    return {
      isTablet: dimensions.windowSize.width >= muiBreakpoints.xs && dimensions.windowSize.width < muiBreakpoints.md,
      isMobile: dimensions.windowSize.width < muiBreakpoints.xs,
    }
  }, [dimensions.windowSize.width])


  return (
    <div className={classes.box}>
      <div className={clsx(commonClasses.flexRow, classes.topSection)}>
        <div className={clsx(classes.topBox, commonClasses.flexColumn, commonClasses.justifyContentStart)}>
          <TopCardLabel>
            <div className={clsx(commonClasses.flexColumn, classes.loadingSkeletonWrapper, 'userPositionValue')}>
              <LoadingSkeleton height={isMobile ? 16 : 20} />
              <LoadingSkeleton height={isMobile ? 16 : 20} />
            </div>
          </TopCardLabel>
        </div>
        <div className={clsx(classes.topBox, commonClasses.flexColumn, commonClasses.justifyContentStart)}>
          <TopCardLabel>
            <div className={clsx(commonClasses.flexColumn, classes.loadingSkeletonWrapper, 'unclaimedRewards')}>
              <LoadingSkeleton height={isMobile ? 16 : 20} />
              <LoadingSkeleton height={isMobile ? 16 : 20} />
            </div>
          </TopCardLabel>
        </div>
      </div>
      <div className={classes.tableContainer} style={{ minHeight: isMobile ? dimensions.otherPagesHeight.mobile - 100 : dimensions.otherPagesHeight.desktop - 216 }}>
        <LoadingSkeleton width={180} paddingBottom={48} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton paddingBottom={24} />
        <LoadingSkeleton />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    width: '100%',
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(4),
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
      marginBottom: theme.spacing(6),
    },
  },
  topSection: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 0,
    },
  },
  topBox: {
    width: '100%',
    border: 'none',
    marginBottom: '0.5rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      '&:first-child': {
        marginRight: '1rem',
      },
      '&:last-child': {
        marginLeft: '1rem',
      },
    },
  },
  tableContainer: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(1.5),
    },
  },
  loadingSkeletonWrapper: {
    gap: '8px',
    height: '76px',
    '&.userPositionValue': {
      [theme.breakpoints.down('md')]: {
        height: '38px'
      },
    },
    '&.unclaimedRewards': {
      [theme.breakpoints.down('md')]: {
        height: '60px'
      },
    },
  },
}))

export default ManagePoolsFallback