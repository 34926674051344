import { Divider, Theme, makeStyles, Paper } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

import { SearchInput } from 'js/components/Common'
import DropDownIcon from 'js/components/Common/DropDownIcon'
import TextButton from 'js/components/Common/TextButton'
import { StyleUtils } from 'js/utils/styles'

export interface DropdownProps extends React.DOMAttributes<any> {
  label: string | React.ReactNode
  dropdownOpen: boolean
  paperRef?: React.RefObject<HTMLDivElement>
  hasDropdownIcon?: boolean
  rightDropDownMenu?: boolean,
  leftUpperMenu?: boolean,
  selected?: boolean
  children?: any
  className?: any // dropdownContent className
  iconClassName?: any // dropdown icon className
  labelClassName?: any // dropdown label className
  dropdownContentClass?: string
  dropdownChildrenClass?: string
  containerClass?: string
  newLblClass?: string
  disabled?: boolean
  extraLength?: number
  givenHeight?: number
  overrideLength?: boolean
  additionalHeight?: any
  multiSelect?: boolean
  onResetClick?: () => void
  search?: string
  setSearch?: (search: string) => void
  onDropdownLabelClick?: (event?: any) => void
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const classes = useStyles()

  const {
    label, paperRef, selected = false, children, className, labelClassName, iconClassName,
    dropdownOpen, hasDropdownIcon = true, rightDropDownMenu = false, leftUpperMenu = false, containerClass, newLblClass,
    disabled = false, extraLength, givenHeight, overrideLength, onTouchEnd, additionalHeight = 0, multiSelect = false, onResetClick, search, setSearch, onDropdownLabelClick, dropdownContentClass, dropdownChildrenClass, ...rest
  } = props

  const dropdownDivRef = useRef<HTMLDivElement | null>(null)
  const [dropdownHeight, setDropdownHeight] = useState<number | 'auto'>('auto')


  useEffect(() => {
    if (dropdownOpen && dropdownDivRef.current) {
      const height = dropdownDivRef.current.offsetHeight
      const finalDropdownHeight = height + additionalHeight
      setDropdownHeight(finalDropdownHeight)
    }
    // eslint-disable-next-line
  }, [dropdownOpen])

  return (
			<div
				role="presentation"
				className={clsx(classes.dropdown, labelClassName, {
					selected,
					dropdownOpen,
				})}
				{...rest}
			>
				<div
					className={clsx(classes.labelContainer, containerClass)}
					onTouchEnd={onTouchEnd}
          onClick={onDropdownLabelClick}
				>
					<div className={clsx(classes.label, newLblClass)}>{label}</div>
					{hasDropdownIcon && (
						<div className={clsx(classes.dropDownIconContainer, iconClassName)}>
							<DropDownIcon open={dropdownOpen} />
						</div>
					)}
					<div className={classes.bottomHighLight} />
				</div>

      <Paper
        className={
          disabled
            ? classes.disabled
            : clsx(classes.dropdownContent, dropdownContentClass, multiSelect && classes.multiSelectDropdownContent,
              {
                rightDropDownMenu,
                [classes.dropdownOpen]: dropdownOpen,
                overrideLength,
                leftUpperMenu,
              }, className)
        }
        style={{ height: dropdownOpen ? dropdownHeight : '0' }}
      >
        <div ref={dropdownDivRef} className={classes.dropdownDiv}>
          {multiSelect && (
            <div className={classes.multiDropdownHeader}>
              <SearchInput
                input={search!}
                setInput={setSearch}
                placeholder="Search"
                className={classes.searchInput}
                classes={{ input: classes.searchLabel }}
              />
              <TextButton 
                label="Reset"
                onClick={onResetClick}
                className={classes.resetButton}
              />
              <Divider className={classes.divider} />
            </div>
          )}
          <div className={clsx(multiSelect && classes.multiDropdownChildrenDiv, dropdownChildrenClass)}>
            {children}
          </div>
        </div>
      </Paper>
    </div>
	)
} 

const useStyles = makeStyles((theme: Theme) => ({
  multiDropdownHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  searchInput: {
    margin: theme.spacing(2, 2, 0.5, 2),
    height: '32px'
  },
  searchLabel: {
    ...theme.typography.body4,
    fontSize: '11px !important',
    lineHeight: '13.2px'
  },
  resetButton: {
    padding: theme.spacing(1, 0, 1, 2),
  },
  dropdown: {
    position: 'relative',
    '&.selected': {
      '& $bottomHighLight': {
        display: 'block',
      },
    },
    '&.dropdownOpen': {
      border: 'none',
      '& $dropdownContent': {
        display: 'flex',
      },
    },
  },
  bottomHighLight: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    bottom: 0,
    display: 'none',
  },
  multiSelectDropdownContent: {
    minWidth: '230px !important',
  },
  multiDropdownChildrenDiv: {
    ...StyleUtils.scrollBar(theme),
    overflowY: 'auto',
    maxHeight: '14rem',
  },
  dropdownContent: {
    transition: 'all 0.2s ease-in-out',
    opacity: 0,
    overflow: 'hidden',
    border: 'none',
    boxShadow: StyleUtils.dropShadow(theme),
    position: 'absolute',
    zIndex: 2,
    flexDirection: 'column',
    height: 0,
    maxHeight: 0,
    '&.rightDropDownMenu': {
      ...StyleUtils.scrollBar(theme),
      right: 0,
    },
    '&.leftUpperMenu': {
      ...StyleUtils.scrollBar(theme),
      left: '-5%',
      bottom: '100%',
    },
  },
  dropdownOpen: {
    ...StyleUtils.dropdownAnimation(),
    height: 'auto',
    maxHeight: 'unset',
    overflow: 'unset',
    '&.leftUpperMenu': {
      maxHeight: 'calc(100vh - 120px)',
      overflowX: 'unset',
      overflowY: 'auto',
    },
  },
  divider: {
    margin: '0 8px',
  },
  labelContainer: {
    width: 'fit-content',
    height: '100%',
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    cursor: 'pointer',
    position: 'relative',
  },
  label: {
    ...theme.typography.header2,
    color: 'inherit',
    alignContent: 'center'
  },
  dropDownIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
  },
  disabled: {
    display: 'none',
  },
}))

export default Dropdown
