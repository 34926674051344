import React, { ReactText } from 'react'
import { cssTransition, toast, ToastContent, ToastOptions } from 'react-toastify'

import { hydrogenPendingTxLink, ViewOnExplorerLink, ViewTransactionLink } from 'js/constants/externalLinks'
import { DISMISS_TOAST_KEY, FeatureType } from 'js/constants/notification'
import { store } from 'js/state'
import { onCloseToast, queueToast } from 'js/state/modules/loadingTask/actions'
import { uuidv4 } from 'js/utils'
import { assignDismissToastHandler, assignOrderToastHandler, assignSagaToastHandler, assignToastHandler, assignTxLinkToastHandler, assignTxToastHandler, customToast, getNotificationConfig, getOrderNotificationType, getTxNotificationConfig, NotificationProps, OrderNotificationProps } from 'js/utils/notifications'

import DismissNotification from './DismissNotification'
import Notification from './Notification'
import OrderNotification from './OrderNotification'
import TransactionNotification from './TransactionNotification'

const fadeInAndOut =  cssTransition({
  enter: 'fade-in',
  exit: 'fade-out',
  collapseDuration: 150,
  duration: 300
})

assignDismissToastHandler((
  options: NotificationProps,
  userConfig: ToastOptions | undefined = {},
): ReactText => {
  const dispatch = store.dispatch
  const notifConfigs = getNotificationConfig(userConfig, options.type)
  const toastUuid = notifConfigs.toastId ?? uuidv4()
  const dismissToastId = `${toastUuid}-${DISMISS_TOAST_KEY}`
  return toast(
    <DismissNotification {...options} dismissToastId={dismissToastId} />,
    {
      ...notifConfigs,
      closeButton: false,
      hideProgressBar: true,
      autoClose: false, 
      className: 'dismissToastWrapper',
      bodyClassName: 'dismissToastBody',
      toastId: dismissToastId,
      onClose(props) {
        notifConfigs.onClose?.(props)
        dispatch(onCloseToast(dismissToastId))
      },
      transition: fadeInAndOut
    },
  )
})

assignToastHandler((
  options: NotificationProps,
  userConfig: ToastOptions | undefined = {},
): ReactText => {
  const notifConfigs = getNotificationConfig(userConfig, options.type)
  return toast(<Notification {...options} />, notifConfigs)
})

assignSagaToastHandler((props): ReactText => {
  const { userConfig } = props
  const dispatch = store.dispatch
  const toastId = userConfig?.toastId?.toString() ?? uuidv4()
  const updatedToastOptions: ToastOptions = {
    ...userConfig,
    toastId: toastId,
    onClose(props) {
      userConfig?.onClose?.(props)
      dispatch(onCloseToast(toastId))
    },
  }
  const updated = { ...props, userConfig: updatedToastOptions }

  dispatch(queueToast(updated))
  return toastId
})

assignTxToastHandler((
  options: NotificationProps,
  userConfig: ToastOptions | undefined = {},
): ReactText => {
  const { content, customConfig } = getCustomTxToastContentConfig(options, userConfig)
  return toast(content, customConfig)
})

export function getCustomTxToastContentConfig(
  options: NotificationProps,
  config: ToastOptions | undefined = {},
): { content: ToastContent, customConfig: ToastOptions } {
  return {
    content: <TransactionNotification {...options} />,
    customConfig: getTxNotificationConfig(config, options.type),
  }
}

assignOrderToastHandler((
  options: OrderNotificationProps,
  userConfig: ToastOptions | undefined = {},
): ReactText => {
  const { content, customConfig } = getCustomOrderToastContentConfig(options, userConfig)
  return toast(content, customConfig)
})

function getCustomOrderToastContentConfig(
  options: OrderNotificationProps,
  config: ToastOptions | undefined = {},
): { content: ToastContent, customConfig: ToastOptions } {
  const notificationType = getOrderNotificationType(options.scenario, options.cancelReason)
  return {
    content: <OrderNotification {...options} />,
    customConfig: getNotificationConfig(config, notificationType),
  }
}

assignTxLinkToastHandler((
  _options: NotificationProps,
  txExplorerLink: string,
  userConfig: ToastOptions | undefined = {},
): ReactText => {
  const options: NotificationProps = {
    ..._options,
    link: _options.link ?? {
      ...ViewOnExplorerLink,
      href: txExplorerLink,
    },
  }
  return toast(
    <Notification {...options} />,
    getNotificationConfig(userConfig, _options.type),
  )
})

export const withdrawNotificationToast = (txHash: string) => {
  customToast.info({
    featureType: FeatureType.WALLET,
    title: 'Withdraw Initiated',
    message: 'Your funds will be withdrawn shortly.',
    link: {
      ...ViewTransactionLink,
      href: hydrogenPendingTxLink(txHash),
    },
  })
}
