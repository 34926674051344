import { makeStyles, Theme } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

export const LiteContainer = ({ children }: PropsWithChildren<{}>) => {
  const classes = containerStyles()
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}
const containerStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '3rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  loader: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
}))
