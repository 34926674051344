import { Divider, Paper, Typography, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { ProviderAgent } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DropDownIcon, SvgIcon } from 'js/components/Common'
import Button from 'js/components/Common/Button'
import MenuListItems, { MenuItem } from 'js/components/Common/MenuListItems'
import SidebarHeader from 'js/components/Common/Sidebar/SidebarHeader'
import { FUTURES_QUOTES } from 'js/constants/assets'
import Paths from 'js/constants/paths'
import { EvtCategory, useDimensions, useDropdownHandler, useEventTrackers, useGetRouteMatch } from 'js/hooks'
import useCurrentWalletConnector from 'js/hooks/useCurrentWalletConnector'
import useDisconnectWallet from 'js/hooks/useDisconnectWallet'
import { history } from 'js/state'
import { getSocialLoginInfo } from 'js/state/modules/account/selectors'
import { setSubPage, setWalletDropdownOpen, setWalletToggle } from 'js/state/modules/app/actions'
import { getCarbonSDK, getIsLegacyLogin, getNet, getSDKWallet } from 'js/state/modules/app/selectors'
import { Page } from 'js/state/modules/app/types'
import { getUserDemexPoints } from 'js/state/modules/competition/selectors'
import { checkSelectToken } from 'js/state/modules/walletBalance/actions'
import { Web3WalletIcons } from 'js/utils'
import { formatDemexPoints } from 'js/utils/demexPoints'
import { getTokenName, truncateStr } from 'js/utils/strings'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'
import { getSocialIcon } from 'js/utils/web3auth'
import { WalletType } from 'types/wallet'

import { ReactComponent as CautionIcon } from 'assets/Caution.svg'
import { ReactComponent as ExitToApp } from 'assets/CloseCircle.svg'
import { ReactComponent as DemexPoints } from 'assets/DemexPoints.svg'
import { ReactComponent as Deposit } from 'assets/Deposit.svg'
import { ReactComponent as Referral } from 'assets/Referral.svg'
import { ReactComponent as PreferencesIcon } from 'assets/Settings.svg'
import { ReactComponent as UserIcon } from 'assets/User.svg'
import { ReactComponent as AccountBalanceWallet } from 'assets/WalletOutline.svg'
import { EncryptedKeyIcon, KeplrIcon, LeapIcon, LedgerIcon, MetaMaskIcon, Web3Icon } from 'assets/login'


interface Props {
}

const YourWalletDropdown: React.FC<Props> = (props: Props) => {
  const { windowSize } = useDimensions()
  const currentWeb3Wallet = useCurrentWalletConnector()

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dispatch = useDispatch()
  const eventTrackers = useEventTrackers()
  const {
    isInternalTransfer, isCarbonEVMTransfer, isUserProfile, isWalletBalance, isCrosschainTransfer, isOrderHistory, isTradeHistory, isReferrals, isDepositUSD,
    isAddressManagement, isDelegateAccounts, isDemexPoints, isUserOverview, isDeposit, isWithdraw
  } = useGetRouteMatch()
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const socialLoginInfo = useSelector(getSocialLoginInfo)

  const net = useSelector(getNet)
  const sdk = useSelector(getCarbonSDK)
  const wallet = useSelector(getSDKWallet)
  const isLegacyLogin = useSelector(getIsLegacyLogin)
  const userDemexPoints = useSelector(getUserDemexPoints)

  const disconnect = useDisconnectWallet(sdk)

  const address = wallet?.bech32Address ?? ''
  const abbreviatedAddress = truncateStr(address, 5, 5)

  const futuresDenom = FUTURES_QUOTES[net]?.[0]
  const futuresSymbol = getTokenName(futuresDenom ?? '', sdk?.token, net)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [dropdownHeight, setDropdownHeight] = useState<number | 'auto'>('auto')
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)

  const isMobileAccountPageActive = isCrosschainTransfer || isInternalTransfer || isCarbonEVMTransfer || isOrderHistory ||
    isTradeHistory || isAddressManagement || isDelegateAccounts || isUserOverview || isWithdraw
  const isDesktopAccountPageActive = isMobileAccountPageActive || isDepositUSD || isDeposit

  useEffect(() => {
    if (isDropdownOpen && contentRef.current) {
      const height = (contentRef.current as HTMLDivElement).offsetHeight

      if (windowSize.height * 0.8 < height) {
        setDropdownHeight(windowSize.height * 0.8 + 25)
      } else {
        setDropdownHeight(height + 25)
      }
    }
  }, [isDropdownOpen, windowSize])

  const handleNavClick = useCallback((route: string | undefined) => {
    if (!route) return
    history.push(route)
    handleDropdownClose()
  }, [handleDropdownClose])

  const handleDepositClick = useCallback((evt: React.MouseEvent) => {
    const newSelect = sdk?.token.tokenForDenom(futuresDenom ?? '')
    if (!newSelect || !futuresSymbol) return

    handleDropdownClose()
    eventTrackers.sendEvent(EvtCategory.Profile, 'deposit', {source: 'navbar'})
    dispatch(checkSelectToken(newSelect))
    history.push(`${Paths.Account.Deposit}/${newSelect.denom}`)
  }, [sdk?.token, futuresDenom, futuresSymbol, handleDropdownClose, eventTrackers, dispatch])

  const demexPointsLabel = React.useMemo(() => {
    return <React.Fragment>
      Demex Points <span className={classes.demexPoints}>
        <span className={classes.btnInnerSpan}>
          <span className={classes.btnContents}>
            <span className={classes.pointsText}>{formatDemexPoints(bnOrZero(userDemexPoints?.points))}&nbsp;PTS</span>
          </span>
        </span>
      </span>
    </React.Fragment>
  }, [userDemexPoints, classes.demexPoints, classes.btnInnerSpan, classes.btnContents, classes.pointsText])

  const navItems = React.useMemo((): MenuItem[] => {
    const initNavItems: MenuItem[] = [{
      active: isWidthSmDown ? isMobileAccountPageActive : isDesktopAccountPageActive,
      label: 'Account',
      onClick: () => handleNavClick(Paths.Account.Overview),
      startIcon: UserIcon,
      customClasses: { startIcon: classes.startIcon },
      startIconType: 'fill',
      key: 'account',
    }]
    if (isWidthSmDown) {
      initNavItems.push({
        active: isDepositUSD,
        key: 'deposit-usd',
        label: 'Deposit Stablecoins',
        startIcon: Deposit,
        customClasses: { startIcon: classes.startIcon },
        startIconType: 'fill',
        onClick: handleDepositClick,
      })
    }
    let navItems = initNavItems.concat([{
      active: isWalletBalance && !isDesktopAccountPageActive,
      label: 'Balances',
      onClick: () => handleNavClick(Paths.Account.Balance),
      startIcon: AccountBalanceWallet,
      customClasses: { startIcon: classes.startIcon },
      startIconType: 'fill',
      key: 'wallet-balance',
    }, {
      active: isReferrals,
      label: 'Referrals',
      onClick: () => {
        handleNavClick(Paths.Rewards.Referrals)
        eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_referrals', {source: 'dropdown'})
      },
      startIcon: Referral,
      customClasses: { startIcon: classes.startIcon },
      startIconType: 'fill',
      key: 'referrals',
    }, {
      active: isDemexPoints,
      label: demexPointsLabel,
      onClick: () => {
        handleNavClick(Paths.Rewards.DemexPoints.Main)
        eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_demex_points', {source: 'dropdown'})
      },
      startIcon: DemexPoints,
      customClasses: { startIcon: classes.startIcon },
      startIconType: 'fill',
      key: 'demex-points',
    },
    {
      active: isUserProfile,
      key: 'user-profile',
      label: 'Preferences',
      startIcon: PreferencesIcon,
      customClasses: { startIcon: classes.startIcon },
      startIconType: 'stroke',
      onClick: () => handleNavClick(Paths.Account.Profile),
    }])

    return navItems
  }, [classes.startIcon, isUserProfile, demexPointsLabel, handleDepositClick, handleNavClick, isMobileAccountPageActive, isDesktopAccountPageActive, isWalletBalance, isReferrals, isDepositUSD, isDemexPoints, isWidthSmDown, eventTrackers])

  const handleToggle = () => {
    if (isDropdownOpen) {
      handleDropdownClose()
    } else {
      handleDropdownOpen()
    }
    dispatch(setWalletDropdownOpen())
  }

  const onMigrateAccount = () => {
    if (!isLegacyLogin) return
    handleDropdownClose()
    dispatch(setWalletToggle(true))
    dispatch(setSubPage(Page.Metamask))
  }

  const {WalletIcon, type} = useMemo(() => {
    if (sdk?.wallet?.providerAgent === ProviderAgent.Ledger) {
      return {WalletIcon: LedgerIcon, type: WalletType.Ledger}
    }
    if (sdk?.wallet?.providerAgent === ProviderAgent.KeplrExtension) {
      return {WalletIcon: KeplrIcon, type: WalletType.Keplr}
    }
    if (sdk?.wallet?.providerAgent === ProviderAgent.LeapExtension) {
      return {WalletIcon: LeapIcon, type: WalletType.Leap}
    }
    if (sdk?.wallet?.providerAgent === ProviderAgent.Web3Auth) {
      return {WalletIcon: getSocialIcon(socialLoginInfo?.typeOfLogin) ?? EncryptedKeyIcon, type: WalletType.Web3Auth}
    }
    if (isLegacyLogin) {
      return {WalletIcon: CautionIcon, type: WalletType.LegacyMetamask}
    }
    if (sdk?.wallet?.providerAgent === ProviderAgent.MetamaskExtension) {
      return {WalletIcon: MetaMaskIcon, type: WalletType.Metamask}
    }
    if (currentWeb3Wallet?.type === WalletType.WalletConnect) {
      return {WalletIcon: Web3Icon, type: WalletType.WalletConnect}
    }
    return {WalletIcon: EncryptedKeyIcon, type: WalletType.EncryptedKey}
  }, [sdk?.wallet?.providerAgent, isLegacyLogin, currentWeb3Wallet?.type, socialLoginInfo?.typeOfLogin])

  const handleSignOut = async () => {
    try {
      await disconnect()
      eventTrackers.sendEvent(EvtCategory.Wallet, 'wallet_disconnect_tap', {wallet: type, success: 'true'})
    } catch (err) {
      console.error(err)
      eventTrackers.sendEvent(EvtCategory.Wallet, 'wallet_disconnect_tap', {wallet: type, success: 'false'})
    } finally {
      handleDropdownClose()
    }
  }

  const disconnectItems: MenuItem[] = [{
    key: 'disconnect-wallet',
    label: 'Disconnect',
    onClick: handleSignOut,
    startIcon: ExitToApp,
    startIconType: 'stroke',
  }]

  return (
    <div className={clsx(commonClasses.posRelative, commonClasses.fullHeight)} onMouseLeave={handleDropdownClose}>
      <div
        className={classes.dropdownLabel}
        onClick={handleToggle}
      >
        <div className={classes.labelContainer}>
          {currentWeb3Wallet && (currentWeb3Wallet.icon || Web3WalletIcons[currentWeb3Wallet.id]) ? (
            <img src={currentWeb3Wallet.icon || Web3WalletIcons[currentWeb3Wallet.id]} className={classes.walletIcon} alt={currentWeb3Wallet.name} />
          ) : (
            <SvgIcon className={clsx(classes.walletIcon, { ledger: sdk?.wallet?.isLedgerSigner(), legacy: isLegacyLogin })} component={WalletIcon} />
          )}
          <span>
            {abbreviatedAddress}
          </span>
          {!isWidthSmDown && <DropDownIcon className={classes.walletDropdownDesktop} open={isDropdownOpen} />}
        </div>
      </div>
      <div className={clsx(classes.dropdownContainer, commonClasses.posAbsolute)}>
        <Paper
          className={clsx(classes.dropdown, { [classes.dropdownOpen]: isDropdownOpen })}
          elevation={2}
          style={{ height: isDropdownOpen ? dropdownHeight : '0' }}
        >
          <div className={classes.dropdownContents}>
            <div ref={contentRef}>
              {!!wallet?.bech32Address && isLegacyLogin && (
                <Button
                  className={clsx(classes.topBtn)}
                  baseButtonVariant="text"
                  startIcon={(
                    <SvgIcon component={CautionIcon} className={classes.migrateSvgIcon} />
                  )}
                  onClick={onMigrateAccount}
                >
                  <Typography className={classes.warningText}>Migrate Account</Typography>
                </Button>
              )}
              <SidebarHeader />
              <div className={classes.marginTop1}>
                <Divider />
              </div>
              <MenuListItems
                items={navItems}
                menuListClasses={{ root: classes.menuList }}
                size="large"
                menuClass={classes.menuClass}
              />
              <div className={classes.marginTop1}>
                <Divider />
              </div>
              <MenuListItems
                items={disconnectItems}
                menuListClasses={{ root: classes.menuList }}
                size="large"
                menuClass={classes.menuClass}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  walletIcon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
    borderRadius: 5,
    '&.ledger': {
      fill: 'currentColor',
    },
    '&.legacy': {
      '& path': {
        fill: 'url(#accentWarning)',
      },
    },
  },
  dropdownContainer: {
    bottom: 0,
    right: `-${theme.spacing(0.25)}px`,
    position: 'absolute',
    width: '17.5rem',
    [theme.breakpoints.down('sm')]: {
      right: `0.8rem`
    }
  },
  copyBox: {
    width: '100%',
    display: 'flex',
  },
  dropdown: {
    ...StyleUtils.scrollBar(theme),
    transition: 'all 0.2s ease-out',
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    borderRadius: 4,
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    maxHeight: 0,
    '&.hide': {
      opacity: 0,
      height: 0,
    },
    '@media (max-height: 650px)': {
      height: '75vh',
    },
  },
  dropdownContents: {
    margin: theme.spacing(1.5),
  },
  dropdownOpen: {
    ...StyleUtils.dropdownAnimation(),
    overflow: 'unset',
    height: 'auto',
    maxHeight: 'unset',
  },
  dropdownLabel: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '100%',
  },
  labelContainer: {
    ...theme.typography.body3,
    alignItems: 'center',
    padding: theme.spacing(0.5, 1.25),
    display: 'flex',
    color: theme.palette.text.secondary,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.tertiary,
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(1, 1.5),
      borderRadius: theme.spacing(0.5),
      fontSize: '11px',
    },
  },
  label: {
    ...theme.typography.button,
    textTransform: 'initial',
    color: '#ffffff',
    opacity: 1,
  },
  menuList: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '& > li': {
      padding: theme.spacing(1.5, 1),
    },
  },
  pulseAlt: {
    marginLeft: theme.spacing(1.5),
  },
  topBtn: {
    width: '100%',
    justifyContent: 'flex-start',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 1, 1, 1.8),
  },
  warningText: {
    ...theme.typography.body2,
    background: StyleUtils.accentWarning,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    fontSize: '15px'
  },
  migrateSvgIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    '& path': {
      fill: 'url(#accentWarning)',
    },
  },
  copyBtn: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    minHeight: '2.75rem',
    padding: theme.spacing(1.5),
    display: 'flex',
    whiteSpace: 'nowrap',
    '&.copied': {
      backgroundColor: theme.palette.background.highlight,
    },
    '& span>span': {
      marginRight: 0,
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  copyBtnFlex: {
    flex: 1,
  },
  copySvgIcon: {
    width: '1.125rem',
    height: '1.125rem',
    marginRight: '0.625rem',
    '& path': {
      stroke: theme.palette.text.secondary,
    },
  },
  linkBtn: {
    padding: 0,
  },
  extLinkSvgIcon: {
    width: '0.75rem',
    height: '0.75rem',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  button: {
    ...theme.typography.body3,
    alignSelf: 'center',
    width: 84,
    opacity: 1,
    fontWeight: 700,
    background: StyleUtils.primaryBranding,
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    padding: '0 12px',
    '&.baseButtonContained': {
      ...theme.typography.body3,
      fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('sm')]: {
      '&.regular': {
        ...theme.typography.body3,
        fontWeight: 700,
        height: '2rem',
      },
    },
    '@media (max-width: 320px)': {
      width: 72,
      padding: theme.spacing(1),
      margin: theme.spacing(0, 0.75),
    },
  },
  walletDropdown: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: '0.6rem',
      '& path': {
        stroke: '#fff',
      },
    },
  },
  walletDropdownDesktop: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  menuClass: {
    fontSize: '15px',
  },
  startIcon: {
    marginTop: theme.spacing(0.25),
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
  demexPoints: {
    background: StyleUtils.primaryBranding,
    border: 'none',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    height: 'auto',
    marginRight: theme.spacing(0.5),
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  btnContents: {
    alignItems: 'center',
    display: 'flex',
  },
  btnInnerSpan: {
    ...theme.typography.body3,
    margin: 1,
    background: 'linear-gradient(270deg, rgba(72, 43, 255, 0.24) 0%, rgba(0, 122, 255, 0.24) 100%)',
    backgroundColor: theme.palette.background.primary,
    borderRadius: theme.spacing(1) - 1, // for iRadius to match outer bRadius
    color: theme.palette.text.primary,
    padding: theme.spacing(.75, 1),
    display: 'block',
    height: '100%',
  },
}))

export default YourWalletDropdown
