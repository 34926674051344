import { Chain, getDefaultConfig } from '@rainbow-me/rainbowkit'
import { CarbonEvmNativeCurrency } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { http } from 'wagmi'
import {
  arbitrum,
  bsc,
  evmos,
  mainnet,
  okc,
  optimism,
  polygon
} from 'wagmi/chains'

import { CARBON_EVM_DEVNET, CARBON_EVM_MAINNET, CARBON_EVM_TESTNET, CARBON_EVM_LOCALHOST } from 'js/constants/chainConfig'
import { StaticLinks } from 'js/constants/externalLinks'

import { Wallets } from './wallets'

const blockExplorersUrl = StaticLinks.EvmScan
const mainnetRPCUrls = { http: CARBON_EVM_MAINNET.rpcUrls }
const testnetRpcUrls = { http: CARBON_EVM_TESTNET.rpcUrls }
const devnetRpcUrls = { http: CARBON_EVM_DEVNET.rpcUrls }
const localHostRpcUrls = { http: CARBON_EVM_LOCALHOST.rpcUrls }

export const carbonEVMChain: Chain = {
  id: CARBON_EVM_MAINNET.chainId,
  name: CARBON_EVM_MAINNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: mainnetRPCUrls,
    default: mainnetRPCUrls,
  },
  blockExplorers: {
    default: { name: 'Mainnet EVM', url: blockExplorersUrl },
  },
}

export const carbonEVMChainTestNet: Chain = {
  id: CARBON_EVM_TESTNET.chainId,
  name: CARBON_EVM_TESTNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: testnetRpcUrls,
    default: testnetRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Testnet EVM', url: blockExplorersUrl },
  },
  testnet: true
}

export const carbonEVMChainDevNet: Chain = {
  id: CARBON_EVM_DEVNET.chainId,
  name: CARBON_EVM_DEVNET.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: devnetRpcUrls,
    default: devnetRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Devnet EVM', url: blockExplorersUrl },
  },
}

export const carbonEVMChainLocalhost: Chain = {
  id: CARBON_EVM_LOCALHOST.chainId,
  name: CARBON_EVM_LOCALHOST.chainName,
  nativeCurrency: CarbonEvmNativeCurrency,
  rpcUrls: {
    public: localHostRpcUrls,
    default: localHostRpcUrls,
  },
  blockExplorers: {
    default: { name: 'Localhost EVM', url: blockExplorersUrl },
  },
}

const supportedChains: readonly [Chain, ...Chain[]] = [
  carbonEVMChain,
  carbonEVMChainTestNet,
  carbonEVMChainDevNet,
  carbonEVMChainLocalhost,
  mainnet,
  polygon,
  optimism,
  arbitrum,
  bsc,
  evmos,
  okc
]

const transports = {
  [mainnet.id]: http(),
  [polygon.id]: http(),
  [optimism.id]: http(),
  [arbitrum.id]: http(),
  [bsc.id]: http(),
  [evmos.id]: http(),
  [okc.id]: http(),
  [carbonEVMChain.id]: http(),
  [carbonEVMChainTestNet.id]: http(),
  [carbonEVMChainDevNet.id]: http(),
  [carbonEVMChainLocalhost.id]: http(),
}

export const wagmiConfig = getDefaultConfig({
  appName: 'app.dem.exchange',
  projectId: 'c743c2a52bb5e1fd97863489e0b86dc0',
  wallets: Wallets,
  chains: supportedChains,
  transports,
  ssr: true,
  multiInjectedProviderDiscovery: true,
})

export const mapChainNames: Record<string, string> = {
  'Arbitrum One': 'Arbitrum',
  'BNB Smart Chain': 'Binance Smart Chain'
}

export const supportedChainNames = supportedChains.map(c => mapChainNames[c.name] ?? c.name )
