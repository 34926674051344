import React from 'react'

import TextButton from 'js/components/Common/TextButton'
import { StaticLinks } from 'js/constants/externalLinks'
import { SimpleMap } from 'js/utils'

interface ConnectionError {
  nodeError?: ErrorMessage,
  thirdPartyError?: ErrorMessage
}
interface ErrorMessage {
  navText: string,
  title: string,
  subtitle: string,
  text?: React.ReactNode,
}

export const connectionErrorMessages: SimpleMap<ConnectionError> = {
  1: {
    thirdPartyError: {
      navText: 'No Internet Connection',
      title: 'No Internet',
      subtitle: 'Please connect to the internet and refresh Demex to try again.',
    },
  },
  2: {
    nodeError: {
      navText: 'Connection Issue',
      title: 'Unable to connect to Carbon node',
      subtitle: 'This selected node is unreachable. Please change your selected Carbon API node below.',
    },
  },
  3: {
    nodeError: {
      navText: 'Connection Issue',
      title: 'Unable to connect to Carbon blockchain',
      subtitle: 'Demex is currently unable to connect to the Carbon chain. This may be due to offline API nodes or a temporary chain halt.',
      text: (
        <React.Fragment>
          In the meantime, transactions and deposits are paused.<br />
          Your current deposits and balances are safe, and you can resume transactions once connectivity is restored.<br />
          Please check <TextButton href={StaticLinks.Socials.DemexTwitter} target='_blank' label="X" isInline/> or <TextButton href={StaticLinks.Socials.TelegramANN} target='_blank' label="Telegram" isInline/> for updates.
        </React.Fragment>
      ),
    },
  },
  4: {
    nodeError: {
      navText: 'Degraded Connection',
      title: 'Unable to connect to Carbon RPC',
      subtitle: 'This node is experiencing degraded performance. Demex is currently unable to load chain data or broadcast transactions.',
      text: <React.Fragment>Please change your selected Carbon API node below.</React.Fragment>,
    },
  },
  5: {
    nodeError: {
      navText: 'Degraded Connection',
      title: 'Unable to connect to Carbon Websocket',
      subtitle: 'This node is experiencing degraded performance. Demex is currently unable to load various trading and blockchain related data.',
      text: (
        <React.Fragment>
          Please try the following:
          <ul>
            <li>Change your selected Carbon API node below</li>
            <li>Disable your adblocker</li>
            <li>Turn off your VPN</li>
            <li>Switch your browser to Chrome</li>
          </ul>
          Please check <TextButton href={StaticLinks.Socials.DemexTwitter} target='_blank' label="X" isInline/> or <TextButton href={StaticLinks.Socials.TelegramANN} target='_blank' label="Telegram" isInline/> for updates.
        </React.Fragment>
      ),
    },
  },
  6: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to 3rd-party price feed',
      subtitle: 'Notional USD values may be missing. This does not affect trading or your PnL.',
      text: (
        <React.Fragment>
          Please try the following:
          <ul>
            <li>Change your selected Carbon API node below</li>
            <li>Disable your adblocker</li>
          </ul>
        </React.Fragment>
      ),
    },
  },
  7: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to Hydrogen API',
      subtitle: 'Crosschain transaction history may not be updated. This does not affect deposits and withdrawals.',
      text: <React.Fragment>Your balances and transaction history will be updated once connectivity is restored.</React.Fragment>,
    },
  },
  8: {
    thirdPartyError: {
      navText: 'Issue with Insights API',
      title: 'Unable to fetch leaderboard data',
      subtitle: 'Leaderboard data may be missing or outdated.',
      text: <React.Fragment>Data will reappear when connectivity is restored.</React.Fragment>,
    },
  },
  9: {
    thirdPartyError: {
      navText: 'Issue with Referral API',
      title: 'Unable to fetch referral data',
      subtitle: 'Referral data and links may be degraded at the moment.',
      text: <React.Fragment>Please try again later.</React.Fragment>,
    },
  },
  10: {
    thirdPartyError: {
      navText: 'Issue with 3rd-Party API',
      title: 'Unable to connect to GitHub',
      subtitle: 'Market configuration may be outdated.',
      text: <React.Fragment>Configuration will be updated when connectivity is restored.</React.Fragment>,
    },
  },
}
