import { Theme } from '@material-ui/core'

import { FlavorableOverrides } from './types'

const MuiCssBaseline: FlavorableOverrides = (theme: Theme) => ({
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'unset',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.5)',
        opacity: 1,
        boxShadow: '0 0 0 0 currentColor',
      },
      '80%': {
        opacity: 0.6,
        boxShadow: '0 0 0 10px currentColor',
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 0,
        boxShadow: '0 0 0 10px currentColor',
      },
    },
    '@keyframes rotating': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    '@keyframes fadeOut': {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
    'input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      appearance: 'textfield',
      '-moz-appearance': 'textfield',
    },
    div: {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
      '-webkit-font-smoothing': 'subpixel-antialiased',
      backgroundColor: '#111213',
    },
    code: {
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
    },
    '.Toastify__toast': {
      padding: 0,
      display: 'block',
    },
    '.Toastify__toast button.MuiIconButton-root': {
      padding: '10px',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    '.Toastify__toast button.MuiIconButton-root svg': {
      fontSize: '1.125rem',
    },
    '.Toastify__toast button.MuiIconButton-root path': {
      color: '#6B748B',
    },
  },
})

export default MuiCssBaseline
