import { Card, CardProps, IconButton, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import QRCode from 'qrcode.react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { copyTextToClipboard, useCommonStyles, StyleUtils } from 'js/utils'

import { ReactComponent as CopyOutlined } from 'assets/CopyOutlined.svg'

interface Props extends CardProps {
  depositAddress: string
}

const QRCodeCard: React.FC<Props> = (props: Props) => {
  const { depositAddress, className, ...rest } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const sdk = useSelector(getCarbonSDK)

  const onCopyAddress = () => {
    if (!sdk?.wallet) return

    copyTextToClipboard(depositAddress)
  }

  return (
    <Card
      {...rest}
      elevation={0}
      className={clsx(classes.addressBox, className)}
    >
      <div className={!depositAddress ? classes.qrCodeBlur : undefined}>
        <div className={classes.QRCodeBox}>
          <QRCode value={depositAddress} />
        </div>
      </div>

      <div className={clsx(commonClasses.alignItemsCenter, classes.marginTop2)}>
        <Typography variant="body2" color="textPrimary" className={depositAddress ? classes.address : classes.noAddress}>
          {depositAddress ? (
            <React.Fragment>{depositAddress}</React.Fragment>
          ) : (
            <React.Fragment>Please connect wallet to view your address</React.Fragment>
          )}
        </Typography>
        {depositAddress && (
          <IconButton onClick={onCopyAddress} className={classes.copyButton}>
            <CopyOutlined />
          </IconButton>
        )}
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  addressBox: {
    borderRadius: theme.spacing(0.25),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
    },
  },
  address: {
    ...theme.typography.body3,
    flex: 1,
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('xs')]: {
      whiteSpace: 'initial',
    },
  },
  noAddress: {
    ...theme.typography.body3,
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.only('xs')]: {
      whiteSpace: 'initial',
    },
  },
  copyButton: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.75),
    '& svg': {
      '& path': {
        ...StyleUtils.textButtonHoverAnimationDuration(),
        stroke: theme.palette.text.demexSolid,
      },
      '&:hover': {
        '& path': {
          stroke: theme.palette.text.demexSolidHover,
        },
      }
    },
  },
  emphasis: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '0.85rem',
  },
  QRCodeBox: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(0.5),
  },
  qrCodeBlur: {
    filter: 'blur(10px)',
    WebkitFilter: 'blur(10px)',
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  }
}))

export default QRCodeCard
