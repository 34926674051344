import { FEES_REBATE_PERCENTAGE } from './competition'

export const feeWalletAddress: string = 'swth1prv0t8j8tqcdngdmjlt59pwy6dxxmtqgycy2h7'

export const msgClaimDelegationRewardsGasLimit = 20000000

export const DEFAULT_RESERVED_FEE_USD = 1

export interface FeeDropDownItem {
  label: string
  key: string
}

export const FeeDropdownKeys: { [key: string]: string } = {
  preferredToken: 'preferred-token',
  reserveToken: 'reserve-token',
  useSwthForFees: 'use-swth-for-fees',
  buySwthForFees: 'buy-swth-for-fees',
}

export const feeDropdownItems: FeeDropDownItem[] = [
  {
    label: 'Select Preferred Fee Token',
    key: FeeDropdownKeys.preferredToken,
  },
  {
    label: 'Reserve Tokens for Fees',
    key: FeeDropdownKeys.reserveToken,
  },
  {
    label: 'Use SWTH for Withdrawal Fees',
    key: FeeDropdownKeys.useSwthForFees,
  },
  {
    label: 'Buy SWTH for Fees',
    key: FeeDropdownKeys.buySwthForFees,
  },
]

export const feePercentAfterDiscount = (100 - FEES_REBATE_PERCENTAGE) / 100
