import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { BN_ZERO } from 'js/utils/number'
import { AccountData, Params } from 'types/cdp'

import { CDPActionTypes, CDPState, CDPStateProps } from './types'


export const initAccountData: AccountData = {
  totalCollateralsUsd: BN_ZERO,
  availableBorrowsUsd: BN_ZERO,
  currLiquidationThreshold: BN_ZERO,
  totalDebtsUsd: BN_ZERO,
  totalStablecoinDebtsUsd: BN_ZERO,
  healthFactor: BN_ZERO,
}

export const initParams: Params = {
  completeLiquidationThreshold: BN_ZERO,
  interestFee: BN_ZERO,
  liquidationFee: BN_ZERO,
  minimumCloseFactor: BN_ZERO,
  smallLiquidationSize: BN_ZERO,
  stablecoinMintCap: BN_ZERO,
  stablecoinInterestRate: BN_ZERO,
  cdpPaused: false,
}

export const DefaultInitialState:
  Record.Factory<CDPStateProps> = Record<CDPStateProps>({
    params: initParams,

    collaterals: [],
    debts: [],
    accountData: initAccountData,

    cdpModuleAddressBalances: {},
    totalSupplyMap: {},
    cdpAssets: {},
    cdpAssetsAlias: {},


    accountView: undefined,

    rewardSchemes: {},
    rewardDebts: {},

    // cdp filters
    hideZeroAvailable: false,
    hideZeroBalance: false,

    // cdp form redirect url
    redirectUrl: undefined,

    // emode
    emodeCategories: {},
    emodeCategory: undefined,
  })

const defaultInitialState: CDPState = new DefaultInitialState()

export const CDPReducer = (
  state: CDPState = defaultInitialState,
  action: AnyAction,
): CDPState => {
  switch (action.type) {
    case CDPActionTypes.SET_PARAMS: {
      return state.set('params', action.payload)
    }
    case CDPActionTypes.SET_COLLATERALS: {
      return state.set('collaterals', action.payload)
    }
    case CDPActionTypes.SET_DEBTS: {
      return state.set('debts', action.payload)
    }
    case CDPActionTypes.SET_CDP_MODULE_BALANCES: {
      return state.set('cdpModuleAddressBalances', action.payload)
    }
    case CDPActionTypes.UPDATE_CDP_ASSET: {
      return state.set('cdpAssets', action.payload)
    }
    case CDPActionTypes.UPDATE_CDP_ASSET_ALIAS: {
      return state.set('cdpAssetsAlias', action.payload)
    }
    case CDPActionTypes.UPDATE_ACCOUNT_VIEW: {
      return state.set('accountView', action.payload)
    }
    case CDPActionTypes.SET_HIDE_ZERO_BALANCE: {
      return state.set('hideZeroBalance', action.payload)
    }
    case CDPActionTypes.SET_REWARD_SCHEMES: {
      return state.set('rewardSchemes', action.payload)
    }
    case CDPActionTypes.SET_REWARD_DEBTS: {
      return state.set('rewardDebts', action.payload)
    }
    case CDPActionTypes.SET_TOTAL_SUPPLY_MAP: {
      return state.set('totalSupplyMap', action.payload)
    }
    case CDPActionTypes.SET_CDP_FORM_REDIRECT_URL: {
      return state.set('redirectUrl', action.payload)
    }
    case CDPActionTypes.SET_EMODE_CATEGORIES: {
      return state.set('emodeCategories', action.payload)
    }
    case CDPActionTypes.SET_EMODE_CATEGORY: {
      return state.set('emodeCategory', action.payload)
    }
    default:
      return state
  }
}
