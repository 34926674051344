import {
  Hidden,
  Theme, makeStyles
} from '@material-ui/core'
import { Color } from '@material-ui/lab'
import { CarbonSDK } from 'carbon-js-sdk'
import React, { Suspense, useRef } from 'react'
import { useSelector } from 'react-redux'

import { PulsingIndicator } from 'js/components/Common'
import Dropdown from 'js/components/Exchange/MarketsBar/shared/Dropdown'
import { EvtCategory, useAdjustedBalance, useDropdownHandler, useEventTrackers, useIndivReservedFees } from 'js/hooks'
import { getReservedTokensPreference, isWalletConnected } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { BN_ZERO } from 'js/utils/number'
import { StyleUtils } from 'js/utils/styles'

import { ReactComponent as FeeSettings } from 'assets/FeeSettings.svg'

import { FeeSettingsDetail } from './components'

import BottomButton from '../../BottomButton'

const SWTHFeeDropdown: React.FC = () => {
  const classes = useStyles()
  const eventTrackers = useEventTrackers()
  const isLoggedIn = useSelector(isWalletConnected)
  const sdk: CarbonSDK = useSelector(getCarbonSDK) as CarbonSDK
  const indivReservedFees = useIndivReservedFees()
  const wallet = sdk?.wallet
  const { firstPriority: firstPriorityToken = { denom: '', symbol: '' } } = useSelector(getReservedTokensPreference) ?? {}
  const balanceAvailable = useAdjustedBalance(firstPriorityToken.denom, 'available')
  const balanceFeeReserved = useAdjustedBalance(firstPriorityToken.denom, 'feeReserved')
  const availableBeforeFees = (balanceAvailable).plus(balanceFeeReserved)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const dropdownPaperRef = useRef<HTMLDivElement | null>(null)
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useDropdownHandler(false)
  const status = React.useMemo((): Color | undefined => {
    if (availableBeforeFees) {
      if (availableBeforeFees.isZero() && indivReservedFees?.reserve.gt(0)) {
        return 'error'
      }
      if (availableBeforeFees.lt(indivReservedFees?.reserve) && availableBeforeFees.gt(BN_ZERO)) {
        return 'warning'
      }
    }
    return undefined
  }, [indivReservedFees, availableBeforeFees])

  const feeSettingsIcon = (
    <BottomButton
      icon={FeeSettings}
      label={(
        <React.Fragment>Fee Settings {wallet?.bech32Address && status && (
          <PulsingIndicator
            size={16}
            className={classes.pulseAlt}
            status={status}
          />
        )}
        </React.Fragment>
      )}
    />
  )

  const handleLabelClick = () => {
    if (isDropdownOpen) {
      handleDropdownClose()
    } else {
      handleDropdownOpen()
      eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_fee_settings')
    }
  }

  return (
    <React.Fragment>
      <Hidden smDown>
        {
          isLoggedIn && (
            <Dropdown
              dropdownOpen={isDropdownOpen}
              paperRef={dropdownPaperRef}
              onDropdownLabelClick={handleLabelClick}
              onMouseLeave={handleDropdownClose}
              label={feeSettingsIcon}
              hasDropdownIcon={false}
              leftUpperMenu
              className={classes.dropdown}
              labelClassName={classes.dropdownLbl}
              additionalHeight={110}
            >
              <Suspense fallback={null}>
                <div ref={contentRef}>
                  <FeeSettingsDetail feeDropdownOpen={isDropdownOpen} feeDropdownPaperRef={dropdownPaperRef} />
                </div>
              </Suspense>
            </Dropdown>
          )
        }
      </Hidden>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: 'unset',
  },
  balance: {
    ...theme.typography.body4,
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.warning': {
      color: theme.palette.warning.main,
    },
  },
  cautionIcon: {
    maxHeight: '1rem',
    width: '1rem',
    '& path': {
      '&:first-child': {
        fill: theme.palette.error.main,
      },
    },
  },
  dropdown: {
    transition: 'all 0.2s ease-in-out',
    borderRadius: 4,
    width: '23.5rem',
    height: '640px !important',
    zIndex: 2,
    padding: theme.spacing(2, 3),
  },
  dropdownLbl: {
    '&.dropdownOpen': {
      background: 'transparent',
    },
  },
  innerDiv: {
    ...theme.typography.body2,
    height: '100%',
    width: '14rem',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  labelClass: {
    height: '100%',
    '&.dropdownOpen': {
      '& .dropdownClass': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  labelContainer: {
    ...theme.typography.header2,
    alignSelf: 'center',
    padding: theme.spacing(1.5, 3),
  },
  label: {
    ...theme.typography.button,
    textTransform: 'initial',
    color: '#ffffff',
    opacity: 1,
  },
  lockIcon: {
    height: '0.875rem',
    marginLeft: theme.spacing(1),
    width: '0.875rem',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  lockTop: {
    height: '0.875rem',
    width: '0.875rem',
    top: theme.spacing(-1.25),
    right: theme.spacing(-1.5),
    position: 'absolute',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  newLblClass: {
    display: 'flex',
    borderLeft: StyleUtils.borderStyle(theme.palette.divider, 1),
    [theme.breakpoints.only('xs')]: {
      borderLeft: 'none',
    },
  },
  notLogged: {
    marginRight: theme.spacing(3),
    '@media (min-width: 960px) and (max-width: 1056px)': {
      marginRight: theme.spacing(2),
    },
  },
  pulsing: {
    marginLeft: 4,
  },
  sliderFee: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    overflowY: 'auto',
  },
  unlockClass: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1.5),
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  drawer: {
    overflowY: 'hidden',
    padding: theme.spacing(2.5),
    background: theme.palette.background.paper,
  },
  pulseAlt: {
    marginLeft: theme.spacing(1),
  },
  root: {
    '&.$feeBox': {
      zIndex: 100,
    },
  },
  feeBox: {
    minHeight: '24px'
  },
}))

export default SWTHFeeDropdown
