import { Divider, Typography, createStyles, makeStyles } from '@material-ui/core'
import { OrderModule } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'

import { FeatureType, MAX_ORDERS_TO_SHOW } from 'js/constants/notification'
import { StyleUtils, useCommonStyles } from 'js/utils'
import { OrderNotificationProps } from 'js/utils/notifications'

interface OrderMessageProps extends OrderNotificationProps {
  cancelReasonDetails?: string
}

const OrderMessage: React.FC<OrderMessageProps> = (props: OrderMessageProps) => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { scenario, orders, cancelReasonDetails } = props

  const isOrderPlaced = scenario === FeatureType.ORDER_PLACED
  const isOrderExecuted = scenario === FeatureType.ORDER_FILLED || scenario === FeatureType.ORDER_PARTIALLY_FILLED
  const isOrderCancelled = scenario === FeatureType.ORDER_CANCELLED || scenario === FeatureType.ORDER_SYSTEM_CANCELLED
  const isMultiOrder = orders.length > 1
  const isCancelAll = isOrderCancelled && isMultiOrder
  const remainingOrders = Math.max(orders.length - MAX_ORDERS_TO_SHOW, 0)

  const getTriggerTypeDisplay = (type: OrderModule.TriggerType) => {
    switch (type) {
      case OrderModule.TriggerType.LastPrice: return 'Last'
      case OrderModule.TriggerType.IndexPrice: return 'Index'
      case OrderModule.TriggerType.MarkPrice: return 'Mark'
    }
    return undefined
  }
  const getTPSLSide = (side: OrderModule.OrderType) => {
    switch (side) {
      case OrderModule.OrderType.StopLimit:
      case OrderModule.OrderType.StopMarket:
        return "Stop Loss Order"
      case OrderModule.OrderType.TakeProfitLimit:
      case OrderModule.OrderType.TakeProfitMarket:
        return "Take Profit Order"
      default:
        return undefined
    }
  }

  return (
    <div>
      {isMultiOrder && <div>
        <Typography className={classes.message}>
          You have successfully {isCancelAll ? `cancelled` : `placed`}  the following orders.
        </Typography>
      </div>}
      {orders.slice(0, MAX_ORDERS_TO_SHOW).map((order, i) => <div key={i} className={clsx(classes.body, { empty: isOrderPlaced })}>
        {isOrderExecuted && (
          <div>
            <span className={clsx(classes.icon, { buy: order.side === 'buy', execution: true })}>
              {order.side === 'buy' ? 'Buy' : 'Sell'}
            </span>
            <span className={classes.description}>
              {order.tradeAction}
            </span>
          </div>
        )}
        {!isCancelAll && isOrderCancelled && (
          <div>
            <span className={classes.message}>
              {cancelReasonDetails ?? 'Your order is cancelled.'}
            </span>
          </div>
        )}
        <Divider className={classes.divider} />

        {isOrderExecuted && (
          <div>
            <Typography className={classes.title}>{order.market.name}</Typography>
            <div className={classes.marginTop0p5}><span className={classes.status}>Filled</span><span className={classes.value}>{`${order.execution?.filled}`}</span></div>
            {order.execution?.remaining && <div className={classes.marginTop0p25}><span className={classes.status}>Remaining</span><span className={classes.value}>{`${order.execution?.remaining}`}</span></div>}
          </div>
        )}

        {(isOrderPlaced || isOrderCancelled) && (
          <div>
            <Typography className={classes.title}>
              {(isOrderPlaced && order.triggerDetails) ? getTPSLSide(order.triggerDetails.side) : order.market.name}
            </Typography>
            <div className={classes.marginTop0p5}>
              <span className={clsx(classes.icon, { buy: order.side === 'buy', execution: false })}>
                {order.side === 'buy' ? 'Buy' : 'Sell'}
              </span>
              <span className={classes.description}>
                {order.tradeAction}
              </span>
            </div>

            {order.triggerDetails && (
              <div className={classes.marginTop0p5}>
                <span className={clsx(classes.icon, { buy: order.side === 'buy', execution: false })}>
                  {`Trigger | ${getTriggerTypeDisplay(order.triggerDetails.type)}`}
                </span>
                <span className={classes.description}>
                  {order.triggerDetails.price}
                </span>
              </div>
            )}
          </div>
        )}
      </div>)}
      {remainingOrders > 0 && isCancelAll && (
        <React.Fragment>
          <Divider className={classes.divider} />
          <div className={commonClasses.flexRow}>
            <Typography className={classes.message}>{`+${remainingOrders} more`}</Typography>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  body: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2.25),
    '&.empty': {
      marginTop: 0,
    },
  },
  icon: {
    ...theme.typography.body4,
    color: '#FFFFFF',
    border: '1px solid transparent',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 0.5, 0.125, 0.5),
    marginRight: theme.spacing(0.75),
    backgroundColor: '#6F7073',
    '&.execution': {
      '&.buy': {
        background: StyleUtils.accentSuccess,
      },
      background: StyleUtils.accentError,
    },
  },
  description: {
    ...theme.typography.body3,
  },
  message: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(1, 0),
  },
  title: {
    ...theme.typography.body3,
    fontWeight: 700,
  },
  status: {
    marginRight: theme.spacing(1),
    ...theme.typography.body3,
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
  value: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  marginTop0p5: {
    marginTop: theme.spacing(0.5)
  },
  marginTop0p25: {
    marginTop: theme.spacing(0.25)
  }
}))

export default OrderMessage
