import BigNumber from 'bignumber.js'
import { WSModels } from 'carbon-js-sdk'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import { List, Record } from 'immutable'
import { AnyAction } from 'redux'

import { parseNumber } from 'js/utils/number'

import { FundingData, FundingBreakdownGraphCoordinate, HistoryActionTypes, HistoryState, HistoryStateProps, ModifiedHistoryOrder, OverviewGraphCoordinate, OverviewPnlGraphCoordinate } from './types'

let hideOtherMarketsBoolean: boolean = true
const hideOtherMarkets = localStorage.getItem(HistoryActionTypes.SET_IS_CURRENT_MARKET_SELECTED)
if (hideOtherMarkets && hideOtherMarkets === 'false') {
  hideOtherMarketsBoolean = false
}

export const DefaultInitialState:
  Record.Factory<HistoryStateProps> = Record<HistoryStateProps>({
    orderHistory: List<ModifiedHistoryOrder>(),
    openOrders: List<ModifiedHistoryOrder>(),
    trades: List<WSModels.AccountTrade>(),
    openPositions: List<WSModels.Position>(),
    closedPositions: List<WSModels.Position>(),
    crosschainTransfers: [],
    pendingCrosschainTransfers: {transfers: []},
    internalTransfers: [],
    editedOrders: {},
    isCurrentMarketSelected: hideOtherMarketsBoolean,
    closePositionPriceInputs: {},
    balanceGraphData: [],
    pnlGraphData: [],
    pnl: BN_ZERO,
    fundingData: [],
    fundingPaidBreakdownGraphData: [],
    fundingReceivedBreakdownGraphData: [],
    isUSD: false,
    isSyncComplete: true,
    isUserOverviewMode: false,
  })

const defaultInitialState: HistoryState = new DefaultInitialState()

export const HistoryReducer = (
  state: HistoryState = defaultInitialState,
  action: AnyAction,
): HistoryState => {
  switch (action.type) {
    // Order history
    case HistoryActionTypes.SET_ORDER_HISTORY: {
      return state.set('orderHistory', List(action.payload))
    }
    case HistoryActionTypes.CLEAR_ORDERS_AND_TRADES: {
      return state.merge({
        orderHistory: List(),
        openOrders: List(),
        trades: List(),
        openPositions: List(),
        closedPositions: List(),
      })
    }

    // Open orders
    case HistoryActionTypes.SET_OPEN_ORDERS: {
      return state.set('openOrders', List(action.payload))
    }

    // Pending edit orders
    case HistoryActionTypes.EDIT_ORDER: {
      const params = action.payload
      const newEditedOrders = { ...state.editedOrders }
      newEditedOrders[params.id] = params
      return state.set('editedOrders', newEditedOrders)
    }
    case HistoryActionTypes.EDIT_ORDER_RESULT: {
      const newEditedOrders = { ...state.editedOrders }
      delete newEditedOrders[action.payload]
      return state.set('editedOrders', newEditedOrders)
    }

    // Account trades or trade history
    case HistoryActionTypes.SET_ACCOUNT_TRADES: {
      const trades: WSModels.AccountTrade[] = action.payload
      return state.set('trades', List(trades))
    }
    case HistoryActionTypes.ADD_ACCOUNT_TRADES: {
      const incomingTrades: WSModels.AccountTrade[] = action.payload
      return state.set('trades', List(incomingTrades).concat(state.trades))
    }

    // Positions
    case HistoryActionTypes.SET_OPEN_POSITIONS: {
      const positions: WSModels.Position[] = action.payload
      return state.set('openPositions', List(positions))
    }
    case HistoryActionTypes.SET_CLOSED_POSITIONS: {
      const positions: WSModels.Position[] = action.payload
      return state.set('closedPositions', List(positions))
    }
    case HistoryActionTypes.ADD_POSITIONS: {
      const positions: WSModels.Position[] = action.payload
      const incomingPositions: List<WSModels.Position> = List(positions)

      return processPositions(state, incomingPositions)
    }
    case HistoryActionTypes.SET_CLOSE_POSITION_PRICE_INPUTS: {
      return state.set('closePositionPriceInputs', {
        ...state.closePositionPriceInputs,
        [action.payload.name]: action.payload.input,
      })
    }

    // Balance/Pnl 
    case HistoryActionTypes.SET_ACCOUNT_BALANCE_GRAPH_DATA: {
      const graphData: OverviewGraphCoordinate[] = action.payload
      return state.set('balanceGraphData', graphData)
    }
    case HistoryActionTypes.SET_ACCOUNT_PNL_GRAPH_DATA: {
      const graphData: OverviewPnlGraphCoordinate[] = action.payload
      return state.set('pnlGraphData', graphData)
    }
    case HistoryActionTypes.SET_ACCOUNT_PNL: {
      const pnl: BigNumber = action.payload
      return state.set('pnl', pnl)
    }

    // Funding
    case HistoryActionTypes.SET_ACCOUNT_FUNDINGS: {
      const fundingData: FundingData[] = action.payload
      return state.set('fundingData', fundingData)
    }

    case HistoryActionTypes.SET_ACCOUNT_FUNDING_PAID_BREAKDOWN_GRAPH_DATA: {
      const fundingPaidBreakdownGraphData: FundingBreakdownGraphCoordinate[] = action.payload
      return state.set('fundingPaidBreakdownGraphData', fundingPaidBreakdownGraphData)
    }

    case HistoryActionTypes.SET_ACCOUNT_FUNDING_RECEIVED_BREAKDOWN_GRAPH_DATA: {
      const fundingReceivedBreakdownGraphData: FundingBreakdownGraphCoordinate[] = action.payload
      return state.set('fundingReceivedBreakdownGraphData', fundingReceivedBreakdownGraphData)
    }

    // Used for orderHistory, openOrders and stops
    // Because all 3 depend on one ws subscription; orders
    case HistoryActionTypes.SYNC_ORDERS: {
      const incomingOrders: List<ModifiedHistoryOrder> = List(action.payload)
      return state.merge({
        orderHistory: processOrders(state.orderHistory, incomingOrders),
        openOrders: processAndFilterOrders(state.openOrders, incomingOrders),
      })
    }

    case HistoryActionTypes.SET_SYNC_STATUS_TRUE: {
      return state.set('isSyncComplete', true)
    }

    case HistoryActionTypes.SET_SYNC_STATUS_FALSE: {
      return state.set('isSyncComplete', false)
    }

    case HistoryActionTypes.SET_IS_CURRENT_MARKET_SELECTED: {
      localStorage.setItem(HistoryActionTypes.SET_IS_CURRENT_MARKET_SELECTED, JSON.stringify(action.payload))
      return state.set('isCurrentMarketSelected', action.payload)
    }

    case HistoryActionTypes.SET_CROSSCHAIN_TRANSFERS: {
      return state.set('crosschainTransfers', action.payload)
    }

    case HistoryActionTypes.SET_IS_USER_OVERVIEW_MODE: {
      return state.set('isUserOverviewMode', action.payload)
    }

    case HistoryActionTypes.SET_PENDING_CROSSCHAIN_TRANSFERS: {
      return state.set('pendingCrosschainTransfers', action.payload)
    }
    default:
      return state
  }
}

export function processAndFilterOrders(originalState: List<ModifiedHistoryOrder>, incomingOrders: List<ModifiedHistoryOrder>): List<ModifiedHistoryOrder> {
  return List(processOrders(originalState, incomingOrders).filter(byOpenOrders))
}

function processOrders(originalState: List<ModifiedHistoryOrder>, incomingOrders: List<ModifiedHistoryOrder>)
  : List<ModifiedHistoryOrder> {
  let newState = List<ModifiedHistoryOrder>()
  newState = originalState

  // Process new
  const newOrders: List<ModifiedHistoryOrder> = incomingOrders.filter((order: ModifiedHistoryOrder) => order.type === 'new')
  newState = List<ModifiedHistoryOrder>(newOrders).concat(newState)

  // Process update
  const updatedOrders: List<ModifiedHistoryOrder> = incomingOrders.filter((order: ModifiedHistoryOrder) => order.type === 'update')

  updatedOrders.forEach((newOrder: ModifiedHistoryOrder): void => {
    newState.forEach((stateOrder: ModifiedHistoryOrder, index: number): boolean => {
      if (stateOrder.id === newOrder.id) {
        newState = newState.set(index, newOrder)
        return false // Break loop
      }
      return true
    })
  })

  return newState
}

function processNewPositions(incomingPosition: WSModels.Position, state: HistoryState) {
  const openPositions: List<WSModels.Position> = List(state.openPositions)

  return state.set('openPositions', addNewOpenPosition(incomingPosition, openPositions))
}

export function addNewOpenPosition(incomingPosition: WSModels.Position, openPositions: List<WSModels.Position>) {
  return openPositions.unshift(incomingPosition)
}

function processUpdatePositions(incomingPosition: WSModels.Position, state: HistoryState) {
  let tempPositionState: List<WSModels.Position> = List(state.openPositions)

  tempPositionState = updateOpenPosition(incomingPosition, tempPositionState)

  return state.set('openPositions', tempPositionState)
}

export function updateOpenPosition(incomingPosition: WSModels.Position, openPositions: List<WSModels.Position>) {
  openPositions.every((statePosition: WSModels.Position, index: number) => {
    if (statePosition.market_id === incomingPosition.market_id) {
      openPositions = openPositions.set(index, incomingPosition)
      return false
    }
    return true
  })
  return openPositions
}

function processDeletePositions(incomingPosition: WSModels.Position, state: HistoryState) {
  const newClosedPositions: List<WSModels.Position> = List(state.closedPositions)
  let newOpenPositions: List<WSModels.Position> = List(state.openPositions)
  newOpenPositions = deleteOpenPosition(incomingPosition, newOpenPositions)
  return state.merge({
    openPositions: newOpenPositions,
    closedPositions: newClosedPositions.unshift(incomingPosition),
  })
}


export function deleteOpenPosition(incomingPosition: WSModels.Position, openPositions: List<WSModels.Position>) {
  openPositions.every((statePosition: WSModels.Position, index: number) => {
    if (statePosition.market_id === incomingPosition.market_id
      && !new BigNumber(statePosition.lots).eq(0)) {
      openPositions = openPositions.delete(index)
      return false
    }
    return true
  })
  return openPositions
}


function processPositions(state: HistoryState, incomingPositions: List<WSModels.Position>)
  : HistoryState {
  let newState: HistoryState = state

  incomingPositions.forEach((incomingPosition: WSModels.Position) => {
    const currentPosition = newState.openPositions.find((position: WSModels.Position) => (
      position.market_id === incomingPosition.market_id
    ))
    if (currentPosition) {
      if (!parseNumber(incomingPosition.lots)?.isZero()) {
        newState = processUpdatePositions(incomingPosition, newState)
      } else {
        newState = processDeletePositions(incomingPosition, newState)
      }
    } else {
      newState = processNewPositions(incomingPosition, newState)
    }
  })

  return newState
}


function byOpenOrders(order: any): boolean {
  const { status: orderStatus }
    // eslint-disable-next-line camelcase
    : { status: string, order_type: string } = order
  // Only return orders with open or untriggered status (remove canceled/filled/pending status)
  return (orderStatus === 'open' || orderStatus === 'untriggered')
}
