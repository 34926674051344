import {
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import clsx from 'clsx'
import { isString } from 'lodash'
import React, { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import LoadingIcon from 'js/components/Common/LoadingIcon'
import SvgIcon from 'js/components/Common/SvgIcon'
import TextButton from 'js/components/Common/TextButton'
import { FeatureIcons, FeatureType, NotificationIcons } from 'js/constants/notification'
import { EvtCategory } from 'js/hooks'
import { getConnectedWalletAddress } from 'js/state/modules/app/selectors'
import { sendGaEvent } from 'js/utils/events'
import { NotificationProps } from 'js/utils/notifications'
import { useCommonStyles } from 'js/utils/styles'

import StringReplacement from '../Common/StringReplacement'
import TokenSymbol from '../Common/TokenSymbol'

const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
  const {
    closeToast,
    title,
    featureType,
    body,
    message,
    type = 'info',
    action = undefined,
    link = undefined,
    replaceGroupedToken,
  } = props
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const history = useHistory()
  const NotificationIcon = NotificationIcons[type]

  const messageToDisplay = useMemo(() => {
    if (!replaceGroupedToken) return message

    if (isString(message)) {
      return (
        <StringReplacement
          original={message}
          toReplace={replaceGroupedToken.symbol}
          replacement={
            <Fragment>
              &nbsp;
              <TokenSymbol denom={replaceGroupedToken.denom} symbolOverride={replaceGroupedToken.symbol} />
            </Fragment>
          } />
      )
    }

    return message
  }, [message, replaceGroupedToken])

  const getFeatureIcon = (featureType?: FeatureType): SVGComponent | undefined => {
    return (featureType && FeatureIcons[featureType]) ?? FeatureIcons.trade
  }

  const actionCallback = () => {
    if (action && closeToast) {
      closeToast()
      action.cta()
    }
  }

  const linkCallback = () => {
    if (action && closeToast) {
      closeToast()
      action.cta()
    }
    if (link?.path) {
      history.push(link.path)
    }
  }


  const address = useSelector(getConnectedWalletAddress)
  if (type === 'error') {
    let msg = ''
    if (message && typeof message === 'string') {
      msg = message
    }
    const gaParams: SimpleMap<string> = {}
    if (address) {
      gaParams[address] = address
      sendGaEvent(EvtCategory.Misc, 'error_shown', msg, undefined, new Map() as any)
    }
  }
  return (
    <div
      style={{ wordBreak: 'break-word' }}
      className={clsx(classes.root)}
    >
      <div className={clsx(commonClasses.flexRow, commonClasses.alignItemsStart)}>
        {type === 'loading' ? <div className={classes.loading}><LoadingIcon size="1.2rem" /></div> : <SvgIcon className={classes.icon} component={NotificationIcon} />}

        <div className={clsx(commonClasses.flexColumn, classes.flexGrow)}>
          {isString(title) && title !== '' && (
            <div className={commonClasses.alignItemsCenter}>
              <SvgIcon className={clsx(classes.featureIcon)} component={getFeatureIcon(featureType)} />
              <Typography
                variant="body2"
                className={clsx(classes.title)}
              >
                {title}
              </Typography>
            </div>
          )}
          {!isString(title) && title}
          {isString(messageToDisplay) && messageToDisplay !== '' && (
            <div className={classes.messageToDisplayWrapper}>
              <Typography variant="body2" className={classes.message}>
                {messageToDisplay}
              </Typography>
            </div>
          )}
          {!isString(messageToDisplay) && (
            <div className={classes.message}>
              {messageToDisplay}
            </div>
          )}
        </div>
      </div>
      <div>
        {body && <Divider className={classes.divider} />}
        {isString(body) && (
          <div className={classes.body}>
            {body}
          </div>
        )}
        {!isString(body) && body}
        {action && (
          <div className={clsx(classes.marginTop1, !link && commonClasses.justifyContentEnd)}>
            <TextButton
              label={action.label}
              onClick={actionCallback}
              endIcon={action.endIcon}
              startIcon={action.startIcon}
              labelClass={classes.actionLabel}
            />
          </div>
        )}
        {link && (
          <div className={clsx(classes.marginTop1, commonClasses.justifyContentEnd)}>
            <TextButton
              label={link.label ?? 'Button'}
              onClick={linkCallback}
              href={link.href}
              target={link.target}
              endIcon={link.endIcon}
              startIcon={link.startIcon}
              svgClass={link.svgClass}
              labelClass={classes.actionLabel}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  actionLabel: {
    minWidth: 'max-content',
  },
  actionBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  linkBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
  root: {
    background: theme.palette.background.secondary,
    // 24px padding for close button
    flex: 1,
    transition: 'all 500ms ease',
  },
  featureIcon: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.3),
  },
  loading: {
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(1),
  },
  icon: {
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    marginRight: theme.spacing(1),
    '& path': {
      strokeWidth: 1,
    },
  },
  title: {
    ...theme.typography.body3,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  message: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
  },
  header: {
    display: 'flex',
    justifyContent: 'start',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(1, 0),
  },
  body: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
  },
  flexGrow: {
    flexGrow: 1
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
  messageToDisplayWrapper: {
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
  }
}))

export default Notification
