import { Record } from 'immutable'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

import { SimpleMap, uuidv4 } from 'js/utils'
import { Inbox, Preference, updateInbox } from 'js/utils/alerts'

import { AlertsActionTypes, AlertsState, AlertsStateProps, UpdateInboxStatusPayload } from './types'

const defaultValues: AlertsStateProps = {
  profile: null,
  preferences: null,
  optimisticPreferences: null,
  verification: null,
  inbox: null,
  optimisticInbox: null,
  pendingInboxUpdates: null,
  pendingPreferencesUpdates: null,
  displayStatus: null,
  authDialog: null,
  telegramDialog: null,
  accountVerified: null,
}

export const DefaultInitialState: Record.Factory<AlertsStateProps> = Record<AlertsStateProps>(defaultValues)
const defaultInitialState: AlertsState = new DefaultInitialState()

export const alertsReducer = (
  state: AlertsState = defaultInitialState,
  action: AnyAction,
): AlertsState => {
  switch (action.type) {
    case AlertsActionTypes.RESET_ALERTS_DATA: {
      return new DefaultInitialState(cloneDeep(defaultValues))
    }
    case AlertsActionTypes.SET_USER_PROFILE: {
      return state.set('profile', action.payload)
    }
    case AlertsActionTypes.UPDATE_DISPLAY_STATUS: {
      const { section, show } = action.payload
      return state.set('displayStatus', { ...state.displayStatus, [section]: show })
    }
    case AlertsActionTypes.SET_USER_PREFERENCES: {
      return state.set('preferences', action.payload)
    }
    case AlertsActionTypes.SET_OPTIMISTIC_USER_PREFERENCES: {
      return state.set('optimisticPreferences', action.payload)
    }
    case AlertsActionTypes.REVERT_PREFERENCES_OPTIMISTIC_UPDATES: {
      return state.set('optimisticPreferences', cloneDeep(state.preferences))
    }
    case AlertsActionTypes.SET_VERIFICATION: {
      return state.set('verification', action.payload)
    }
    case AlertsActionTypes.UPDATE_VERIFICATION_STATUS: {
      const { type, update } = action.payload
      const currentState = state.verification?.[type] ?? {}
      return state.set('verification', { ...state.verification, [type]: { ...currentState, ...update } })
    }
    case AlertsActionTypes.TOGGLE_AUTH_DIALOG: {
      const dialog = state.authDialog
      return state.set('authDialog', { ...dialog, ...action.payload })
    }
    case AlertsActionTypes.TOGGLE_TELEGRAM_DIALOG: {
      const dialog = state.telegramDialog
      return state.set('telegramDialog', { ...dialog, ...action.payload })
    }
    case AlertsActionTypes.SET_ACCOUNT_VERIFIED: {
      return state.set('accountVerified', action.payload)
    }
    case AlertsActionTypes.UPDATE_INBOX: {
      const updates: Inbox[] = action.payload
      const updatedInbox = updateInbox(state.inbox, updates)
      return state.set('inbox', updatedInbox)
    }
    case AlertsActionTypes.UPDATE_OPTIMISTIC_INBOX: {
      const updates: Inbox[] = action.payload

      const updatedInbox = updateInbox(state.optimisticInbox, updates)
      return state.set('optimisticInbox', updatedInbox)
    }
    case AlertsActionTypes.REVERT_INBOX_OPTIMISTIC_UPDATES: {
      return state.set('optimisticInbox', cloneDeep(state.inbox))
    }
    case AlertsActionTypes.RESET_INBOX: {
      return state.set('inbox', null)
    }
    case AlertsActionTypes.RESET_OPTIMISTIC_INBOX: {
      return state.set('optimisticInbox', null)
    }
    case AlertsActionTypes.ADD_PENDING_INBOX_STATUS_UPDATES: {
      const updates: UpdateInboxStatusPayload[] = action.payload
      const updatedState = addPendingState(state.pendingInboxUpdates, updates)
      return state.set('pendingInboxUpdates', updatedState)
    }
    case AlertsActionTypes.REMOVE_PENDING_INBOX_STATUS_UPDATES: {
      const uuids: string[] = action.payload
      const updatedState = removePendingState(state.pendingInboxUpdates, uuids)
      return state.set('pendingInboxUpdates', { ...updatedState })
    }
    case AlertsActionTypes.ADD_PENDING_PREFERENCES_UPDATES: {
      const updates: Preference[] = action.payload
      const updatedState = addPendingState(state.pendingPreferencesUpdates, updates)
      return state.set('pendingPreferencesUpdates', updatedState)
    }
    case AlertsActionTypes.REMOVE_PENDING_PREFERENCES_UPDATES: {
      const uuids: string[] = action.payload
      const updatedState = removePendingState(state.pendingPreferencesUpdates, uuids)
      return state.set('pendingPreferencesUpdates', { ...updatedState })
    }
    default:
      return state
  }
}

const addPendingState = <T>(state: SimpleMap<T> | null, updates: T) => {
  const uuid = uuidv4()
  const updatedState = !state ? { [uuid]: updates } : { ...state, [uuid]: updates }
  return updatedState
}

const removePendingState = <T>(state: SimpleMap<T> | null, uuids: string[]) => {
  if (!state || Object.keys(state).length === 0) return state
  uuids.forEach(uuid => delete state[uuid])
  return state
}
