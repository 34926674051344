import { List, Record, Set } from 'immutable'
import { find } from 'lodash'
import { AnyAction } from 'redux'

import { LayoutStructVersion } from 'js/constants/assets'
import { EXCHANGE_SECTIONS } from 'js/constants/sectionTabs'
import baseLayouts from 'js/utils/layouts'
import { BN_ZERO } from 'js/utils/number'

import { ExchangeActionTypes, ExchangeOrderBook, ExchangeState, ExchangeStateProps, OpenOrderLevel, OrderBookUpdate, OrderBookUpdates, liteModeLocalStorageKey } from './types'

import { processOrderSide } from '../orderBook/reducer'
import { OrderBookEntry } from '../orderBook/types'


let lastMarkObj: { [key: string]: string } = {}
let lastSwapMarketObj: { [key: string]: string } = {}
const lastMarkLocal = localStorage.getItem(ExchangeActionTypes.SET_LAST_MARKET)
const lastSwapMarketLocal = localStorage.getItem(ExchangeActionTypes.SET_LAST_SWAP_MARKET)

const parseLastMarketObj = (localStorageObj: string) => {
  try {
    const jsonLastMarket = JSON.parse(localStorageObj)
    const res: { [key: string]: string } = {}
    if (!(jsonLastMarket instanceof Object) || jsonLastMarket instanceof Array) {
      return {}
    }
    if (Object.keys(jsonLastMarket).length > 0) {
      Object.keys(jsonLastMarket).forEach((netKey: string) => {
        res[netKey] = jsonLastMarket[netKey]
      })
    }
    return res
  } catch (err) {
    return {}
  }
}

if (lastMarkLocal) {
  lastMarkObj = parseLastMarketObj(lastMarkLocal)
}

if (lastSwapMarketLocal) {
  lastSwapMarketObj = parseLastMarketObj(lastSwapMarketLocal)
}

let isSimpleModeInit = true
const isSimpleModeLocal = localStorage.getItem(liteModeLocalStorageKey)
if (isSimpleModeLocal) {
  const jsonIsSimpleMode = JSON.parse(isSimpleModeLocal)
  isSimpleModeInit = jsonIsSimpleMode
}

// Default to true so that user can see all available markets before deciding if he wants to filter or not
let showLowLiquidityBoolean: boolean = false
const showLowLiquidity = localStorage.getItem(ExchangeActionTypes.SET_SHOW_LOW_LIQUIDITY)
if (showLowLiquidity && showLowLiquidity === 'true') {
  showLowLiquidityBoolean = true
}


export const DefaultInitialState:
  Record.Factory<ExchangeStateProps> = Record<ExchangeStateProps>({
    favouriteMarkets: Set(),
    hideLayouts: Set(),
    markets: [],
    marketsAliases: {},
    marketDisplayNames: {},
    market: null,
    marketSelect: false,
    marketName: '',
    layout: baseLayouts.defaultLayout,
    layouts: {
      xl: baseLayouts.xlLayout,
      lg: baseLayouts.lgLayout,
      md: baseLayouts.mdLayout,
      sm: baseLayouts.smLayout,
      xs: baseLayouts.xsLayout,
    },
    symbolToggle: true,
    marketTypeSelect: 'perpetual',
    marketSearch: '',
    lastMarket: lastMarkObj,
    lastSwapMarket: lastSwapMarketObj,
    candlesticks: [],
    showLowLiquidity: showLowLiquidityBoolean,
    // booksObj store price unshifted from 18dp (sdk.Dec)
    booksObj: {},
    isSimpleMode: isSimpleModeInit,
    modeDialogState: {
      isOpen: false,
      selected: isSimpleModeInit ? 'pro' : 'lite',
    },
    tempOrderDetailsDialogState: { open: false },
    searchHistory: {},
    fundingInterval: null,
    adjustedMaxFuturesQuantity: BN_ZERO,
    marketLiquidityMap: {},
    marketHistoricalFundingRates: null,
    historicalFundingRateGraphData: [],
    sectionCollapseStatus: {
      [EXCHANGE_SECTIONS.chart]: false,
      [EXCHANGE_SECTIONS.orderBook]: true,
      [EXCHANGE_SECTIONS.leverageOrBalance]: false,
      [EXCHANGE_SECTIONS.positionOrOrder]: true,
    },
  })

const defaultInitialState: ExchangeState = new DefaultInitialState()

export const exchangeReducer = (
  state: ExchangeState = defaultInitialState,
  action: AnyAction,
): ExchangeState => {
  const { type, payload } = action
  switch (type) {
    case ExchangeActionTypes.ADD_FAVOURITE_MARKET: {
      return state.set('favouriteMarkets', state.favouriteMarkets.add(payload))
    }
    case ExchangeActionTypes.CLEAR_HIDE_LAYOUTS: {
      return state.set('hideLayouts', Set())
    }
    case ExchangeActionTypes.REMOVE_FAVOURITE_MARKET: {
      return state.set('favouriteMarkets', state.favouriteMarkets.remove(payload))
    }
    case ExchangeActionTypes.SET_HIDE_LAYOUTS: {
      const { layout, hide } = payload
      if (hide) {
        return state.set('hideLayouts', state.hideLayouts.add(layout))
      }
      return state.set('hideLayouts', state.hideLayouts.remove(layout))
    }
    case ExchangeActionTypes.SET_MARKET: {
      return state.set('market', payload)
    }
    case ExchangeActionTypes.SET_MARKET_NAME: {
      const marketName = payload
      const market = find(state.markets, ['id', marketName])
      return state.merge({
        market,
        marketName,
      })
    }
    case ExchangeActionTypes.SET_MARKETS: {
      const markets = payload
      const market = find(markets, ['id', state.marketName])
      return state.merge({
        markets,
        market,
      })
    }
    case ExchangeActionTypes.SET_MARKETS_ALIASES: {
      return state.set('marketsAliases', payload)
    }
    case ExchangeActionTypes.SET_MARKET_DISPLAY_NAMES: {
      return state.set('marketDisplayNames', payload)
    }
    case ExchangeActionTypes.SET_LAYOUT: {
      return state.set('layout', payload)
    }
    case ExchangeActionTypes.SET_LAYOUTS: {
      return state.set('layouts', payload)
    }
    case ExchangeActionTypes.RESET_LAYOUT: {
      return state.merge({
        layout: baseLayouts.defaultLayout,
        layouts: {
          v: LayoutStructVersion,
          xl: baseLayouts.xlLayout,
          lg: baseLayouts.lgLayout,
          md: baseLayouts.mdLayout,
          sm: baseLayouts.smLayout,
          xs: baseLayouts.xsLayout,
        },
        hideLayouts: Set(),
      })
    }
    case ExchangeActionTypes.SET_MARKET_SELECT_OPEN: {
      return state.set('marketSelect', true)
    }
    case ExchangeActionTypes.SET_MARKET_SELECT_CLOSE: {
      return state.set('marketSelect', false)
    }
    case ExchangeActionTypes.SET_MARKET_TYPE_SELECT: {
      return state.set('marketTypeSelect', payload)
    }
    case ExchangeActionTypes.SET_MARKET_SEARCH: {
      return state.set('marketSearch', payload)
    }
    case ExchangeActionTypes.SET_SYMBOL_TOGGLE: {
      return state.set('symbolToggle', !state.symbolToggle)
    }
    case ExchangeActionTypes.SET_LAST_MARKET: {
      const newState = {
        ...state.lastMarket,
        [payload.network]: payload.lastMarket,
      }
      localStorage.setItem(ExchangeActionTypes.SET_LAST_MARKET, JSON.stringify(newState))
      return state.set('lastMarket', newState)
    }
    case ExchangeActionTypes.SET_LAST_SWAP_MARKET: {
      const newState = {
        ...state.lastSwapMarket,
        [payload.network]: payload.lastSwapMarket,
      }
      localStorage.setItem(ExchangeActionTypes.SET_LAST_SWAP_MARKET, JSON.stringify(newState))
      return state.set('lastSwapMarket', newState)
    }
    case ExchangeActionTypes.SET_MARKETS_CANDLESTICKS: {
      return state.set('candlesticks', payload)
    }
    case ExchangeActionTypes.SET_SEARCH_HISTORY: {
      localStorage.setItem(ExchangeActionTypes.SET_SEARCH_HISTORY, JSON.stringify(payload))
      return state.set('searchHistory', payload)
    }
    case ExchangeActionTypes.SET_SHOW_LOW_LIQUIDITY: {
      localStorage.setItem(ExchangeActionTypes.SET_SHOW_LOW_LIQUIDITY, JSON.stringify(payload))
      return state.set('showLowLiquidity', payload)
    }
    case ExchangeActionTypes.SET_BOOKS_OBJ: {
      return state.set('booksObj', { ...payload })
    }
    case ExchangeActionTypes.SET_MARKET_ORDER_BOOK: {
      const booksObj = state.get('booksObj')
      const { market, bids, asks } = payload as ExchangeOrderBook
      booksObj[market] = { market, bids, asks }
      return state.set('booksObj', { ...booksObj })
    }
    case ExchangeActionTypes.SYNC_MARKET_ORDER_BOOK: {
      const booksObj = state.get('booksObj')
      const bookUpdates = payload as OrderBookUpdates
      Object.entries(bookUpdates).forEach(([market, orderBookUpdate]: [string, OrderBookUpdate]) => {
        const currentBids = booksObj[market]?.bids ?? []
        const currentAsks = booksObj[market]?.asks ?? []
        const updatedBids: OpenOrderLevel[] = processOrderSide(List(currentBids), List(orderBookUpdate.bidsChanges)).map(({ price, quantity }: OrderBookEntry) => ({ price, quantity })).toArray()
        const updatedAsks: OpenOrderLevel[] = processOrderSide(List(currentAsks), List(orderBookUpdate.asksChanges)).map(({ price, quantity }: OrderBookEntry) => ({ price, quantity })).toArray()
        booksObj[market] = { market, asks: updatedAsks, bids: updatedBids }
      })
      return state.set('booksObj', { ...booksObj })
    }
    case ExchangeActionTypes.SET_IS_SIMPLE_MODE: {
      localStorage.setItem(liteModeLocalStorageKey, JSON.stringify(payload))
      return state.set('isSimpleMode', payload)
    }
    case ExchangeActionTypes.SET_MODE_DIALOG_STATE: {
      return state.set('modeDialogState', payload)
    }
    case ExchangeActionTypes.SET_TEMP_ORDER_DETAILS_DIALOG_STATE: {
      return state.set('tempOrderDetailsDialogState', payload)
    }
    case ExchangeActionTypes.SET_FUNDING_INTERVAL: {
      return state.set('fundingInterval', payload)
    }
    case ExchangeActionTypes.SET_ADJUSTED_MAX_FUTURES_QUANTITY: {
      return state.set('adjustedMaxFuturesQuantity', payload)
    }
    case ExchangeActionTypes.SET_MARKETS_LIQUIDITY_MAP: {
      return state.set('marketLiquidityMap', payload)
    }
    case ExchangeActionTypes.SET_MARKET_HISTORICAL_FUNDING_RATES: {
      return state.set('marketHistoricalFundingRates', payload)
    }
    case ExchangeActionTypes.SET_HISTORICAL_FUNDING_RATE_GRAPH_DATA: {
      return state.set('historicalFundingRateGraphData', payload)
    }
    case ExchangeActionTypes.SET_SECTION_COLLAPSE_STATUS: {
      const { section, status } = payload
      const sectionCollapseStatus = state.get('sectionCollapseStatus')
      return state.set('sectionCollapseStatus', { ...sectionCollapseStatus, [section]: status })
    }
    default:
      return state
  }
}
