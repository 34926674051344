import { Record } from 'immutable'
import { AnyAction } from 'redux'

import { CDPLiquidationsStateProps, CdpLiquidationsActionTypes, CDPLiquidationsState, ModuleTotal } from './types'

const initTotal: ModuleTotal = {
  collateralsUsd: null,
  debtsUsd: null,
}

const DefaultInitialState:
  Record.Factory<CDPLiquidationsStateProps> = Record<CDPLiquidationsStateProps>({
    moduleTotal: initTotal,
    liquidations: [],
    positions: [],
  })

const defaultInitialState: CDPLiquidationsState = new DefaultInitialState()

export const CDPLiquidationReducer = (
  state: CDPLiquidationsState = defaultInitialState,
  action: AnyAction,
): CDPLiquidationsState => {
  switch (action.type) {
    case CdpLiquidationsActionTypes.SET_LIQUIDATIONS: {
      return state.set('liquidations', action.payload)
    }
    case CdpLiquidationsActionTypes.SET_POSITIONS: {
      return state.set('positions', action.payload)
    }
    case CdpLiquidationsActionTypes.SET_MODULE_TOTAL: {
      return state.set('moduleTotal', action.payload)
    }
    default:
      return state
  }
}
