import { makeStyles, SvgIconProps } from '@material-ui/core'
import { BlockchainUtils } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

import { TransferKey, TransferMethod } from 'js/constants/TransferOptions'
import { TOKEN_ICON_REPO_URL } from 'js/constants/paths'
import useCurrentWalletConnector from 'js/hooks/useCurrentWalletConnector'
import { Web3WalletIcons } from 'js/utils'
import { isNeoBlockchain } from 'js/utils/externalTransfer'

import { ReactComponent as Placeholder } from 'assets/Placeholder_Blockchain.svg'
import { Web3Icon } from 'assets/login'
import * as TransferMethodIcons from 'assets/transfer_methods'

import SvgIcon from './SvgIcon'


const getTokenIconURL = (symbol: string, fileType: string) => {
  return TOKEN_ICON_REPO_URL.replace(':type', 'blockchains').replace(':token_name', symbol).replace(':file_type', fileType)
}

export interface TransferMethodIconProps extends SvgIconProps {
  optionKey?: TransferKey
  transferMethod?: TransferMethod
  blockchain?: BlockchainUtils.BlockchainV2
  tokenContractIcon?: boolean
}

const TransferMethodIcon: React.FunctionComponent<TransferMethodIconProps> = (
  props: TransferMethodIconProps,
) => {
  const { optionKey, transferMethod, blockchain, className, tokenContractIcon, ...rest } = props
  const classes = useStyles()

  const currentWeb3Wallet = useCurrentWalletConnector()

  const [imageSrcIndex, setImageSrcIndex] = useState(0)
  const [imageSrcError, setImageSrcError] = useState(false)

  const transferTypeMap: {
    [index: string]: string
  } = {
    [TransferMethod.Token]: 'tokentransfer',
    [TransferMethod.O3Wallet]: 'o3wallet',
    [TransferMethod.IBCKeplr]: 'keplr',
    [TransferMethod.IBCLeap]: 'leap',
    [TransferMethod.IBC]: 'ibc',
    [TransferMethod.ZilPay]: 'zilpay',
    [TransferMethod.MetaMask]: 'metamask',
    [TransferMethod.Ledger]: 'ledger',
    [TransferMethod.TradeHub]: 'internaltransfer',
    [TransferMethod.Bridge]: 'internaltransfer',
  }

  const { chain, transferMethodIcon } = useMemo(() => {
    if (!(optionKey && transferMethod) && blockchain) {
      const chain = (isNeoBlockchain(blockchain.toLowerCase()) ? 'Neo' : blockchain).replace(/\s/g, '-')
      return { chain }
    }
    if (!optionKey || !transferMethod || !blockchain) {
      return { chain: 'Carbon' }
    }
    if (blockchain==='Native'){
      return {chain: 'Carbon', transferMethodIcon: TransferMethodIcons.internaltransfer}
    }
    const chain = (isNeoBlockchain(blockchain) ? 'Neo' : blockchain).replace(/\s/g, '-')
    const assetName = transferTypeMap[transferMethod] as keyof typeof TransferMethodIcons
    const transferMethodIcon = TransferMethodIcons[assetName]

    return {
      chain,
      transferMethodIcon,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionKey, transferMethod, blockchain])
  const symbolToFetch = `${chain ?? ''}-filled`

  const imageSrcArray = symbolToFetch ? ['svg', 'png'].map((fileType) => getTokenIconURL(symbolToFetch, fileType)) : []

  const handleImgSrcError = () => {
    if (imageSrcIndex < imageSrcArray.length - 1) {
      setImageSrcIndex(imageSrcIndex + 1)
    } else {
      console.error(symbolToFetch)
      setImageSrcError(true)
    }
  }

  return (
    !tokenContractIcon ? (
      <div className={clsx(classes.root, className)}>
        {!imageSrcError && symbolToFetch ? (
          <img className={clsx(classes.svg, className)} src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={symbolToFetch} />
        ) : (
          <SvgIcon
            component={Placeholder}
            {...rest}
            className={clsx(classes.svg, className)}
          />
        )
        }
        {transferMethodIcon ? (
          <SvgIcon className={classes.transferMethodBadge} component={transferMethodIcon} />
        ) : currentWeb3Wallet && (currentWeb3Wallet.icon || Web3WalletIcons[currentWeb3Wallet.id]) ? (
          <img src={currentWeb3Wallet.icon || Web3WalletIcons[currentWeb3Wallet.id]} className={classes.transferMethodBadge} alt={currentWeb3Wallet.name} />
        ) : (
          <Web3Icon className={classes.transferMethodBadge} />
        )}
      </div>
    ) : (
      !imageSrcError && symbolToFetch ? (
        <img className={clsx(classes.svg, className)} src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={symbolToFetch} />
      ) : (
        <SvgIcon
          component={Placeholder}
          {...rest}
          className={clsx(classes.svg, className)}
        />
      )
    )
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '1.5em',
    height: '1.5em',
    flexShrink: 0,
    margin: '5px',
  },
  svg: {
    marginRight: theme.spacing(1.5),
    fontSize: 'inherit',
  },
  transferMethodBadge: {
    position: 'absolute',
    right: theme.spacing(-0.875),
    bottom: theme.spacing(-0.875),
    width: '60%',
    height: '60%',
  },
}))

export default React.memo(TransferMethodIcon)
