import { Container, createStyles, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"

const ReferralsFallback: React.FC = () => {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  return (
    <div className={classes.box}>
      <Container maxWidth={false} className={classes.containerDiv}>
        <div className={classes.summaryRow}>
          <div className={clsx(commonClasses.flexColumn, commonClasses.alignItemsStart, classes.leftSummarySection)}>
            <LoadingSkeleton paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
          </div>
          <div className={clsx(commonClasses.flexColumn, commonClasses.alignItemsStart, commonClasses.justifyContentStretch, classes.referralConfigBox)}>
            <LoadingSkeleton paddingBottom={48} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
            <LoadingSkeleton paddingBottom={24} />
          </div>
        </div>
        <div className={classes.earningsTableContainer}>
          <LoadingSkeleton paddingBottom={48} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
          <LoadingSkeleton paddingBottom={24} />
        </div>
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    backgroundColor: theme.palette.background.base,
    padding: theme.spacing(4, 4),
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(4, 4, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 7),
    },
  },
  containerDiv: {
    padding: 0,
  },
  summaryRow: {
    display: 'grid',
    gridTemplateColumns: '2.5fr 1.5fr',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  leftSummarySection: {
    justifyContent: 'stretch',
    padding: '1.5rem 4.125rem 2.375rem 2rem',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    marginBottom: '2rem',
    width: '100%',
    height: '254px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: '0.5rem',
      padding: '1rem 2rem',
      height: '241px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '1rem 0.75rem',
    },
  },
  referralConfigBox: {
    padding: '2rem',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    marginBottom: '2rem',
    position: 'relative',
    height: '254px',
    [theme.breakpoints.down('sm')]: {
      height: '204px',
      display: 'block',
      marginBottom: '0.5rem',
      padding: theme.spacing(1.5),
    },
  },
  earningsTableContainer: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(3, 4),
    height: '328px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1.5, 0.5),
    },
  },
}))

export default ReferralsFallback