import { FeatureType } from 'js/constants/notification'
import { OnBlockConfirmation, TxLoadingTaskDetails, TxPhase } from 'js/models'


export enum ActionType {
  ADD_BACKGROUND_LOADING = 'LAYOUT_ADD_BACKGROUND_LOADING',
  REMOVE_BACKGROUND_LOADING = 'LAYOUT_REMOVE_BACKGROUND_LOADING',
  START_COUNTDOWN = 'LAYOUT_START_COUNTDOWN',
  REMOVE_TRANSACTION_LOADING = 'REMOVE_TRANSACTION_LOADING',
  SET_TRANSACTION_LOADING = 'SET_TRANSACTION_LOADING',
  UPDATE_TRANSACTION_PHASE = 'UPDATE_TRANSACTION_PHASE',
  CONFIRM_BROADCASTED_TRANSACTION = 'CONFIRM_BROADCASTED_TRANSACTION',
  SET_WS_INIT = 'SET_WS_INIT',
  RESET_WS_INIT = 'RESET_WS_INIT',
  REMOVE_WS_INIT = 'REMOVE_WS_INIT',
  QUEUE_TOAST = 'QUEUE_TOAST',
  ON_CLOSE_TOAST = 'ON_CLOSE_TOAST',
  ADD_TOAST = 'ADD_TOAST',
  UPDATE_PENDING_TO_ACTIVE_TOAST = 'UPDATE_PENDING_TO_ACTIVE_TOAST',
  DISMISS_ALL_ACTIVE_TOASTS = 'DISMISS_ALL_ACTIVE_TOASTS'
}


export interface UpdateTxPhase {
  name: string
  uuid: string
  phase: TxPhase
  featureType?: FeatureType,
  module?: string,
  txHash: string
  error?: Error
  onBlockConfirmation?: OnBlockConfirmation
  walletAddress?: string
  extras?: any
}
export interface SetTxLoading extends TxLoadingTaskDetails {
  name: string
  uuid: string
}
export interface RemoveTxLoading {
  name: string
  uuid: string
}

export interface Toast {
  id: string
  isActive: boolean
}
