import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination'
import dayjs from 'dayjs'
import { List, Record } from 'immutable'
import Long from 'long'
import { AnyAction } from 'redux'

import { Validator } from 'js/models/staking/Validator'
import { BN_ZERO } from 'js/utils/number'

import {
  StakeActionTypes, StakeState,
  StakeStateProps,
  Status,
} from './types'

const startStatus: Status = { bonded: true, unbonding: true, unbonded: false }
const rawstr = localStorage.getItem(StakeActionTypes.SET_STATUSES)

if (rawstr) {
  try {
    const parsed = JSON.parse(rawstr)
    Object.keys(parsed).forEach((key) => {
      if (['bonded', 'unbonded', 'unbonding'].includes(key)) startStatus[key as keyof Status] = parsed[key]
    })
  } catch {
    localStorage.setItem(StakeActionTypes.SET_STATUSES, JSON.stringify(startStatus))
  }
} else {
  localStorage.setItem(StakeActionTypes.SET_STATUSES, JSON.stringify(startStatus))
}


export const DefaultInitialState:
  Record.Factory<StakeStateProps> = Record<StakeStateProps>({
    statuses: startStatus,
    validators: List<Validator>(),
    delegations: [],
    unbondingDelegations: [],
    redelegations: [],
    delegationRewards: [],
    totalStaked: '0',
    usdPrice: '0',
    lastUpdatedAt: {
      validators: '0',
      delegations: '0',
      unbondingDelegations: '0',
      delegationRewards: '0',
      redelegations: '0',
    },
    aprStats: {
      avgBlockTime: BN_ZERO,
      avgReward: BN_ZERO,
      totalBonded: BN_ZERO,
      apyMap: {},
    },
    allianceAssets: [],
    validatorParticipations: [],
    proposals: [],
    avgBlockTime: null,
    totalDelegationRewardsUsd: BN_ZERO,
  })

const defaultInitialState: StakeState = new DefaultInitialState()

const currentTimeFormatted = () => dayjs().format('D-MMM-YYYY H:mm:ss').toString()

export const defaultPagination = PageRequest.fromPartial({
  limit: new Long(10000),
  offset: Long.UZERO,
  key: new Uint8Array(),
  countTotal: false,
  reverse: false,
})

export const stakeReducer = (
  state: StakeState = defaultInitialState,
  action: AnyAction,
): StakeState => {
  switch (action.type) {
    case StakeActionTypes.SET_STATUSES: {
      const statuses = action.payload
      const newState = state.set('statuses', statuses)
      localStorage.setItem(StakeActionTypes.SET_STATUSES, JSON.stringify(statuses))
      return newState
    }
    case StakeActionTypes.SET_STAKING_INFORMATION: {
      const { totalStaked, usdPrice } = action.payload
      let newState = state.set('totalStaked', totalStaked)
      newState = newState.set('usdPrice', usdPrice)
      return newState
    }
    case StakeActionTypes.SET_VALIDATORS: {
      const newState = state.set('validators', action.payload)
      return newState.setIn(['lastUpdatedAt', 'validators'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_APR_STATS: {
      return state.set('aprStats', action.payload)
    }
    case StakeActionTypes.SET_VALIDATOR_DELEGATIONS: {
      const newState = state.set('validators', action.payload)
      return newState.setIn(['lastUpdatedAt', 'validators'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_DELEGATIONS: {
      const newState = state.set('delegations', action.payload)
      return newState.setIn(['lastUpdatedAt', 'delegations'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_UNBONDING_DELEGATIONS: {
      const newState = state.set('unbondingDelegations', action.payload)
      return newState.setIn(['lastUpdatedAt', 'unbondingDelegations'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_REDELEGATIONS: {
      const newState = state.set('redelegations', action.payload)
      return newState.setIn(['lastUpdatedAt', 'redelegations'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_DELEGATION_REWARDS: {
      const newState = state.set('delegationRewards', action.payload)
      return newState.setIn(['lastUpdatedAt', 'delegationRewards'], currentTimeFormatted())
    }
    case StakeActionTypes.SET_VALIDATOR_PARTICIPATIONS: {
      return state.set('validatorParticipations', action.payload)
    }
    case StakeActionTypes.SET_PROPOSALS: {
      return state.set('proposals', action.payload)
    }
    case StakeActionTypes.SET_ALLIANCES: {
      return state.set('allianceAssets', action.payload)
    }
    case StakeActionTypes.SET_AVG_BLOCK_TIME: {
      return state.set('avgBlockTime', action.payload)
    }
    case StakeActionTypes.SET_TOTAL_DELEGATION_REWARDS_USD: {
      return state.set('totalDelegationRewardsUsd', action.payload)
    }
    default:
      return state
  }
}
