import BigNumber from 'bignumber.js'
import { TypeUtils, WSModels } from 'carbon-js-sdk'
import { EModeCategory } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/e_mode_category'
import { RecordOf } from 'immutable'

import { CDPAsset, CDPAccountView, Params, AccountData, Collateral, Debt } from 'types/cdp'

export interface CDPStateProps {
  /** @deprecated use accountView instead */
  readonly accountData: AccountData

  readonly params: Params

  readonly collaterals: Collateral[]
  readonly debts: Debt[]
  readonly cdpModuleAddressBalances: TypeUtils.SimpleMap<BigNumber>

  readonly cdpAssets: TypeUtils.SimpleMap<CDPAsset>
  readonly cdpAssetsAlias: TypeUtils.SimpleMap<string>


  readonly accountView?: CDPAccountView

  // cdp filters
  readonly hideZeroAvailable: boolean
  readonly hideZeroBalance: boolean
  // cdp rewards
  readonly rewardSchemes: TypeUtils.SimpleMap<WSModels.RewardScheme>
  readonly rewardDebts: TypeUtils.SimpleMap<WSModels.RewardDebt>
  readonly totalSupplyMap: TypeUtils.SimpleMap<BigNumber>

  // cdp form redirect url
  redirectUrl?: string

  // emode
  // readonly emodeCategories: EModeCategory[]
  readonly emodeCategories: Record<EModeCategory['name'], EModeCategory>
  readonly emodeCategory?: EModeCategory
}

export type CDPState = RecordOf<CDPStateProps>

export const CDPActionTypes = {
  INIT_CDP_STATE: '@cdp/INIT_CDP_STATE',
  SET_ASSETS: '@cdp/SET_ASSETS',
  SET_PARAMS: '@cdp/SET_PARAMS',
  SET_COLLATERALS: '@cdp/SET_COLLATERALS',
  SET_DEBTS: '@cdp/SET_DEBTS',
  QUERY_ASSETS: '@cdp/QUERY_ASSETS',
  QUERY_PARAMS: '@cdp/QUERY_PARAMS',
  SET_CDP_MODULE_BALANCES: '@cdp/SET_CDP_MODULE_BALANCES',
  UPDATE_CDP_ASSET: '@cdp/UPDATE_CDP_ASSET',
  UPDATE_CDP_ASSET_ALIAS: '@cdp/UPDATE_CDP_ASSET_ALIAS',
  UPDATE_ACCOUNT_VIEW: '@cdp/UPDATE_ACCOUNT_VIEW',
  // cdp filters
  SET_HIDE_ZERO_BALANCE: '@cdp/SET_HIDE_ZERO_BALANCE',
  // cdp rewards
  SET_REWARD_SCHEMES: '@cdp/SET_REWARD_SCHEMES',
  SET_REWARD_DEBTS: '@cdp/SET_REWARD_DEBTS',
  SET_TOTAL_SUPPLY_MAP: '@cdp/SET_TOTAL_SUPPLY_MAP',
  RELOAD_TOTAL_SUPPLY_MAP: '@cdp/RELOAD_TOTAL_SUPPLY_MAP',
  // cdp form redirect url
  SET_CDP_FORM_REDIRECT_URL: '@cdp/SET_CDP_FORM_REDIRECT_URL',
  // emode
  QUERY_EMODE_CATEGORIES: '@cdp/QUERY_EMODE_CATEGORIES',
  SET_EMODE_CATEGORIES: '@cdp/SET_EMODE_CATEGORIES',
  QUERY_EMODE_CATEGORY: '@cdp/QUERY_EMODE_CATEGORY',
  SET_EMODE_CATEGORY: '@cdp/SET_EMODE_CATEGORY',
}

export const CDPQueryTasks: TypeUtils.SimpleMap<string> = {
  CDPAssets: 'cdp-assets-info',
  CDPEModeCategory: 'cdp-emode-category',
  CDPEModeCategories: 'cdp-emode-categories',
  CDPAccountView: 'cdp-account-view',
}
