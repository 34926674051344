import { SvgIcon, Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { ReactElement } from "react"

interface Props {
  icon?: any
  customDivIcon?: any
  label: string | ReactElement
  hoverComponent?: any
  onClick?: () => void
  customIconClassName?: string
  customClassName?: string
}

const BottomButton: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { icon, label, hoverComponent, onClick, customIconClassName, customClassName, customDivIcon } = props

  return (
    <div className={clsx(classes.utilButton, typeof onClick === 'function' && classes.clickable, customClassName)} onClick={onClick}>
      {icon && <div className={clsx(classes.buttonIcon, customIconClassName)}>
        <SvgIcon className={classes.svgIcon} component={icon} />
      </div>}
      {customDivIcon && (
        <React.Fragment>
          {customDivIcon}
        </React.Fragment>
      )}
      <Typography className={classes.buttonLabel}>{label}</Typography>
      <div className={classes.hoverComponent}>
        {hoverComponent}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  utilButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 0),
    '&:hover .hoverComponent': {
      opacity: 1,
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    }
  },
  clickable: {
    cursor: 'pointer'
  },
  hoverComponent: {
    opacity: 0,
    height: 0,
  },
  buttonLabel: {
    ...theme.typography.body4,
    display: 'flex',
    alignItems: 'center',
    '& span svg': {
      height: '12px !important',
      width: '12px !important',
      position: 'relative',
      top: '1px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      lineHeight: '11px',
    }
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  svgIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}))

export default BottomButton