import { SimpleMap } from 'js/utils'
import { AuthDialog, groupNotifications, Inbox, InboxSectionsNotifs, Preference, Status, TelegramDialog, UserProfile, VerificationStatus, VerificationStatusResponse, VerificationType } from 'js/utils/alerts'

import { UpdateInboxStatusPayload } from './types'

import { RootState } from '../rootReducer'

export function getUserProfile(state: RootState): UserProfile | null {
  return state.alerts.profile
}

export function getUserPreferences(state: RootState): Preference[] | null {
  return state.alerts.preferences
}

export function getOptimisticUserPreferences(state: RootState): Preference[] | null {
  return state.alerts.optimisticPreferences
}

export function getAuthDialog(state: RootState): AuthDialog | null {
  return state.alerts.authDialog
}

export function getTelegramDialog(state: RootState): TelegramDialog | null {
  return state.alerts.telegramDialog
}

export function getDisplayStatus(section: string): (state: RootState) => boolean {
  return (state: RootState) => {
    return !!state.alerts.displayStatus?.[section]
  }
}

export function getVerificationStatus(type: VerificationType): (state: RootState) => VerificationStatus | undefined {
  return (state: RootState) => {
    return state.alerts.verification?.[type.toString()]
  }
}

export function getAccountVerified(state: RootState): boolean {
  return !!state.alerts.accountVerified
}

export function getVerification(state: RootState): VerificationStatusResponse | null {
  return state.alerts.verification
}


export function getInbox(state: RootState): Map<number, Inbox> | null {
  return state.alerts.inbox
}

export function getOptimisticInbox(state: RootState): Map<number, Inbox> | null {
  return state.alerts.optimisticInbox
}


export function getGroupedInboxNotifications(): (state: RootState) => InboxSectionsNotifs {
  return (state: RootState) => (groupNotifications(state.alerts.optimisticInbox))
}

export function getTotalNewCount(): (state: RootState) => number {
  return (state: RootState) => {
    const groupedNotifs = groupNotifications(state.alerts.optimisticInbox)
    return Object.values(groupedNotifs).reduce((acc, { counts }) => acc += counts[Status.New], 0)
  }
}


export function getPendingInboxUpdates(state: RootState): SimpleMap<UpdateInboxStatusPayload[]> | null {
  return state.alerts.pendingInboxUpdates
}

export function getPendingPreferencesUpdates(state: RootState): SimpleMap<Preference[]> | null {
  return state.alerts.pendingPreferencesUpdates
}
