import { Card, Container, Divider, Grid, Hidden, makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

import WalletCard from "js/components/Account/WalletCard"
import LoadingSkeleton from "js/components/Common/LoadingSkeleton/LoadingSkeleton"
import { useCommonStyles } from "js/utils"

interface Props extends React.HTMLAttributes<HTMLDivElement> {

}

const DepositWithdrawFallback: React.FC<Props> = (props: Props) => {
  const { className, children, ...rest } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className="outerBoxContainer">
        <div className={classes.boxContainer}>
          <Hidden smDown>
            <Container className={clsx(commonClasses.justifyContentStart, classes.tabsBoxContainer, 'backRow')} maxWidth="md">
            </Container>
            <Divider className={classes.divider} />
            <Container className={classes.currencySelectContainer} maxWidth="md">
              <Card
                elevation={0}
                className={classes.currencySelectRoot}
              >
                <div className={classes.dropdownBox}>
                  <LoadingSkeleton />
                </div>
                <Divider orientation="vertical" className={classes.currencySelectDivider} flexItem />
                <div className={clsx(classes.balanceBox, commonClasses.alignItemsCenter)}>
                  <LoadingSkeleton />
                </div>
              </Card>
            </Container>
          </Hidden>
          <Container className={classes.container} maxWidth="md">
            <div className={clsx(classes.content, commonClasses.flexRow)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container className={classes.parentGrid}>
                    <Grid item xs={12} md={5}>
                      <div className={classes.selectNetworkRoot}>
                        <WalletCard className={classes.firstCard}>
                          <LoadingSkeleton width={200} paddingBottom={48} />
                          <LoadingSkeleton paddingBottom={24} />
                          <LoadingSkeleton paddingBottom={24} />
                          <LoadingSkeleton />
                        </WalletCard>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <WalletCard className={classes.secondCard}>
                        <LoadingSkeleton width={200} />
                        <br /><br />
                        <LoadingSkeleton paddingBottom={24} />
                        <LoadingSkeleton paddingBottom={24} />
                        <LoadingSkeleton />
                      </WalletCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div >
    </div >
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.base,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(6.5),
    },
  },
  boxContainer: {
    backgroundColor: theme.palette.background.primary,
    margin: theme.spacing(4),
    marginBottom: theme.spacing(10),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 0, 5.75),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(1, 0, 0),
      backgroundColor: 'transparent',
    },
    minHeight: 'calc(100vh - 48px - 28px - 32px - 64px)', // menu bar, footer, box padding, box margin
  },
  tabsBoxContainer: {
    maxWidth: 'unset',
    padding: theme.spacing(0, 4),
    '@media (min-width: 960px) and (max-width: 1190px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'column',
      maxWidth: '59rem',
    },
    '@media (min-width: 1280px) and (max-width: 1400px)': {
      padding: theme.spacing(0, 3),
      flexDirection: 'column',
      maxWidth: '60rem',
    },
    '&.backRow': {
      height: '81px',
    },
  },
  currencySelectRoot: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'visible',
    minHeight: '4.125rem',
  },
  currencySelectContainer: {
    padding: theme.spacing(4, 3, 0),
  },
  dropdownBox: {
    maxWidth: 'calc(100% / 12 * 5)',
    width: '100%',
    padding: theme.spacing(1.5, 2.5),
  },
  currencySelectDivider: {
    marginTop: 8,
    marginBottom: 8,
  },
  balanceBox: {
    padding: theme.spacing(1.5, 2.5),
    maxWidth: 'calc(100% / 12 * 7)',
    width: '100%',
  },
  content: {
    padding: theme.spacing(2.5, 0, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  parentGrid: {
    '& > div': {
      padding: theme.spacing(1.5),
    },
    width: 'calc(100% + 24px)',
    margin: -12,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0, 1),
      '& > div': {
        padding: theme.spacing(0, 1.5, 1),
      },
    },
  },
  selectNetworkRoot: {
    border: 'none',
    borderRadius: '4px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      backgroundColor: theme.palette.background.primary,
    },
  },
  firstCard: {
    minHeight: '399px',
    [theme.breakpoints.only('sm')]: {
      backgroundColor: theme.palette.background.primary,
      padding: theme.spacing(2, 4, 4),
    },
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.background.primary,
      padding: theme.spacing(3),
    },
    '@media (max-width: 359px)': {
      padding: theme.spacing(3, 2),
    },
  },
  secondCard: {
    minHeight: '399px',
    height: '100%',
    [theme.breakpoints.only('sm')]: {
      backgroundColor: theme.palette.background.primary,
      padding: theme.spacing(2, 4, 4),
    },
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.background.primary,
      padding: theme.spacing(3),
    },
    '@media (max-width: 359px)': {
      padding: theme.spacing(3, 2),
    },
  },
}))

export default DepositWithdrawFallback