import { CarbonSDK, CarbonWallet, Insights } from 'carbon-js-sdk'
import { Bridge } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/bridge'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { DenomTrace } from 'carbon-js-sdk/lib/codec/ibc/applications/transfer/v1/transfer'
import { ExtendedChainInfo } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import { StoredMnemonicInfo } from 'carbon-js-sdk/lib/provider/metamask/MetaMask' // eslint-disable-line import/no-unresolved
import { Map } from 'immutable'
import { isAndroid, isChrome, isFirefox, isIOS, isSafari } from 'react-device-detect'
import { createSelector } from 'reselect'

import { OracleInfo } from 'js/constants/chart'
import { FeeDropDownItem } from 'js/constants/fees'
import { AdditionalIbcTokenList, CustomNodeItem, DemexConfig, GroupTokenDetails, MinGasPricesMap, PWABeforeInstallPromptEvent, Page, ThirdPartyAPIStatuses, TransactionStatus } from 'js/state/modules/app/types'
import { getDefaultNetwork, isBrave, isInStandaloneMode } from 'js/utils/environment'
import { NodeFormState, RatingLatencyObj } from 'js/utils/nodes'
import { HiddenBanners, SimpleMap } from 'js/utils/types'

import ReferralClient from './helpers/ReferralClient'

import { RootState } from '../rootReducer'

export function getSubPage(state: RootState): Page {
  return state.app.subPage
}

export function getAppVersion(state: RootState): string {
  return state.app.appVersion
}

export function getChainInfoMapError(state: RootState): string {
  return state.app.errorChainInfoMap
}

export function getWalletToggle(state: RootState): boolean {
  return state.app.walletToggle
}

export function getThemeType(state: RootState): 'dark' | 'light' {
  return state.app.themeType
}

export function getNet(state: RootState): CarbonSDK.Network {
  return state.app.carbonSDK?.network ?? getDefaultNetwork()
}

export function getCarbonSDK(state: RootState): CarbonSDK | undefined {
  return state.app.carbonSDK
}

export function getConnectedWalletAddress(state: RootState): string | undefined {
  return state.app.carbonSDK?.wallet?.bech32Address
}

export function getSDKWallet(state: RootState): CarbonWallet | undefined {
  return state.app.carbonSDK?.wallet
}

export function getUserMnemonic(state: RootState): string | undefined {
  return state.app.userMnemonic
}

export function getCachedWallet(state: RootState): string | undefined {
  return state.app.connectedAgent
}

export function getIsRainbowKit(state: RootState): boolean | undefined {
  return state.app.isRainbowKit
}

export function getTokens(state: RootState): Token[] {
  return state.app.tokens
}

export const getTokensAsMap = createSelector(
  [getTokens],
  (tokens: Token[]): Map<string, Token> => Map(tokens.map((token) => [token.denom, token])),
)
export function getNewTokenDenoms(state: RootState): Set<string> {
  return state.app.newTokenDenoms
}

export function getIsSigningModalShown(state: RootState): boolean {
  return state.app.isSigningModalShown
}

export function getBlockHeight(state: RootState): number {
  return state.app.blockHeight
}

export function getBlockStatus(state: RootState): boolean {
  return state.app.blocksMoving
}

export function getShowEditLeverageDialog(state: RootState): boolean {
  return state.app.showEditLeverageDialog
}

export function getHideConvertDialog(state: RootState): string[] {
  return state.app.hideConvertDialog
}

export function getOpenMenu(state: RootState): boolean {
  return state.app.openMenu
}

export function getOpenMoreMenu(state: RootState): boolean {
  return state.app.openMoreMenu
}

export function getOpenNotificationMenu(state: RootState): boolean {
  return state.app.openNotificationMenu
}

export function getPromoRegistered(state: RootState): boolean {
  return state.app.promoRegistered
}

export function getOpenNodeMenu(state: RootState): boolean {
  return state.app.openNodeMenu
}

export function getOpenDisplayMenu(state: RootState): boolean {
  return state.app.openDisplayMenu
}

export function getOpenHelpMenu(state: RootState): boolean {
  return state.app.openHelpMenu
}

export function getNodes(state: RootState): Insights.NodeItem[] {
  return state.app.nodes
}

export function getFeeToggle(state: RootState): boolean {
  return state.app.feeOpen
}

export function getShowNodeInfoForm(state: RootState): boolean {
  return state.app.isShowNodeInfoForm
}

export function getCustomNodes(state: RootState): CustomNodeItem[] {
  return state.app.customNodes
}

export function getSelectedNodes(state: RootState): SimpleMap<any> | undefined {
  return state.app.selectedNodes
}

export function getAutoSelectNode(state: RootState): boolean {
  return state.app.autoSelectNode
}

export function getFormNode(state: RootState): NodeFormState | undefined {
  return state.app.formNode
}

export function getConnectError(state: RootState): Error | undefined {
  return state.app.connectError
}

export function getInternetConnected(state: RootState): boolean {
  return state.app.internetConnected
}

export function getBridges(state: RootState): Bridge[] {
  return state.app.bridges
}

export function getRatingLatency(state: RootState): RatingLatencyObj {
  return state.app.latency
}

export function getMinGasPricesMap(state: RootState): MinGasPricesMap {
  return state.app.gasPricesMap
}

export function getCachedWalletLoading(state: RootState): boolean {
  return state.app.cachedWalletLoading
}

export function getShowMobilePromo(state: RootState): boolean {
  return state.app.showMobilePromo
}

export function getGroupTokens(state: RootState): SimpleMap<GroupTokenDetails> {
  return state.app.groupTokensMap
}

export function getDebugMode(state: RootState): boolean {
  return state.app.debugMode
}

export function getDemexConfig(state: RootState): DemexConfig {
  return state.app.demexConfig
}

export function getConfettiState(state: RootState): boolean {
  return state.app.confettiState
}

export function getDisableConfetti(state: RootState): boolean {
  return state.app.disableConfetti
}

export function getIsLoggingIn(state: RootState): boolean {
  return state.app.loggingIn
}

export function getLegacyAccounts(state: RootState): StoredMnemonicInfo[] {
  return state.app.legacyAccounts
}

export function getIsLegacyLogin(state: RootState): boolean {
  return state.app.isLegacyLogin
}

export function getShowActivateAccountDialog(state: RootState): boolean {
  return state.app.showActivateAccountDialog
}

export function getActivateAccountTxStatus(state: RootState): TransactionStatus {
  return state.app.activateAccountTxStatus
}

export function getSubsequentTxStatus(state: RootState): TransactionStatus {
  return state.app.subsequentTxStatus
}

export function getDenomTraces(state: RootState): SimpleMap<DenomTrace> {
  return state.app.denomTraces
}

export function getAdditionalIbcTokens(state: RootState): AdditionalIbcTokenList {
  return state.app.additionalIbcTokens
}

export function getDenomTracesHash(state: RootState): string | null {
  return state.app.denomTracesHash
}

export function getChainInfoMap(state: RootState): SimpleMap<ExtendedChainInfo> {
  return state.app.chainInfoMap
}


export function getThirdPartyAPIStatuses(state: RootState): ThirdPartyAPIStatuses {
  return state.app.thirdPartyAPIStatuses
}

export function getReferralClient(state: RootState): ReferralClient | undefined {
  return state.app.referralClient
}

export function getShowFeedbackBanner(state: RootState): boolean {
  return state.app.showFeedbackBanner
}

export function getPwaInstallPrompt(state: RootState): PWABeforeInstallPromptEvent | null {
  return state.app.pwaInstallPrompt
}

export function getOpenPwaInstallDialog(state: RootState): boolean {
  return state.app.openPwaInstallDialog
}

export const getShowPWAInstallIcon = createSelector(
  [getPwaInstallPrompt],
  (prompt: PWABeforeInstallPromptEvent | null): boolean => {
    // PWA not supported in firefox
    if (isInStandaloneMode() || isFirefox) return false
    // Brave doesnt download pwa as pwa on the phone
    if (isAndroid && isBrave()) return false
    if (isIOS && (isSafari || isChrome)) return true
    return !!prompt
  }
)
export function getShowPromoBanner(state: RootState): boolean {
  return state.app.showPromoBanner
}

export function getShowSignlessSettingsDialog(state: RootState): boolean {
  return state.app.showSignlessSettingsDialog
}

export function getOracleInfo(state: RootState): OracleInfo[] {
  return state.app.oracleInfo
}

export function getShowPwaUpdateDialog(state: RootState): boolean {
  return state.app.showPwaUpdateDialog
}

export function getIsPwaUpdateToastQueued(state: RootState): boolean {
  return state.app.queuePwaUpdateToast
}

export function getHiddenBanners(state: RootState): HiddenBanners {
  return state.app.hiddenBanners
}

export function getCurrentFeeSettingsDropdown(state: RootState): FeeDropDownItem | null {
  return state.app.currentFeeSettingsDropdown
}

export function getCrossSellingSourceTokens(state: RootState): string[] {
  return state.app.demexConfig.crossSellingSourceTokens
}

export function getHiddenTypeformWidgets(state: RootState): string[] {
  return state.app.hiddenTypeformWidgets
}
