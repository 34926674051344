import { CarbonSDK } from 'carbon-js-sdk'

import { usdGroupedToken } from './assets'

const Paths = {
  Home: '/',

  Login: {
    Main: '/login',
    Ledger: '/login/ledger',
    MetaMask: '/login/metamask',
    EncryptedKey: '/login/encrypted_key',
    KeplrWallet: '/login/keplr',
    LeapWallet: '/login/leap',
    O3Wallet: '/login/o3',
  },

  PasswordReset: '/reset_password',
  Register: '/register',
  Account: {
    Home: '/account',
    Overview: '/account/overview',
    Profile: '/account/profile',
    Balance: '/account/balance',
    Deposit: '/account/balance/deposit',
    Withdraw: '/account/balance/withdraw',
    Transfer: {
      Crosschain: {
        v1: '/account/transfers',
        v2: '/account/transfers/crosschain',
      },
      Internal: {
        v1: '/account/internal_transfers',
        v2: '/account/transfers/internal',
      },
      CarbonEVM: '/account/transfers/carbon-evm'
    },
    TransferDetail: '/account/transfers/crosschain/detail',
    Order: {
      v1: '/account/orders',
      v2: '/account/history/orders',
    },
    OrderDetails: '/account/history/orders/details',
    Trade: {
      v1: '/account/trades',
      v2: '/account/history/trades',
    },
    TradeDetails: '/account/history/trades/details',
    Funding: '/account/history/funding',
    Convert: '/account/convert',
    Settings: '/account/settings',
    AddressManagement: '/account/address/management',
    Referrals: '/account/referrals',
    ReferralsEarnings: '/account/referrals/earnings',
    ReferralsSettings: '/account/referrals/settings',
    Subaccounts: '/account/subaccounts/list',
  },

  Errors: {
    Maintenance: '/maintenance',
    Error404: '/error_404',
  },

  Profile: '/profile',

  Trade: {
    Main: '/trade',
    TradePage: '/exchange',
    RecentTrades: '/trade/recent_trades',
    SwthUsd: '/trade/SWTH/USD',
    ScrtPerp: '/trade/SCRT-PERP',
    EthPerp: '/trade/ETH-PERP',
    TiaPerp: '/trade/TIA-PERP',
  },

  Lite: '/lite',

  Swap: {
    Home: '/swap',
    SWTH: '/swap/SWTH/USD',
  },
  Stake: {
    List: '/stake',
    LegacyStake: '/legacy/stake',
    Add: '/stake/add/:address',
    Manage: '/stake/manage',
    Unstake: '/stake/unstake/:details',
    Transfer: '/stake/transfer/:details',
    FAQ: '/stake/faq',
  },


  Pools: {
    List: '/pools',
    PerpList: '/pools/perp',
    SpotList: '/pools/spot',
    Manage: '/pools/manage',
    TransactionDetail: '/pools/manage/detail',
    Create: '/pools/create',
    AddLiquidity: '/pools/add/:pool_denom',
    RemoveLiquidity: '/pools/remove/:pool_denom',
    CommitTokens: '/pools/commit/:pool_denom',
    ExtendCommit: '/pools/extend/:pool_denom',
    UnlockTokens: '/pools/unlock/:pool_denom',
  },


  Competition: {
    Main: '/competition',
    SignUp: '/competition/registration',
    MegaMarathon: '/marathon',
    Leaderboard: '/marathon/competition',
    Promotion: '/marathon/promotion',
  },

  /** @deprecated retained for redirection */
  LegacyPaths: {
    PromotionHub: {
      Main: '/promotions',
      RewardsHistory: '/promotions/rewards',
      Referrals: '/promotions/referrals',
      ReferralsEarnings: '/promotions/referrals/earnings',
      ReferralsSettings: '/promotions/referrals/settings',
      SwapPromotion: '/promotions/swappromo',
      FlashPromotion: '/promotions/flashpromo',
      MobilePromotion: '/promotions/mobilepromo',
      EarnAThon: '/promotions/earnathon',
      KingOfTheHill: '/promotions/kingofthehill',
      PerpsTradingChallenge: '/promotions/perps-trading-challenge',
    },
    EarnAThon: '/earnathon',
    FlashPromotion: '/promotion/flashpromo',
    MobilePromotion: '/promotion/mobilepromo',
    Liquidations: {
      Main: '/liquidations',
      RiskyDetails: '/liquidations/risky/:borrower_address',
      Repay: '/liquidations/repay/:debtor',
    },
  },

  Rewards: {
    Main: '/rewards',
    DemexPoints: {
      Main: '/rewards/demex-points/main',
      Tabs: '/rewards/demex-points/main/:tab?',
      Spin: '/rewards/demex-points/spin',
    },
    RewardsHistory: '/rewards/history',
    Referrals: '/rewards/referrals',
    ReferralsEarnings: '/rewards/referrals/earnings',
    ReferralsSettings: '/rewards/referrals/settings',
    SwapPromotion: '/rewards/swappromo',
    FlashPromotion: '/rewards/flashpromo',
    MobilePromotion: '/rewards/mobilepromo',
    EarnAThon: '/rewards/earnathon',
    KingOfTheHill: '/rewards/kingofthehill',
    PerpsTradingChallenge: '/rewards/perps-trading-challenge',
    ScrtTradingLeague: '/rewards/scrt-trading-league',
    CarbonCreditsTradingLeague: '/rewards/carbon-credits',
    CarbonCreditsPnl: '/rewards/carbon-credits/pnl',
    ArchwayTradingLeague: '/rewards/archway-trading-league',
    CosmosTradingCompetition: '/rewards/cosmos-trading-competition',
    StayConnectedPromotion: '/rewards/stay-connected',
    LossProtection: '/rewards/loss-protection-programme',
  },

  LeaderBoard: '/leaderboard',

  Markets: {
    List: '/markets',
    Create: '/markets/create',
    Import: '/markets/import',
  },

  Nitron: {
    Main: '/nitron',
    Lend: '/nitron/lend/:denom',
    Withdraw: '/nitron/withdraw/:denom',
    Borrow: '/nitron/borrow/:denom',
    Mint: '/nitron/mint/:denom',
    Return: '/nitron/return/:denom',
    Edit: '/nitron/edit/:denom',
    EMode: '/nitron/emode',
    Liquidations: '/nitron/liquidations',
    LiquidationRiskyDetails: '/nitron/liquidations/risky/:borrower_address',
    LiquidationRepay: '/nitron/liquidations/repay/:debtor',
  },

  Vaults: {
    Manage: '/vaults/:id/:tab?',
  },

  LaunchVaults: {
    Main: '/launch-vaults',
    Manage: '/launch-vaults/:bonusTierId/:tab?',
  },

  FluoDashboard: {
    Main: '/fluo-dashboard',
    Stake: '/fluo-dashboard/stake',
    Unstake: '/fluo-dashboard/unstake',
    Boost: '/fluo-dashboard/boost',
  },

  Strategy: {
    Main: '/strategy',
    Deposit: '/strategy/deposit',
    Withdraw: '/strategy/withdraw',
    GLPWrapper: '/strategy/glp-compounder',
  },

  WelcomeOffer: '/welcome',

  Alerts: {
    Main: '/alerts',
    EmailVerification: '/alerts/verify/email/:address'
  }
}

export default Paths

export const DEFAULT_DEPOSIT_LINK = `/account/balance/deposit/${usdGroupedToken}` // default link to deposit USD

export const TOKEN_ICON_REPO_URL = 'https://raw.githubusercontent.com/Switcheo/token-icons/main/:type/:token_name.:file_type'

export const CARBON_CREDITS_CLAIM_WORKAROUND_API = 'https://lsi-index.carbon.network/claim'

export const SWTH_REWARDS_RECORDS_REPO_URL = {
  [CarbonSDK.Network.MainNet]: 'https://raw.githubusercontent.com/Switcheo/swth-rewards-records/master/rewards.json',
  [CarbonSDK.Network.TestNet]: 'https://raw.githubusercontent.com/Switcheo/swth-rewards-records/master/testnet_rewards.json'
}

export const DEMEX_POINTS_LEADERBOARD_API = 'https://lsi-index.carbon.network/points/leaderboard'

export const DEMEX_POINTS_USER_API = 'https://lsi-index.carbon.network/points/user?address='

export const DEMEX_POINTS_SPIN_API = 'https://lsi-index.carbon.network/points/spins'
