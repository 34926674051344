import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getBlockHeight, getNet } from 'js/state/modules/app/selectors'

import TextButton from '../TextButton'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  labelClassName?: string
}
const BlockHeight: React.FC<Props> = (props: Props) => {
  const { children, className, labelClassName, ...rest } = props
  const classes = useStyles()
  const height = useSelector(getBlockHeight)
  const net = useSelector(getNet)

  const carbonExplorer = useMemo(() => `https://scan.carbon.network/dashboard?net=${net}`, [net])

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      Block:
      <TextButton
        label={height}
        href={carbonExplorer}
        target="_blank"
        labelClass={clsx(classes.label, labelClassName)}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  label: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
  },
}))


export default BlockHeight
