/* eslint-disable camelcase */
import BigNumber from 'bignumber.js'
import { CarbonTx, TypeUtils, WSModels } from 'carbon-js-sdk'
import { PoolRoute } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/liquiditypool'
import { CommitmentCurve, RewardCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { AminoValueMap, ConvertEncType } from 'carbon-js-sdk/lib/provider/amino/utils' // eslint-disable-line import/no-unresolved
import { RecordOf } from 'immutable'

import { RewardCoin } from 'js/constants/liquidityPools'
import { Pool } from 'js/models/Pool'
import { SimpleMap } from 'js/utils'
import { BN_ZERO } from 'js/utils/number'

export interface PoolsStateProps {
  pools: SimpleMap<Pool>
  poolRoutes?: TypeUtils.SimpleMap<PoolRoute[]>,
  poolStakedInfoMap: PoolStakeInfoMap
  poolsWeeklyRewards: BigNumber
  poolsWeeklyRewardsRealInflation: BigNumber
  poolsUnclaimedRewards: UnclaimedRewardsMap
  poolsUnclaimedUsd: UnclaimedRewardsData
  poolsTransactions: PoolTransaction[]
  poolVolumes: TypeUtils.SimpleMap<BigNumber>
  txFilter: TxFilter
  poolCommitmentCurve: CommitmentCurve
  poolRewardCurve: RewardCurve
  distributedRewards: DistributedRewardsMap
  showZeroRewards: boolean
  showSmallPools: boolean
  showUnlinkedPools: boolean
  totalCommitObj: TotalCommitObj
  poolsRewardCoins: RewardCoin[]
  totalPoolRewardsUsd: BigNumber
  distributionParams?: DistributionParams
}

export interface DistributionParams {
  communityTax: BigNumber;
  baseProposerReward: BigNumber;
  bonusProposerReward: BigNumber;
  liquidityProviderReward: BigNumber;
  withdrawAddrEnabled: boolean;
}

export interface PoolStakeInfoMap {
  [index: string]: WSModels.Commitment
}

export interface PoolTransaction {
  hash: string,
  msgType: string,
  blockTime: number,
  message: TypeUtils.SimpleMap<any>
}

export type PoolsState = RecordOf<PoolsStateProps>

export const PoolsActionTypes = {
  SET_POOLS: '@pools/SET_POOLS',
  SYNC_POOLS: '@pools/SYNC_POOLS',
  SET_POOL_ROUTES: '@pools/SET_POOL_ROUTES',
  SET_UNCLAIMED_REWARDS: '@pools/SET_UNCLAIMED_REWARDS',
  SET_UNCLAIMED_USD_MAP: '@pools/SET_UNCLAIMED_USD_MAP',
  RELOAD_UNCLAIMED_REWARDS: '@pools/RELOAD_UNCLAIMED_REWARDS',
  SET_WEEKLY_POOLS_REWARDS_REAL_INFLATION: '@pools/SET_WEEKLY_POOLS_REWARDS_REAL_INFLATION',
  SET_POOLS_TRANSACTIONS: '@pools/SET_POOLS_TRANSACTIONS',
  SET_COMMITMENT_CURVE: '@pools/SET_COMMITMENT_CURVE',
  SET_REWARD_CURVE: '@pools/SET_REWARD_CURVE',
  SET_DISTRIBUTED_REWARDS: '@pools/SET_DISTRIBUTED_REWARDS',
  SET_DISTRIBUTION_PARAMS: '@pools/SET_DISTRIBUTION_PARAMS',
  ADD_STAKE_INFO: '@pools/ADD_STAKE_INFO',
  SYNC_STAKE_INFO: '@pools/SYNC_STAKE_INFO',
  SET_POOL_VOLUMES: '@pools/SET_POOL_VOLUMES',

  INIT_POOLS: '@pools/INIT_POOLS',
  UPDATE_POOLS: '@pools/UPDATE_POOLS',

  QUERY_WEEKLY_POOLS_REWARDS: '@pools/QUERY_WEEKLY_POOLS_REWARDS',
  QUERY_POOLS_TRANSACTIONS: '@pools/QUERY_POOLS_TRANSACTIONS',
  SUBMIT_LINK_POOL: '@pools/SUBMIT_LINK_POOL',
  SET_SHOW_ZERO_REWARDS_FALSE: '@pools/SET_SHOW_ZERO_REWARDS_FALSE',
  SET_SHOW_ZERO_REWARDS_TRUE: '@pools/SET_SHOW_ZERO_REWARDS_TRUE',
  SET_SHOW_REWARDS: '@pools/SET_SHOW_REWARDS',
  SET_SHOW_SMALL_POOLS_FALSE: '@pools/SET_SHOW_SMALL_POOLS_FALSE',
  SET_SHOW_SMALL_POOLS_TRUE: '@pools/SET_SHOW_SMALL_POOLS_TRUE',
  SET_SHOW_SMALL_POOLS: '@pools/SET_SHOW_SMALL_POOLS',
  SET_SHOW_UNLINKED_POOLS: '@pools/SET_SHOW_UNLINKED_POOLS',
  SET_TOTAL_COMMIT_OBJ: '@pools/SET_TOTAL_COMMIT_OBJ',
  SET_POOLS_REWARD_COINS: '@pools/SET_POOLS_REWARD_COINS',
  SET_TOTAL_POOL_REWARDS_USD: '@pools/SET_TOTAL_POOL_REWARDS_USD',
  RELOAD_LIQUIDITY_POOL_DATA: '@pools/RELOAD_LIQUIDITY_POOL_DATA',
  RELOAD_QUERY_POOL_VOLUME: '@pools/RELOAD_QUERY_POOL_VOLUME',
  RELOAD_QUERY_WEEKLY_POOL_REWARDS: '@pools/RELOAD_QUERY_WEEKLY_POOL_REWARDS',
  RELOAD_COMMITMENT_CURVE: '@pools/RELOAD_COMMITMENT_CURVE',
  RELOAD_REWARD_CUVE: '@pools/RELOAD_REWARD_CUVE',
  RELOAD_DISTRIBUTED_REWARDS: '@pools/RELOAD_DISTRIBUTED_REWARDS',
  RELOAD_TOTAL_COMMIT_TOKEN: '@pools/RELOAD_TOTAL_COMMIT_TOKEN',
  RELOAD_DISTRIBUTION_PARAMS: '@pools/RELOAD_DISTRIBUTION_PARAMS',
}

// Responses from API

export type UnclaimedRewardsMap = TypeUtils.SimpleMap<DecCoin[]>

export type UnclaimedRewardsData = TypeUtils.SimpleMap<BigNumber>

export type DistributedRewardsMap = TypeUtils.SimpleMap<BigNumber>

export interface WSCommitmentEvent {
  Type: 'new' | 'delete'
  amount: string // string representation of number
  boost_factor: string // string representation of number
  commitment_power: string // string representation of number
  denom: string
  duration?: number
  end_time: string // string representation of date isostring
  is_locked: boolean
  start_time: string // string representation of date isostring
}

export type WSCommitEvtMap = {
  [id: number]: WSCommitmentEvent
}

// Tasknames
export const PoolsTaskNames = {
  PoolsList: 'list-pools',
  PoolsTransactions: 'transactions-pools',
  UnclaimedRewards: 'unclaimed-rewards-pools',
  WeeklyPoolRewards: 'weekly-pool-rewards',
  WeeklyRewards: 'weekly-rewards',
  StakedTokens: 'staked-tokens-pools',
  DistributedRewards: 'distributed-rewards',
  CommitmentCurve: 'commitment-curve',
  PoolVolumes: 'pool-volumes',
}

export enum TxFilter {
  Create = 'create_pool',
  Add = 'add_liquidity',
  Remove = 'remove_liquidity',
  Stake = 'stake_pool_token',
  Unstake = 'unstake_pool_token',
  Claim = 'claim_pool_rewards',
  All = 'all',
}

export const TxUrlMap: {
  [key: string]: string
} = {
  [TxFilter.Create]: CarbonTx.Types.MsgCreatePoolWithLiquidity,
  [TxFilter.Add]: CarbonTx.Types.MsgAddLiquidity,
  [TxFilter.Remove]: CarbonTx.Types.MsgRemoveLiquidity,
  [TxFilter.Stake]: CarbonTx.Types.MsgStakePoolToken,
  [TxFilter.Unstake]: CarbonTx.Types.MsgUnstakePoolToken,
  [TxFilter.Claim]: CarbonTx.Types.MsgClaimPoolRewards
}

const CreatePoolMap: AminoValueMap = {
  tokenAWeight: ConvertEncType.Dec,
  tokenBWeight: ConvertEncType.Dec,
  amountA: ConvertEncType.Dec,
  amountB: ConvertEncType.Dec,
  swapFee: ConvertEncType.Dec,
  numQuotes: ConvertEncType.Long,
}

const AddLiquidityMap: AminoValueMap = {
  poolId: ConvertEncType.Long,
  amountA: ConvertEncType.Dec,
  amountB: ConvertEncType.Dec,
  minShares: ConvertEncType.Dec,
}

const RemoveLiquidityMap: AminoValueMap = {
  poolId: ConvertEncType.Long,
  shares: ConvertEncType.Dec,
}

const StakePoolTokenMap: AminoValueMap = {
  amount: ConvertEncType.Dec,
  duration: ConvertEncType.Long,
}

const UnstakePoolTokenMap: AminoValueMap = {
  amount: ConvertEncType.Dec,
}

export const ValueMap: TypeUtils.SimpleMap<AminoValueMap> = {
  [CarbonTx.Types.MsgCreatePoolWithLiquidity]: CreatePoolMap,
  [CarbonTx.Types.MsgAddLiquidity]: AddLiquidityMap,
  [CarbonTx.Types.MsgRemoveLiquidity]: RemoveLiquidityMap,
  [CarbonTx.Types.MsgStakePoolToken]: StakePoolTokenMap,
  [CarbonTx.Types.MsgUnstakePoolToken]: UnstakePoolTokenMap,
}

export type TotalCommitObj = TypeUtils.SimpleMap<BigNumber>

export const emptyParams: DistributionParams = {
  communityTax: BN_ZERO,
  baseProposerReward: BN_ZERO,
  bonusProposerReward: BN_ZERO,
  liquidityProviderReward: BN_ZERO,
  withdrawAddrEnabled: false,
}
