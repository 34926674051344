import { makeStyles } from '@material-ui/core'
import { BlockchainUtils } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'

import { placeholderMap } from 'js/constants/TransferOptions'
import { chainLabel } from 'js/utils/strings'

import TextField, { TextFieldProps } from '../TextField'


type Props = TextFieldProps & {
  blockchain?: BlockchainUtils.BlockchainV2
  hint?: React.ReactNode
  disabled?: boolean
  customLabel?: string
  customTextFieldClass?: string
}
const AddressInput: React.FC<Props> = (props: Props) => {
  const { blockchain, errorText, hint, disabled, customLabel ,customTextFieldClass ,placeholder, ...rest } = props
  const classes = useStyles()
  const errorTextChain = blockchain ? chainLabel(blockchain, true) : 'valid'
  const isVowel = errorTextChain.charAt(0).match(/[aeiou]/i) ? 'an' : 'a'
  const placeholderAddr = blockchain ? placeholderMap[blockchain] : 'Enter Address'

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholderAddr}
      label={customLabel ?? "Recipient's Address"}
      className={clsx(classes.textField, customTextFieldClass)}
      inputClasses={{
        label: classes.labelClass,
        outlined: {
          root: disabled ? classes.disabledInputClass : '',
          input: disabled ? classes.disabledInputClass : '',
        },
      }}
      errorText={
        (
          errorText === 'invalid-address'
          || errorText === 'Invalid bech32 address'
        )
          ? `Invalid address. Only enter ${isVowel} ${errorTextChain} address.`
          : ''
      }
      hint={hint}
      disabled={disabled}
      {...rest}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2.75),
  },
  labelClass: {
    height: theme.spacing(2),
    width: 'unset',
  },
  disabledInputClass: {
    color: theme.palette.text.disabled,
    opacity: 0.7,
  },
}))


export default AddressInput
