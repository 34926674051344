import { Record } from 'immutable'
import Long from 'long'
import { AnyAction } from 'redux'

import { AccountActionTypes, AccountState, AccountStateProps, EnableReservedFees, UsersFeeSettings, UsersTokenFeePreferences } from './types'

export const parseFeeSettings = (feeStr: string): UsersFeeSettings => {
  let res: UsersFeeSettings = {}
  try {
    const jsonSwthFee = JSON.parse(feeStr)
    if (!(jsonSwthFee instanceof Object) || jsonSwthFee instanceof Array) {
      res = {}
    }
    if (Object.keys(jsonSwthFee).length > 0) {
      const jsonSwthAddr = Object.keys(jsonSwthFee)
      jsonSwthAddr.forEach((swthKey: string) => {
        const reserve = (jsonSwthFee[swthKey]?.reserve && !isNaN(jsonSwthFee[swthKey]?.reserve)) || jsonSwthFee[swthKey]?.reserve === 0
          ? jsonSwthFee[swthKey]?.reserve
          : null
        if (typeof reserve === 'number' && reserve >= 0) {
          res[swthKey] = { reserve }
        }
      })
    }
  } catch (err) {
    res = {}
  }

  return res
}

export const parseFeePrefs = (prefStr: string): UsersTokenFeePreferences => {
  let res: UsersTokenFeePreferences = {}
  try {
    const jsonFeePref = JSON.parse(prefStr)

    if (!(jsonFeePref instanceof Object) || jsonFeePref instanceof Array) {
      res = {}
    }

    if (Object.keys(jsonFeePref).length > 0) {
      Object.keys(jsonFeePref).forEach((address: string) => {

        const selectedTokens = jsonFeePref[address]?.selectedTokens
          ? jsonFeePref[address]?.selectedTokens
          : null

        const unselectedTokens = jsonFeePref[address]?.unselectedTokens
          ? jsonFeePref[address]?.unselectedTokens
          : null

        const layout = jsonFeePref[address]?.layout
          ? jsonFeePref[address]?.layout
          : null

        if (layout && layout?.length > 0 && typeof layout === 'object') {
          layout.forEach((item: any, index: number) => {
            if (!item.x) {
              layout[index].x = 0
            }
          })
        }

        if (selectedTokens && layout) {
          res[address] = { selectedTokens, unselectedTokens, layout }
        }
      })
    }
  } catch (err) {
    res = {}
  }
  return res
}

export const getEnableReservedFeeFromLocalStorage = (key: string, address: string): boolean | null => {
  const enableReservedFee = localStorage.getItem(key)
  if (!enableReservedFee) return null
  const jsonEnableReservedFee: EnableReservedFees = JSON.parse(enableReservedFee)
  if (!(jsonEnableReservedFee instanceof Object)) {
    return null
  }
  if (Object.keys(jsonEnableReservedFee).length > 0) {
    const found = jsonEnableReservedFee[address]
    if (found !== undefined) return found
    return null
  }
  return null
}


let hideZero: boolean = false
const hideZeroLocal = localStorage.getItem(AccountActionTypes.SET_HIDE_ZERO)
if (hideZeroLocal && hideZeroLocal === 'true') {
  hideZero = true
}

export const DefaultInitialState: Record.Factory<AccountStateProps> = Record<AccountStateProps>({
  profile: null,
  socialLoginInfo: undefined,
  loginRedirect: null,
  neoExternalBalances: [],
  ethExternalBalances: [],
  n3ExternalBalances: [],

  bscExternalBalances: [],

  smartWalletAddress: undefined,


  tokenFeeSettings: null,
  reservedTokensPreference: null,
  enableReservedFee: null,
  hideZero,
  tokenFeePrefs: null,
  userNotifications: [],
  connectionError: {
    errorPriority: 0,
    errMessage: ''
  },
  contactData: {
    result: {
      entries: [],
    },
  },
  recentlyUsedContactData: [],
  showAddAddressDialog: false,
  showCarbonAddress: true,
  showCreateSubAccountDialog: false,
  pendingSubAccountCreations: undefined,
  pendingSubAccountActivations: undefined,
  pendingSubAccountNotifications: undefined,
  subAccounts: undefined,
  subAccountCooldown: { seconds: Long.ZERO, nanos: 0 },
  isSWTHDefaultWithdrawal: false,
  isSWTHWithdrawal: true,
  isSignlessTransactionsEnabled: false,
  granteeDetailsMap: {},
  lastTransactionMap: {},
  txBeforeMergeEvm: null,
  signlessDialogTrigger: undefined,
})

const defaultInitialState: AccountState = new DefaultInitialState()


const updateZeroBalance = (payload: boolean) => {
  if (payload) {
    localStorage.setItem(AccountActionTypes.SET_HIDE_ZERO, 'true')
  } else {
    localStorage.setItem(AccountActionTypes.SET_HIDE_ZERO, 'false')
  }
}

export const accountReducer = (
  state: AccountState = defaultInitialState,
  action: AnyAction,
): AccountState => {
  switch (action.type) {
    case AccountActionTypes.LOGOUT: {
      return new DefaultInitialState()
    }
    case AccountActionTypes.SET_PROFILE: {
      return state.set('profile', action.payload)
    }
    case AccountActionTypes.SET_LOGIN_REDIRECT: {
      return state.set('loginRedirect', action.payload)
    }
    case AccountActionTypes.SET_NEO_EXTERNAL_BALANCES: {
      return state.set('neoExternalBalances', action.payload)
    }
    case AccountActionTypes.SET_ETH_EXTERNAL_BALANCES: {
      return state.set('ethExternalBalances', action.payload)
    }
    case AccountActionTypes.SET_SMART_WALLET_ADDRESSES: {
      return state.set('smartWalletAddress', action.payload)
    }
    case AccountActionTypes.SET_TOKEN_FEE_SETTINGS: {
      if (!action.payload.address) return state
      const { settings, address } = action.payload
      const feeSettingStr = localStorage.getItem(AccountActionTypes.SET_TOKEN_FEE_SETTINGS)
      const usersFeeSettings: UsersFeeSettings = feeSettingStr ? parseFeeSettings(feeSettingStr) : {}
      const newState = {
        ...usersFeeSettings,
        [address]: settings,
      }

      localStorage.setItem(AccountActionTypes.SET_TOKEN_FEE_SETTINGS, JSON.stringify(newState))
      return state.set('tokenFeeSettings', settings)
    }
    case AccountActionTypes.ENABLE_RESERVED_FEE_FEATURE: {
      if (!action.payload.address) return state
      const enableReservedFee = localStorage.getItem(AccountActionTypes.ENABLE_RESERVED_FEE_FEATURE)
      const parsedEnableReservedFee = enableReservedFee ? JSON.parse(enableReservedFee) : {}
      const enable = action.payload.enable
      const newState = {
        ...parsedEnableReservedFee,
        [action.payload.address]: enable,
      }
      localStorage.setItem(AccountActionTypes.ENABLE_RESERVED_FEE_FEATURE, JSON.stringify(newState))
      return state.set('enableReservedFee', enable)
    }
    case AccountActionTypes.SET_BSC_EXTERNAL_BALANCES: {
      return state.set('bscExternalBalances', action.payload)
    }
    case AccountActionTypes.SET_SOCIAL_LOGIN_INFO: {
      return state.set('socialLoginInfo', action.payload)
    }
    case AccountActionTypes.SET_HIDE_ZERO_TRUE: {
      updateZeroBalance(true)
      return state.set('hideZero', true)
    }
    case AccountActionTypes.SET_HIDE_ZERO_FALSE: {
      updateZeroBalance(false)
      return state.set('hideZero', false)
    }
    // load latest token pref changes into both store and local storage
    case AccountActionTypes.SET_TOKEN_FEE_PREFERENCE: {
      if (!action.payload.address) return state
      // load into local storage
      const feePrefsStr = localStorage.getItem(AccountActionTypes.SET_TOKEN_FEE_PREFERENCE)
      const UsersTokenFeePreferences: UsersTokenFeePreferences = feePrefsStr ? parseFeePrefs(feePrefsStr) : {}
      const newState = {
        ...UsersTokenFeePreferences,
        [action.payload.address]: action.payload.tokenFeePref,
      }
      localStorage.setItem(AccountActionTypes.SET_TOKEN_FEE_PREFERENCE, JSON.stringify(newState))
      // load into state
      return state.set('tokenFeePrefs', action.payload.tokenFeePref)
    }
    case AccountActionTypes.SET_USER_NOTIFICATIONS: {
      return state.set('userNotifications', action.payload)
    }
    case AccountActionTypes.RESET_USER_NOTIFICATIONS: {
      return state.set('userNotifications', [])
    }
    case AccountActionTypes.SET_CONNECTION_ERROR: {
      return state.set('connectionError', action.payload)
    }
    case AccountActionTypes.SET_CONTACT_DATA: {
      return state.set('contactData', action.payload)
    }
    case AccountActionTypes.SET_RECENTLY_USED_CONTACT_DATA: {
      return state.set('recentlyUsedContactData', action.payload)
    }
    case AccountActionTypes.SET_SHOW_ADD_ADDRESS_DIALOG: {
      return state.set('showAddAddressDialog', action.payload)
    }
    case AccountActionTypes.SET_SHOW_CARBON_ADDRESS: {
      return state.set('showCarbonAddress', action.payload)
    }
    case AccountActionTypes.SET_SHOW_CREATE_SUBACCOUNT_DIALOG: {
      return state.set('showCreateSubAccountDialog', action.payload)
    }
    case AccountActionTypes.SET_PENDING_SUBACCOUNT_CREATIONS: {
      return state.set('pendingSubAccountCreations', action.payload)
    }
    case AccountActionTypes.SET_PENDING_SUBACCOUNT_ACTIVATIONS: {
      return state.set('pendingSubAccountActivations', action.payload)
    }
    case AccountActionTypes.SET_SUBACCOUNTS: {
      return state.set('subAccounts', action.payload)
    }
    case AccountActionTypes.RESET_SUBACCOUNTS_DATA: {
      return state.merge({
        subAccounts: undefined,
        pendingSubAccountActivations: undefined,
        pendingSubAccountCreations: undefined,
      })
    }
    case AccountActionTypes.SET_SUBACCOUNT_COOLDOWN: {
      return state.set('subAccountCooldown', action.payload)
    }
    case AccountActionTypes.SET_PENDING_SUBACCOUNT_NOTIFICATIONS: {
      return state.set('pendingSubAccountNotifications', action.payload)
    }
    case AccountActionTypes.REMOVE_PENDING_SUBACCOUNT_NOTIFICATION: {
      const currentPendingSubAccountNotifications = state.pendingSubAccountNotifications
      if (!currentPendingSubAccountNotifications) {
        return state.set('pendingSubAccountNotifications', action.payload)
      }

      const remainingPendingSubAccountNotifications = currentPendingSubAccountNotifications.filter((notification) => {
        return notification !== action.payload
      })
      return state.set('pendingSubAccountNotifications', remainingPendingSubAccountNotifications)
    }
    case AccountActionTypes.SET_WITHDRAWAL_SWTH: {
      return state.set('isSWTHWithdrawal', action.payload)
    }
    case AccountActionTypes.SET_RESERVED_TOKENS_PREFERENCE: {
      return state.set('reservedTokensPreference', action.payload)
    }
    case AccountActionTypes.SET_DEFAULT_WITHDRAWAL_SWTH: {
      if (!action.payload.address) return state
      // load into local storage
      const usersDefaultWithdrawalStr = localStorage.getItem(AccountActionTypes.SET_DEFAULT_WITHDRAWAL_SWTH)
      const usersDefaultWithdrawal = usersDefaultWithdrawalStr ? JSON.parse(usersDefaultWithdrawalStr) : {}
      const newState = {
        ...usersDefaultWithdrawal,
        [action.payload.address]: action.payload.boolean,
      }
      localStorage.setItem(AccountActionTypes.SET_DEFAULT_WITHDRAWAL_SWTH, JSON.stringify(newState))
      // load into state

      return state.set('isSWTHDefaultWithdrawal', action.payload.boolean)
    }
    case AccountActionTypes.LOAD_DEFAULT_WITHDRAWAL_SWTH: {
      if (!action.payload.address) return state
      const usersDefaultWithdrawalStr = localStorage.getItem(AccountActionTypes.SET_DEFAULT_WITHDRAWAL_SWTH)
      const usersDefaultWithdrawal = usersDefaultWithdrawalStr ? JSON.parse(usersDefaultWithdrawalStr) : {}
      const defaultWithdrawal: boolean = action.payload.address in usersDefaultWithdrawal ? usersDefaultWithdrawal[action.payload.address] : true
      return state.set('isSWTHDefaultWithdrawal', defaultWithdrawal)
    }
    case AccountActionTypes.SET_ENABLE_SIGNLESS_TRANSACTIONS: {
      return state.set('isSignlessTransactionsEnabled', action.payload)
    }
    case AccountActionTypes.SET_GRANTEE_DETAILS: {
      return state.set('granteeDetailsMap', action.payload)
    }
    case AccountActionTypes.SET_LAST_TRANSACTION_TIMESTAMP: {
      return state.set('lastTransactionMap', action.payload)
    }
    case AccountActionTypes.SET_SIGNLESS_DIALOG_TRIGGER: {
      return state.set('signlessDialogTrigger', action.payload.triggerType)
    }
    case AccountActionTypes.SET_TX_BEFORE_MERGE_EVM: {
      return state.set('txBeforeMergeEvm', action.payload)
    }
    default:
      return state
  }
}
