import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React, { PropsWithChildren } from "react"
import { WagmiProvider } from 'wagmi'

import { wagmiConfig } from "js/utils"

import '@rainbow-me/rainbowkit/styles.css'

const queryClient = new QueryClient()

const Web3Provider = ({ children }: PropsWithChildren) => {
  return (
    <WagmiProvider reconnectOnMount={false} config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          theme={darkTheme({
            accentColor: '#148AFF',
            accentColorForeground: 'white',
            borderRadius: 'small',
          })}
        >
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default Web3Provider
