import {
  createStyles, LinkProps, makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'

import TextButton from './TextButton'



interface Props extends LinkProps {
  label: React.ReactNode  
  showIcon?: boolean
  symbol?: string
  iconClass?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const ExternalLink: React.FC<Props> = (props: Props) => {
  const {
    className, label, href, showIcon = true, symbol,
    iconClass, color = 'secondary',
    onClick,
  } = props
  const classes = useStyles()

  return (
    <TextButton
      className={clsx(classes.link, className)}
      href={href}
      target="_blank"
      rel="noopener"
      color={color}
      onClick={onClick || ((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation())}
      label={label}
      endIcon={showIcon ? symbol || ExternalLinkIcon : undefined}
      svgClass={iconClass}
    />
  )
}

const useStyles = makeStyles((theme) => createStyles({
  link: {
    ...theme.typography.body1,
    fontSize: '13px',
    textAlign: 'left',
    fontWeight: '700' as any,
    lineHeight: '16px',
    letterSpacing: '0em',
    textDecoration: 'none',
  },
  externalLinkIcon: {
    height: '1rem',
    width: '10px',
    margin: '0 0.25rem 0 0.375rem',
    marginBottom: '-2px',
    maxWidth: '1rem',
    '& path': {
      stroke: theme.palette.text.demexSolid,
    },
  },
}))

export default ExternalLink
