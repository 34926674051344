import { makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { BlockchainUtils } from 'carbon-js-sdk'
import clsx from 'clsx'
import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { TABS_INFO } from 'js/constants/app'
import { usdGroupedToken } from 'js/constants/assets'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getCdpAssets, getCdpModuleBalances, getTotalSupplyMap } from 'js/state/modules/cdp/selectors'
import { getTokenPrices } from 'js/state/modules/pricing/selectors'
import { getTokenProperties } from 'js/state/modules/walletBalance/selectors'
import { formatSmallNumber, getCdpTokenUsdAmount, getTokenUSDPrice } from 'js/utils'
import { BN_ONE, BN_ZERO } from 'js/utils/number'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import AssetIcon from '../AssetIcon'
import ChainTag from '../ChainTag'
import SubscriptText from '../SubscriptText'
import BasicTable, { BasicCell } from '../Table/BasicTable'
import TokenSymbol from '../TokenSymbol'

export interface TokenBalance {
  assetId?: string
  denom: string
  symbol: string
  name?: string
  shift?: number
  interest?: BigNumber
  isMinted?: boolean
  balance: BigNumber
  usdValue: BigNumber
  extraBlockchains?: BlockchainUtils.BlockchainV2[]
  noOfExtraBlockChains?: number
}

export interface TableClasses {
  container?: string
  table?: string
  tokenName?: string
  tokenTitle?: string
  assetIcon?: string
  bodyRowClass?: string
}

interface Props {
  input?: string
  tokens: TokenBalance[]
  balanceLabel?: string
  onClickRow: (value: any) => void
  classes?: TableClasses
}

const sortByUSDFirst = (tokenA: TokenBalance, tokenB: TokenBalance) => {
  if (tokenA.denom === usdGroupedToken) return -1
  return tokenB.denom === usdGroupedToken ? 1 : 0
}

const sortByName = (symbolA: string, symbolB: string) => {
  if (symbolA < symbolB) {
    return -1
  }
  if (symbolA > symbolB) {
    return 1
  }
  return 0
}

const sortByUsdVal = (usdValueA: BigNumber, usdValueB: BigNumber) => {
  if (usdValueA.minus(usdValueB).isZero()) {
    return 0
  }
  return usdValueA.gt(usdValueB) ? -1 : 1
}

const EMPTY_TOKENS: TokenBalance[] = []

const TokensTable: React.FC<Props> = (props: Props) => {
  const { input = '', onClickRow, classes: newClasses = {}, tokens: allTokens, balanceLabel = 'Available Balance' } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const currentPath = useLocation().pathname
  const currentTab = Object.values(TABS_INFO).find((tab) => currentPath.includes(tab.value))
  // currentTab.value === 'withdraw'

  const [ready, setReady] = React.useState<boolean>(false)
  const tokens = ready ? allTokens : EMPTY_TOKENS

  const sdk = useSelector(getCarbonSDK)
  const isLoggedIn = useSelector(isWalletConnected)

  const cdpAssets = useSelector(getCdpAssets)
  const cdpTokenPrices = useSelector(getTokenPrices)
  const cdpModuleBalances = useSelector(getCdpModuleBalances)
  const totalSupplyMap = useSelector(getTotalSupplyMap)
  const tokenProperties = useSelector(getTokenProperties)

  useEffect(() => {
    setTimeout(() => setReady(true))
    return () => setReady(false)
  }, [])

  const headers: BasicCell[] = [{
    key: 'assets',
    item: 'Assets',
  }, {
    key: balanceLabel.replace(' ', '-').toLowerCase(),
    item: balanceLabel,
  }]

  const sortTokens = React.useMemo(() => {
    // set usd value for cdp tokens, otherwise usdValue = 0
    const tokensUsdValue = tokens.map((token: TokenBalance) => {
      const tokenProperty = tokenProperties[token.denom]
      if (tokenProperty?.tokenCDPInfo.isCdpToken) {
        const underlyingDenom = tokenProperty?.tokenCDPInfo.underlyingDenom ?? ''
        const asset = cdpAssets[underlyingDenom]

        const tokenDp = tokenProperty?.tokenCDPInfo.underlyingDecimals ?? 0
        const rawBalance = token.balance.shiftedBy(tokenDp)
        token.usdValue = getCdpTokenUsdAmount(rawBalance, cdpTokenPrices, totalSupplyMap, cdpModuleBalances, sdk, asset)
        return token
      }
      return token
    })
    if (input.length > 0) {
      return tokensUsdValue.sort((tokenA: TokenBalance, tokenB: TokenBalance) => {
        const status = sortByUsdVal(tokenA.usdValue, tokenB.usdValue)
        if (status !== 0) {
          return status
        }
        const usdStatus = sortByUSDFirst(tokenA, tokenB)
        if (usdStatus !== 0) {
          return usdStatus
        }
        return sortByName(tokenA.symbol, tokenB.symbol)
      }).filter((tokenA: TokenBalance) => {
        const newInput = input.toLowerCase()
        const name = tokenA.name?.toLowerCase()
        const symbol = tokenA.symbol?.toLowerCase() ?? ''
        if (name) {
          return name.includes(newInput) || symbol.includes(newInput)
        }
        return symbol.includes(newInput)
      })
    }
    return tokens.sort((tokenA: TokenBalance, tokenB: TokenBalance) => {
      const status = sortByUsdVal(tokenA.usdValue, tokenB.usdValue)
      if (status !== 0) {
        return status
      }
      const usdStatus = sortByUSDFirst(tokenA, tokenB)
      if (usdStatus !== 0) {
        return usdStatus
      }
      return sortByName(tokenA.symbol, tokenB.symbol)
    })
  }, [input, tokens, cdpAssets, cdpModuleBalances, cdpTokenPrices, sdk, totalSupplyMap, tokenProperties])

  const rows = React.useMemo(
    () => {
      const TokenCell = (tokenBalance: TokenBalance) => {
        const { denom, name, noOfExtraBlockChains, symbol, extraBlockchains, isMinted } = tokenBalance
        const tokenProperty = tokenProperties[denom]
        const blockchains = noOfExtraBlockChains ?? 0
        const newExtraBlockchains = extraBlockchains ?? []

        const isGroupedToken = tokenProperty?.tokenBasicInfo.isPartOfGroup
        const isCdpToken = tokenProperty?.tokenCDPInfo?.isCdpToken
        const isPoolToken = tokenProperty?.tokenBasicInfo.isPoolToken
        const isDualTokenIcon = isCdpToken || isPoolToken
        return (
          <div className={clsx("tableData", commonClasses.alignItemsCenter, commonClasses.justifyContentStart)} >
            <AssetIcon
              className={clsx(classes.assetIcon, newClasses.assetIcon)}
              denom={denom}
              leftSvgClass={isDualTokenIcon ? classes.cdpTokenIcon : ''}
              rightSvgClass={isDualTokenIcon ? classes.cdpTokenIcon : ''}
              isMinted={isMinted}
            />
            <div>
              <div className={commonClasses.alignItemsCenter}>
                <Typography
                  variant="body1"
                  className={clsx(classes.tokenTitle, 'asset', newClasses.tokenTitle)}>
                  <TokenSymbol
                    denom={denom}
                    showAssetTitle={isCdpToken}
                  />{isMinted && <Fragment>&nbsp;(Minted)</Fragment>}
                </Typography>
                <Tooltip
                  classes={{ tooltip: classes.tooltipContainer }}
                  placement="right"
                  title={
                    blockchains === 0 ? ''
                      : (
                        <div className={classes.chainTagDiv}>
                          <span className={classes.chainTagSpan}><ChainTag denom={denom} /></span>
                          {(newExtraBlockchains.map((blockchain: BlockchainUtils.BlockchainV2) => {
                            return <span className={classes.chainTagSpan} key={denom}><ChainTag blockchainA={blockchain} /></span>
                          }))}
                        </div>
                      )
                  }
                >
                  <span>
                    <ChainTag denom={denom} noOfExtraBlockChains={blockchains} />
                  </span>
                </Tooltip>
              </div>
              {name && (
                <Typography variant="body2" color="textSecondary" className={clsx(classes.tokenName, newClasses.tokenName)}>
                  {name ?? '-'}
                </Typography>
              )}
              {isGroupedToken && currentTab?.value === 'withdraw' && (
                <Typography variant="body2" color="textSecondary" className={clsx(classes.groupTokenSubtitle, newClasses.tokenName)}>
                  Withdraw {symbol} directly from USD balance
                </Typography>
              )}
            </div>
          </div>
        )
      }
      const BalanceCell = (tokenBalance: TokenBalance) => {
        const { balance, denom, usdValue } = tokenBalance
        const tokenProperty = tokenProperties[denom]
        let balanceUsdValue = usdValue
        if (tokenProperty?.tokenCDPInfo.isCdpToken) {
          const underlyingDenom = tokenProperty?.tokenCDPInfo.underlyingDenom ?? ''
          const asset = cdpAssets[underlyingDenom]
          const totalSupplyBN = totalSupplyMap[denom] ?? BN_ZERO
          const cdpModuleBalance = cdpModuleBalances[underlyingDenom] ?? BN_ZERO
          const usdPrice = getTokenUSDPrice(cdpTokenPrices, underlyingDenom, sdk)
          const cdpRatio = asset?.getCdpRatio(totalSupplyBN, cdpModuleBalance) ?? BN_ONE
          balanceUsdValue = balance.div(cdpRatio).times(usdPrice)
        }
        const formatted = formatSmallNumber(balance, 0, 5, 7)
        const formattedUSD = formatSmallNumber(balanceUsdValue, 0, 8, 8, true)

        return (
          <div className="tableData">
            <Typography variant="body1" className={clsx(classes.tokenTitle, newClasses.tokenTitle)}>
              {isLoggedIn ? (<SubscriptText formatted={formatted} />) : '—'}
            </Typography>
            <div className={commonClasses.justifyContentEnd}>
              <Typography variant="body2" color="textSecondary" className={clsx(classes.tokenName, newClasses.tokenName)}>
                {isLoggedIn ? (<SubscriptText formatted={formattedUSD} usdValue numSubscriptClass={classes.usdValue} subSubscriptClass={classes.usdValueSubscript} />) : '—'}
              </Typography>
            </div>
          </div>
        )
      }

      const rows = sortTokens.map((token: TokenBalance) => {
        const tableCells = [
          {
            key: token.isMinted ? `asset-${token.denom}-minted` : `asset-${token.denom}`,
            item: TokenCell(token),
          },
          {
            key: token.isMinted ? `balance-${token.denom}-minted` : `balance-${token.denom}`,
            item: BalanceCell(token),
          },
        ]

        return {
          key: token.isMinted ? `${token.denom}-minted-row` : `${token.denom}-row`,
          cells: tableCells,
          value: token,
        }
      })
      return rows
    }, [classes.usdValue, classes.usdValueSubscript, classes.assetIcon, classes.tokenTitle, classes.tooltipContainer, classes.chainTagDiv, classes.chainTagSpan, classes.tokenName, classes.groupTokenSubtitle, newClasses.assetIcon, newClasses.tokenTitle, newClasses.tokenName, sdk, cdpAssets, cdpModuleBalances, cdpTokenPrices, totalSupplyMap, sortTokens, currentTab, classes.cdpTokenIcon, tokenProperties, isLoggedIn, commonClasses],
  )
  return (
    <BasicTable
      tableContainer={{
        onClickRow,
        headers,
        rows,
        containerClass: clsx(classes.containerClass, newClasses.container),
        emptyCellClass: classes.emptyCellClass,
        headerRowClass: classes.headerRowClass,
        bodyRowClass: clsx(classes.bodyRowClass, newClasses.bodyRowClass),
        loading: !ready,
      }}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  assetIcon: {
    maxHeight: '1.5em',
    maxWidth: '1.5em',
    marginRight: theme.spacing(1.5),
  },
  cdpTokenIcon: {
    height: '1.3rem',
    width: '1.3rem',
  },
  bodyRowClass: {
    padding: theme.spacing(1.5, 0),
  },
  containerClass: {
    ...StyleUtils.scrollBar(theme),
    border: '1px solid transparent',
    maxHeight: '21.75em',
    overflow: 'overlay',
    '@supports not (overflow:overlay)': {
      overflow: 'auto',
    },
    scrollbarColor: theme.palette.scrollbar.thumb,
    '-webkit-overflow-scrolling': 'scroll',
  },
  emptyCellClass: {
    minWidth: theme.spacing(2),
    maxWidth: theme.spacing(2),
    width: theme.spacing(2),
  },
  headerRowClass: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.25, 0),
    '&:nth-child(3)': {
      textAlign: 'end',
    },
  },
  normalHeader: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    '&.hasSort': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  },
  tokenTitle: {
    ...theme.typography.body3,
    textAlign: 'end',
    '&.asset': {
      marginRight: '0.375rem',
    },
  },
  tokenName: {
    fontSize: '0.725rem',
    lineHeight: '1rem',
    maxWidth: 'unset',
  },
  groupTokenSubtitle: {
    fontSize: '0.5625rem!important',
    lineHeight: '0.75rem',
    maxWidth: 'unset',
  },
  chainTagDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  chainTagSpan: {
    alignSelf: 'center',
    margin: '0.2rem',
  },
  tooltipContainer: {
    zIndex: 4000,
    backgroundColor: theme.palette.background.primary,
  },
  usdValue: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    marginTop: '0.125rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  },
  usdValueSubscript: {
    ...theme.typography.body5,
  },
}))

export default TokensTable
