import { SortDirection } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { BlockchainUtils, Carbon, CarbonSDK, OrderModule, TypeUtils, WSModels } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'

import { SHIFT_DECIMALS } from 'js/constants/assets'
import { DEFAULT_BLOCK_TIME, NEW_MARKET_DURATION } from 'js/constants/date'
import { BlockchainOpts, orderTriggerTypeLabels, timeInForceLabels } from 'js/constants/markets'
import { isFutures } from 'js/models/Market'
import { isBuy, isStop, isTakeProfit } from "js/models/Order"
import { ExchangeOrderBook, Market, OpenOrderLevel } from 'js/state/modules/exchange/types'
import { ModifiedHistoryOrder } from 'js/state/modules/history/types'
import { ModifiedMarketStat } from 'js/state/modules/marketStats/types'
import { BN_ONE, BN_ZERO, parseNumber } from 'js/utils/number'
import { getAdjustedTickLotSize, shiftByBaseDp, unshiftByDiffDp } from 'js/utils/order'

import { SimpleMap } from './types'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export const futuresMarketRegex = /^([a-z]+)_(([\d]{2}[a-z]{3}[\d]{2})|PERP)(\.([a-z]+))?/i

// get the number of decimal places seen in a number
export function getDecimalPlaces(num?: string | number): number {
  if (typeof num === 'undefined') return 0
  const moduloResult = parseNumber(num, BN_ZERO)!.modulo(1)
  return moduloResult.decimalPlaces() ?? 0
}

export function roundOffPriceBasedOnTickSize(market: Market | null | undefined, sdk: CarbonSDK, price: string | null | number, roundingMode: BigNumber.RoundingMode) {
  const { tickSize } = getAdjustedTickLotSize(market)
  const priceDp = getDecimalPlaces(tickSize.toString(10))
  return unshiftByDiffDp(
    market,
    parseNumber(price, BN_ZERO)!,
  ).toFormat(priceDp, roundingMode)
}

export interface MarginVals {
  initialMarginBase: BigNumber
  initialMarginStep: BigNumber
  riskStepSize: BigNumber
}

export const getAdjustedMarginVals = (
  market: Market | null | undefined,
  sdk: CarbonSDK | undefined,
): MarginVals => {
  if (!market || !sdk?.token) {
    return {
      initialMarginBase: BN_ZERO,
      initialMarginStep: BN_ZERO,
      riskStepSize: BN_ZERO,
    }
  }
  const initialMarginBase = parseNumber(market?.initialMarginBase, BN_ZERO)!.shiftedBy(-SHIFT_DECIMALS)
  const riskStepSize = sdk?.token.toHuman(market?.base ?? '', parseNumber(market?.riskStepSize, BN_ZERO)!) ?? BN_ZERO
  const initialMarginStep = parseNumber(market?.initialMarginStep, BN_ZERO)!.shiftedBy(-SHIFT_DECIMALS)
  return {
    initialMarginBase, initialMarginStep, riskStepSize,
  }
}

export const sortPrice = (
  markets: Market[],
  direction: SortDirection,
  sdk: CarbonSDK | undefined,
  marketStats: SimpleMap<ModifiedMarketStat>,
  key: keyof ModifiedMarketStat = 'last_price',
) => {
  return markets.sort((rowA: Market, rowB: Market) => {
    const statA: ModifiedMarketStat | undefined = marketStats[rowA.id]
    const statB: ModifiedMarketStat | undefined = marketStats[rowB.id]
    const valueA = unshiftByDiffDp(rowA, parseNumber(statA?.[key], BN_ZERO)!)
    const valueB = unshiftByDiffDp(rowB, parseNumber(statB?.[key], BN_ZERO)!)
    const usdValueA = (sdk?.token.getUSDValue(rowA?.quote ?? '') ?? BN_ZERO).times(valueA)
    const usdValueB = (sdk?.token.getUSDValue(rowB?.quote ?? '') ?? BN_ZERO).times(valueB)
    if (usdValueA.lt(usdValueB)) {
      return direction === 'desc' ? 1 : -1
    }
    if (usdValueA.gt(usdValueB)) {
      return direction === 'desc' ? -1 : 1
    }
    return 0
  })
}

export const sort24hVolume = (markets: Market[], direction: SortDirection, sdk: CarbonSDK | undefined, marketStats: SimpleMap<ModifiedMarketStat>) => {
  return markets.sort((rowA: Market, rowB: Market) => {
    const statA: ModifiedMarketStat | undefined = marketStats[rowA.id]
    const statB: ModifiedMarketStat | undefined = marketStats[rowB.id]
    const valueA = sdk?.token.toHuman(rowA?.quote ?? '', parseNumber(statA?.day_quote_volume, BN_ZERO)!) ?? BN_ZERO
    const valueB = sdk?.token.toHuman(rowB?.quote ?? '', parseNumber(statB?.day_quote_volume, BN_ZERO)!) ?? BN_ZERO
    const usdValueA = (sdk?.token.getUSDValue(rowA?.quote ?? '') ?? BN_ZERO).times(valueA)
    const usdValueB = (sdk?.token.getUSDValue(rowB?.quote ?? '') ?? BN_ZERO).times(valueB)
    if (usdValueA.lt(usdValueB)) {
      return direction === 'desc' ? 1 : -1
    }
    if (usdValueA.gt(usdValueB)) {
      return direction === 'desc' ? -1 : 1
    }
    return 0
  })
}

export const sort24hChange = (markets: Market[], direction: SortDirection, sdk: CarbonSDK | undefined, marketStats: SimpleMap<ModifiedMarketStat>) => {
  return markets.sort((rowA: Market, rowB: Market) => {
    const statA: ModifiedMarketStat | undefined = marketStats[rowA.id]
    const statB: ModifiedMarketStat | undefined = marketStats[rowB.id]

    if (statA === undefined || statB === undefined) { return 0 }

    const baseADp = rowA.basePrecision.toNumber()
    const baseBDp = rowB.basePrecision.toNumber()
    const quoteADp = rowA.quotePrecision.toNumber()
    const quoteBDp = rowB.quotePrecision.toNumber()
    const diffDpA = baseADp - quoteADp
    const diffDpB = baseBDp - quoteBDp

    const closeABN = parseNumber(statA.day_close, BN_ZERO)!.shiftedBy(-diffDpA)
    const openABN = parseNumber(statA.day_open, BN_ZERO)!.shiftedBy(-diffDpA)
    const changeA = closeABN.isZero() ? BN_ZERO : closeABN.minus(openABN).div(openABN)

    const closeBBN = parseNumber(statB.day_close, BN_ZERO)!.shiftedBy(-diffDpB)
    const openBBN = parseNumber(statB.day_open, BN_ZERO)!.shiftedBy(-diffDpB)
    const changeB = closeBBN.isZero() ? BN_ZERO : closeBBN.minus(openBBN).div(openBBN)

    if (changeA.lt(changeB)) {
      return direction === 'desc' ? 1 : -1
    }
    if (changeA.gt(changeB)) {
      return direction === 'desc' ? -1 : 1
    }
    return 0
  })
}

export const sortMarketAlphabetically = (markets: Market[], direction: SortDirection, marketDisplayNames: TypeUtils.SimpleMap<string>) => {
  return markets.sort((rowA: Market, rowB: Market) => {
    const marketNameA = marketDisplayNames[rowA.id] ?? ''
    const marketNameB = marketDisplayNames[rowB.id] ?? ''

    if (marketNameA < marketNameB) {
      return direction === 'asc' ? -1 : 1
    }
    if (marketNameA > marketNameB) {
      return direction === 'asc' ? 1 : -1
    }
    return 0
  })
}

export const sortOpenInterest = (markets: Market[], direction: SortDirection, sdk: CarbonSDK | undefined, marketStats: SimpleMap<ModifiedMarketStat>) => {
  return markets.sort((rowA: Market, rowB: Market) => {
    const statA: ModifiedMarketStat | undefined = marketStats[rowA.id]
    const statB: ModifiedMarketStat | undefined = marketStats[rowB.id]

    if (statA === undefined || statB === undefined) { return 0 }
    const isRowAFutures = isFutures(rowA?.marketType)
    const isRowBFutures = isFutures(rowB?.marketType)

    // open interest are irrelevant to spot markets, hence these markets will always be placed at the end when sorting via open interest.
    if (!isRowAFutures && isRowBFutures) {
      return 1
    }
    if (isRowAFutures && !isRowBFutures) {
      return -1
    }

    const baseADp = rowA.basePrecision.toNumber()
    const baseBDp = rowB.basePrecision.toNumber()
    const quoteADp = rowA.quotePrecision.toNumber()
    const quoteBDp = rowB.quotePrecision.toNumber()

    const openInterestA = parseNumber(statA?.open_interest || '0', BN_ZERO)!.shiftedBy(-baseADp).times(bnOrZero(statA?.mark_price).shiftedBy(baseADp - quoteADp))
    const openInterestB = parseNumber(statB?.open_interest || '0', BN_ZERO)!.shiftedBy(-baseBDp).times(bnOrZero(statB?.mark_price).shiftedBy(baseBDp - quoteBDp))

    if (openInterestA.lt(openInterestB)) {
      return direction === 'desc' ? 1 : -1
    }
    if (openInterestA.gt(openInterestB)) {
      return direction === 'desc' ? -1 : 1
    }

    return 0
  })
}

export const sortInitMarkets = (markets: Market[], sdk: CarbonSDK | undefined, marketStats: SimpleMap<ModifiedMarketStat>) => {
  return markets.sort((marketA: Market, marketB: Market) => {
    if (marketA.isNew && !marketB.isNew) return -1
    if (marketB.isNew && !marketA.isNew) return 1

    const statA: ModifiedMarketStat | undefined = marketStats[marketA.id]
    const statB: ModifiedMarketStat | undefined = marketStats[marketB.id]
    const valueA = sdk?.token.toHuman(marketA?.quote ?? '', parseNumber(statA?.day_quote_volume, BN_ZERO)!) ?? BN_ZERO
    const valueB = sdk?.token.toHuman(marketB?.quote ?? '', parseNumber(statB?.day_quote_volume, BN_ZERO)!) ?? BN_ZERO
    const usdValueA = (sdk?.token.getUSDValue(marketA?.quote ?? '') ?? BN_ZERO).times(valueA)
    const usdValueB = (sdk?.token.getUSDValue(marketB?.quote ?? '') ?? BN_ZERO).times(valueB)
    return usdValueB.minus(usdValueA).toNumber()
  })
}

export function getNewMarketBlocks(avgBlockTime: BigNumber | null): number {
  const blockTime = avgBlockTime ?? DEFAULT_BLOCK_TIME
  return BN_ONE.div(blockTime).times(NEW_MARKET_DURATION).toNumber()
}

export function isNewMarket(market: Carbon.Market.Market, blockHeight: number, thresholdBlockCount: number) {
  if (!blockHeight) return false
  if (market.createdBlockHeight.eq(0)) return false // old markets have createdBlockHeight of 0
  const createdBlockHeight = market.createdBlockHeight.toInt()
  const elapsedBlockHeight = blockHeight - createdBlockHeight
  return elapsedBlockHeight <= thresholdBlockCount
}

export const getBookUsdValue = (book: OpenOrderLevel[], sdk: CarbonSDK | undefined, market: Market) => {
  let liquidity: BigNumber = BN_ZERO
  book.forEach((ask: OpenOrderLevel) => {
    const price = unshiftByDiffDp(market, bnOrZero(ask.price).shiftedBy(-SHIFT_DECIMALS))
    const quantity = shiftByBaseDp(market, bnOrZero(ask.quantity))
    const total = price.times(quantity)

    // Retrieve usd value everytime we need to calculate totalUSD
    let quoteUsd = bnOrZero(sdk?.token.getUSDValue(market.quote ?? '-'))
    // work-around to fix usd values returning 0
    if (quoteUsd.isZero()) {
      quoteUsd = bnOrZero(sdk?.token.usdValues[market.quote])
    }

    const totalUSD = quoteUsd.times(total)
    liquidity = liquidity.plus(totalUSD)
  })
  return liquidity
}

export const getLiquidityWithinThreshold = (orderBook: ExchangeOrderBook | undefined, sdk: CarbonSDK | undefined, market: Market, marketStat: ModifiedMarketStat, threshold: number) => {
  const sells = orderBook?.asks ?? []
  const buys = orderBook?.bids ?? []

  const baseDp = market?.basePrecision.toNumber() ?? 0
  const quoteDp = market?.quotePrecision.toNumber() ?? 0
  const diffDp = baseDp - quoteDp
  const lastPriceBN = bnOrZero(marketStat?.last_price).shiftedBy(diffDp)
  const markPriceBN = bnOrZero(marketStat?.mark_price).shiftedBy(diffDp)
  const marketPrice = isFutures(market?.marketType) ? markPriceBN : lastPriceBN

  const lowestSellCeiling = marketPrice.multipliedBy(1 + threshold) // maximum 5% higher than market price
  const filteredSells = sells.filter((sell) => {
    const sellPrice = unshiftByDiffDp(market, bnOrZero(sell.price).shiftedBy(-SHIFT_DECIMALS))
    return sellPrice.lte(lowestSellCeiling)
  })

  const highestBuyFloor = marketPrice.multipliedBy(1 - threshold) // maximum 5% lower than market price
  const filteredBuys = buys.filter((buy) => {
    const buyPrice = unshiftByDiffDp(market, bnOrZero(buy.price).shiftedBy(-SHIFT_DECIMALS))
    return buyPrice.gte(highestBuyFloor)
  })

  return getBookUsdValue(filteredSells, sdk, market).plus(getBookUsdValue(filteredBuys, sdk, market))
}

export const getInitMarketBlockchains = () => {
  return BlockchainOpts.filter((blockchain: BlockchainUtils.BlockchainV2) => blockchain !== 'Tradehub')
}

export const roundUpToNearestMultiple = (amount: BigNumber, lotSize: BigNumber) => {
  const lotSizeRemainder = amount.modulo(lotSize)
  if (lotSizeRemainder.gt(0)) {
    const nearestMultiple = bnOrZero(amount).dividedBy(lotSize).dp(0, BigNumber.ROUND_CEIL).times(lotSize)
    return nearestMultiple
  }
  return amount
}

export const getOpenPosition = (openPositions: WSModels.Position[], market: string): WSModels.Position | null => {
  return openPositions.find(position => !bnOrZero(position.lots).eq(BN_ZERO) && position.market_id === market) || null
}

export const getNetOpenOrdersQuantity = (openOrders: ModifiedHistoryOrder[], market: string): BigNumber => {
  return openOrders.reduce((total, order) => {
    if (order.market_id === market) {
      const quantity = bnOrZero(order.quantity)
      return order.side === 'buy' ? total.plus(quantity) : total.minus(quantity)
    }
    return total
  }, BN_ZERO)
}

export const getShiftedDailyStats = (marketStats: ModifiedMarketStat | undefined, market: Market | null) => {
  const baseDp = market?.basePrecision.toNumber() ?? 0
  const quoteDp = market?.quotePrecision.toNumber() ?? 0

  const dpDifference = baseDp - quoteDp
  const shiftedDailyHigh = bnOrZero(marketStats?.day_high).shiftedBy(dpDifference)
  const shiftedDailyLow = bnOrZero(marketStats?.day_low).shiftedBy(dpDifference)
  const shiftedDailyVolumeBN = bnOrZero(marketStats?.volume).shiftedBy(-quoteDp)
  const shiftedDailyClose = bnOrZero(marketStats?.day_close).shiftedBy(dpDifference)
  const shiftedDailyOpen = bnOrZero(marketStats?.day_open).shiftedBy(dpDifference)
  const shiftedLastPriceBN = bnOrZero(marketStats?.last_price).shiftedBy(dpDifference)
  const changePercentBN = bnOrZero(marketStats?.change)

  // for futures and perpetuals only:
  const shiftedOpenInterest = bnOrZero(marketStats?.open_interest || '0').shiftedBy(-baseDp).times(bnOrZero(marketStats?.mark_price).shiftedBy(dpDifference))

  // for futures only:
  const rawMarkPrice = bnOrZero(marketStats?.mark_price)
  const markPrice = unshiftByDiffDp(market, rawMarkPrice)
  const rawIndexPrice = bnOrZero(marketStats?.index_price)
  const indexPrice = unshiftByDiffDp(market, rawIndexPrice)

  return {
    shiftedDailyHigh,
    shiftedDailyLow,
    shiftedDailyVolumeBN,
    shiftedDailyClose,
    shiftedDailyOpen,
    shiftedLastPriceBN,
    changePercentBN,
    markPrice,
    indexPrice,
    shiftedOpenInterest,
  }
}

export const getTimeInForceLabel = (timeInForceOption: OrderModule.TimeInForce | undefined): string => {
  return timeInForceOption ? timeInForceLabels[timeInForceOption] : ''
}

export const getOrderTriggerType = (orderTriggerType: OrderModule.TriggerType | undefined): string => {
  return orderTriggerType ? orderTriggerTypeLabels[orderTriggerType] : ''
}

export const getAdvancedOrderOptionLabel = (isPostOnly: boolean | undefined, isReduceOnly: boolean | undefined): string => {
  if (isPostOnly && isReduceOnly) {
    return 'Post Only and Reduce Only'
  }
  if (isPostOnly) {
    return 'Post Only'
  }
  if (isReduceOnly) {
    return 'Reduce Only'
  }
  return ''
}

export const getPriceActionLabel = (orderType: OrderModule.OrderType, orderSide: OrderModule.OrderSide) => {
  if (isBuy(orderSide)) {
    return isStop(orderType) ? 'rises to or above' : 'drops to or below'
  } else {
    return isTakeProfit(orderType) ? 'rises to or above' : 'drops to or below'
  }
}
