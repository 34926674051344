import daysjs from 'dayjs'
import { Set } from 'immutable'
import { AnyAction } from 'redux'

import { LoadingTasks, TxLoadingTasks } from 'js/models/LoadingTask'

import { ActionType, RemoveTxLoading, SetTxLoading, Toast } from './types'


export type LayoutState = {
  loadingTasks: LoadingTasks
  txLoadingTasks: TxLoadingTasks
  tasksRegistry: { [index: string]: any }
  countdown: Boolean
  wsInit: Set<string>
  toasts: Toast[]
}

export const initialState: LayoutState = {
  loadingTasks: {},
  txLoadingTasks: {},
  tasksRegistry: {},
  countdown: false,
  wsInit: Set<string>(),
  toasts: []
}

const updateTaskViewer = (loadingTasks: LoadingTasks) => {
  if (typeof window === 'undefined') return
  (window as any).loadingTasks = loadingTasks
}

const loadingTaskReducer = (
  state: LayoutState = initialState,
  action: AnyAction,
) => {
  let loadingTask: any = null
  let taskName: string | undefined
  let channel: string
  let wsInit: Set<string>
  switch (action.type) {
    case ActionType.ADD_BACKGROUND_LOADING:
      loadingTask = state.loadingTasks[action.name] || {}
      loadingTask[action.uuid] = daysjs()
      state.tasksRegistry[action.uuid] = action.name // eslint-disable-line

      updateTaskViewer(state.loadingTasks)
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
          [action.name]: loadingTask,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      }

    case ActionType.REMOVE_BACKGROUND_LOADING:
      taskName = state.tasksRegistry[action.uuid]
      if (!taskName) {
        return state
      }
      loadingTask = state.loadingTasks[taskName!]
      if (!loadingTask || !loadingTask[action.uuid]) {
        return state
      }

      delete loadingTask[action.uuid]
      if (!Object.keys(loadingTask).length) {
        delete state.loadingTasks[taskName!] // eslint-disable-line
      }
      delete state.tasksRegistry[action.uuid] // eslint-disable-line

      updateTaskViewer(state.loadingTasks)
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      }
    case ActionType.START_COUNTDOWN:
      return {
        ...state,
        countdown: action.start,
      }

    case ActionType.SET_WS_INIT:
      channel = action.channel
      wsInit = state.wsInit.add(channel)
      return {
        ...state,
        wsInit,
      }
    case ActionType.RESET_WS_INIT:
      wsInit = Set<string>()
      return {
        ...state,
        wsInit,
      }
    case ActionType.REMOVE_WS_INIT:
      return {
        ...state,
        wsInit: state.wsInit.remove(action.channel),
      }

    case ActionType.SET_TRANSACTION_LOADING: {
      const { name, uuid, toastId, txHash, featureType, onBlockConfirmation, extras } = action.payload as SetTxLoading
      loadingTask = state.txLoadingTasks[name] || {}
      loadingTask[uuid] = { ...loadingTask[uuid], toastId, txHash, featureType, onBlockConfirmation, extras }
      return {
        ...state,
        txLoadingTasks: {
          ...state.txLoadingTasks,
          [name]: loadingTask,
        },
      }
    }

    case ActionType.REMOVE_TRANSACTION_LOADING: {
      const { name, uuid } = action.payload as RemoveTxLoading
      loadingTask = state.txLoadingTasks[name]
      if (!loadingTask || !loadingTask[uuid]) {
        return state
      }

      delete loadingTask[uuid]
      if (!Object.keys(loadingTask).length) {
        delete state.txLoadingTasks[name!]
      }

      return {
        ...state,
        txLoadingTasks: {
          ...state.txLoadingTasks,
        },
      }
    }

    case ActionType.ON_CLOSE_TOAST: {
      const toasts = state.toasts
      const updated = toasts.filter((value) => value.id !== action.payload)
      return { ...state, toasts: updated }
    }

    case ActionType.ADD_TOAST: {
      const updated = [...state.toasts, action.payload]
      return { ...state, toasts: updated }
    }

    case ActionType.UPDATE_PENDING_TO_ACTIVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id === action.payload ? { ...toast, isActive: true } : toast
        ),
      }
    }

    default:
      return state
  }
}

export default loadingTaskReducer
