import BigNumber from 'bignumber.js'
import { BlockchainUtils, Carbon, Models, TypeUtils } from 'carbon-js-sdk'
import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { GetFeeQuoteResponse } from 'carbon-js-sdk/lib/hydrogen/feeQuote' // eslint-disable-line
import { RecordOf } from 'immutable'

import { TransferChannel, TransferMethod } from 'js/constants/TransferOptions'

import { Market } from '../exchange/types'

export interface WalletFees {
  // denom indexed
  [index: string]: GetFeeQuoteResponse
}

export interface WalletBalance {
  total?: BigNumber

  available?: BigNumber

  order?: BigNumber
  position?: BigNumber

  unavailable?: BigNumber

  delegated?: BigNumber
  unbonding?: BigNumber

  pool?: BigNumber // spot LP
  poolLocked?: BigNumber // spot LP committed

  supply?: BigNumber // nitron
  supplyLocked?: BigNumber // nitron collateral

  perpPool?: BigNumber // perp LP
  perpPoolLocked?: BigNumber // perp LP staking incentives

  feeReserved?: BigNumber // reserved for fee pocket
}
export type WalletBalanceField = keyof WalletBalance

export interface AdjustedBalance extends WalletBalance {
  tokenDp: number
}

export interface UserPortfolio extends Partial<WalletBalance> {

}

export interface TokenContractButtonPopUpState {
  popupType?: string
  link?: string
  contractAddress?: string
}

export interface ConnectedIbcAddress {
  type: TransferMethod.IBCKeplr | TransferMethod.IBCLeap
  bech32Address: string
}

export interface SelectedToken extends Token {
  isTokenGroup: boolean
}

export interface TokenBasicInfo {
  assetFullName: string,
  assetName: string,
  decimals: number,
  denom: string,
  blockchain: BlockchainUtils.BlockchainV2,
  isWrappedToken: boolean
  isPoolToken: boolean
  isPerpPoolToken: boolean
  isGroupToken: boolean
  isPartOfGroup: boolean

  isDepositHidden?: boolean
  isWithdrawHidden?: boolean
}

export interface TokenCDPInfo {
  assetTitle: string,
  underlyingDenom: string,
  underlyingDecimals: number,
  cdpRatio: BigNumber,
  isCdpToken: boolean
  isLendable: boolean
  isBorrowable: boolean
  isLendableCap: boolean
  isBorrowableCap: boolean
}

export interface TokenExtraBlockchains {
  extraBlockchains: string[],
  noOfExtraBlockChains: number,
  tradableMarkets: Market[],
}

export interface TokenProperties {
  tokenBasicInfo: TokenBasicInfo
  tokenCDPInfo: TokenCDPInfo
  tokenExtraBlockchains: TokenExtraBlockchains
}

export type DenomValue = [string, BigNumber]

export interface WalletBalanceStateProps {
  // balances include carbon + evm balance
  readonly evmBalances: TypeUtils.SimpleMap<TokenBalance>
  readonly carbonBalances: TypeUtils.SimpleMap<TokenBalance>
  readonly isUSD: boolean
  readonly profile: Carbon.Profile.Profile | null
  readonly selectedToken: SelectedToken | null
  readonly depositOption: TransferChannel | null
  readonly withdrawOption: TransferChannel | null
  readonly groupTokenOption: string

  readonly walletFees: WalletFees
  readonly hasSufficientFees: boolean | null
  readonly neoTokenBalance: BigNumber

  // only for swth delegations
  // use alliance delegations for other denoms
  readonly rawDelegations: Models.Staking.DelegationResponse[] | null
  readonly rawUnbondingDelegations: Models.Staking.UnbondingDelegation[] | null

  readonly balances: Record<string, WalletBalance>
  readonly adjustedBalances: Record<string, AdjustedBalance>

  // totals in USD terms
  readonly portfolio: UserPortfolio

  // redemption rates for derived tokens (cibt/, clpt/, cplt/)
  // { string: [denom, rate][] }
  readonly derivedTokenRedemption: Record<string, DenomValue[]>

  // balance denoms sorted by USD value
  readonly nonZeroBalanceDenoms?: string[]

  readonly tokenContractButtonPopUpState: TokenContractButtonPopUpState

  readonly adjustedStake: TypeUtils.SimpleMap<BigNumber>
  readonly tokenProperties: TypeUtils.SimpleMap<TokenProperties>

}

export type WalletBalanceState = RecordOf<WalletBalanceStateProps>

export const WalletBalanceActionTypes = {
  ADD_WALLET_BALANCE: '@walletBalance/ADD_WALLET_BALANCE',
  SET_WALLET_BALANCE: '@walletBalance/SET_WALLET_BALANCE',
  SET_BALANCES: '@walletBalance/SET_BALANCES',
  SET_ADJUSTED_BALANCES: '@walletBalance/SET_ADJUSTED_BALANCES',
  SET_PORTFOLIO: '@walletBalance/SET_PORTFOLIO',
  SET_NON_ZERO_BALANCE_DENOMS: '@walletBalance/SET_NON_ZERO_BALANCE_DENOMS',
  SET_DERIVED_TOKEN_REDEMPTION: '@walletBalance/SET_DERIVED_TOKEN_REDEMPTION',
  SET_CARBON_WALLET_BALANCE: '@walletBalance/SET_CARBON_WALLET_BALANCE',
  SET_EVM_WALLET_BALANCE: '@walletBalance/SET_EVM_WALLET_BALANCE',
  CLEAR_WALLET_BALANCE: '@walletBalance/CLEAR_WALLET_BALANCE',

  CHECK_SELECT_TOKEN: '@walletBalance/CHECK_SELECT_TOKEN',
  SET_SELECTED_TOKEN: '@walletBalance/SET_SELECTED_TOKEN',
  SELECT_TOKEN: '@walletBalance/SELECT_TOKEN',
  SELECT_DEPOSIT_OPTION: '@walletBalance/SELECT_DEPOSIT_OPTION',
  SELECT_WITHDRAW_OPTION: '@walletBalance/SELECT_WITHDRAW_OPTION',
  SELECT_GROUP_TOKEN_OPTION: '@walletBalance/SELECT_GROUP_TOKEN_OPTION',

  GET_WALLET_FEES: '@walletBalance/GET_WALLET_FEES',
  SET_WALLET_FEES: '@walletBalance/SET_WALLET_FEES',
  CLEAR_WALLET_FEES: '@walletBalance/CLEAR_WALLET_FEES',
  SET_HAS_SUFFICIENT_FEES: '@walletBalance/SET_HAS_SUFFICIENT_FEES',

  QUERY_NEO_TOKEN_BALANCE: '@walletBalance/QUERY_NEO_TOKEN_BALANCE',
  SET_NEO_TOKEN_BALANCE: '@walletBalance/SET_NEO_TOKEN_BALANCE',

  // TODO: remove
  SET_ACCOUNT_DELEGATIONS: '@walletBalance/SET_ACCOUNT_DELEGATIONS',

  SET_RAW_DELEGATIONS: '@walletBalance/SET_RAW_DELEGATIONS',
  SET_RAW_UNBONDING_DELEGATIONS: '@walletBalance/SET_RAW_UNBONDING_DELEGATIONS',
  SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE: '@walletBalance/SET_TOKEN_CONTRACT_BUTTON_POPUP_STATE',

  SET_TOKEN_PROPERTIES: '@walletBalance/SET_TOKEN_PROPERTIES',
  SET_ENOUGH_TOKENS_FOR_NETWORK_FEE: '@walletBalance/SET_ENOUGH_TOKENS_FOR_NETWORK_FEE',
  SET_LAST_INSUFFICIENT_FEE_TOAST: '@walletBalance/SET_LAST_INSUFFICIENT_FEE_TOAST',
}

export const walletBalanceQueryTasks: TypeUtils.SimpleMap<string> = {
  updateBalances: 'update-balances',
  userBalanceGraph: 'user-balance-graph',
}
