import React from "react"
import { useLocation } from "react-router"

import Paths from "js/constants/paths"

import ConvertTokenFallback from "./ConvertTokenFallback"
import DepositWithdrawFallback from "./DepositWithdrawFallback"
import OverviewFallback from "./OverviewFallback"
import ProfileFallback from "./ProfileFallback"
import TransfersFallback from "./TransfersFallback"
import WalletBalanceFallback from "./WalletBalanceFallback"

const AccountSwitch: React.FC = () => {
  const location = useLocation()

  if (location.pathname.includes('account/balance/deposit') || location.pathname.includes('account/balance/withdraw')) {
    return <DepositWithdrawFallback />
  }
  if (location.pathname.includes('account/convert')) {
    return <ConvertTokenFallback />
  }

  switch (location.pathname) {
    case Paths.Account.Profile:
    case Paths.Account.Transfer.CarbonEVM: {
      return <ProfileFallback />
    }
    case Paths.Account.Transfer.Crosschain.v2:
    case Paths.Account.Transfer.Internal.v2:
    case Paths.Account.TransferDetail:
    case Paths.Account.Order.v2:
    case Paths.Account.Trade.v2:
    case Paths.Account.Subaccounts:
    case Paths.Account.AddressManagement:
    case Paths.Account.OrderDetails:
    case Paths.Account.TradeDetails: 
    case Paths.Account.Funding: {
      return <TransfersFallback />
    }
    case Paths.Account.Balance: {
      return <WalletBalanceFallback />
    }
    default: {
      return <OverviewFallback />
    }
  }
}

export default AccountSwitch

